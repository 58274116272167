import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { getYoseVideo } from "../../utils/query";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { Collection, YOSE_STATUS } from "../../constants/common";

const useGetYoseVideoActions = (props: {
  statusDisplayed: ValueOf<typeof YOSE_STATUS>[];
  skip?: number;
  perPage?: number;
}) => {
  const { statusDisplayed, perPage = 9, skip = 0 } = props;
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [videos, setVideos] = useState<YoseList[]>([]);
  const [count, setCount] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregateUse002 = getYoseVideo({
          statusDisplayed,
          skip,
          perPage,
        });

        const aggregateResult = (await functions([
          "mongo/client",
          { collection: Collection.YOSES, aggregate: aggregateUse002 },
        ])) as AggregateResultType<YoseList>;

        const { result, count } = aggregateResult[0];

        setCount(count[0]?.count ?? 0);
        setVideos(result);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions, perPage, skip, statusDisplayed]);

  return {
    videos,
    count,
    error,
  };
};

export default useGetYoseVideoActions;
