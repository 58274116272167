import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsk002 } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

const useUsk002DBFetch = (selectedResult: string) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [fetchResult, setFetchResult] = useState<USK002Type[]>([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsk002(
          selectedResult,
          currentUser?.uid ?? "",
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_BRAIN_CHECKS, aggregate },
        ])) as USK002Type[];

        setFetchResult(fetchResult);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, functions, selectedResult]);

  return {
    fetchResult,
    fetchError,
  };
};

export default useUsk002DBFetch;
