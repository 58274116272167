import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { Collection } from "../../constants/common";
import { getAggregateBrainCheck } from "../../utils/query";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";

const useBrainCheckFetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [brainChecks, setBrainChecks] = useState<BrainCheck[]>([]);
  const [brainChecksFetchError, setBrainChecksFetchError] =
    useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const result = await functions([
          "mongo/client",
          {
            collection: Collection.PATIENT_BRAIN_CHECKS,
            aggregate: getAggregateBrainCheck(currentUser?.uid ?? ""),
          },
        ]);
        setBrainChecks(result as BrainCheck[]);
      } catch (err) {
        setBrainChecksFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions]);

  return {
    brainChecks,
    brainChecksFetchError,
  };
};

export default useBrainCheckFetch;
