import { useEffect } from "react";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import {
  INVITATION,
  NON_ACTIVE_PRESENT,
  PATIENT,
} from "../constants/pagePaths";
import {
  getAggregatePatientLogin,
  getAggregateUsaInvitations,
} from "../utils/query";
import { Collection, PatientTypeID, UserStatusID } from "../constants/common";

interface CustomData {
  status?: number|null;
}

// ログイン分岐処理
const LoginProcessing = () => {
  const navigate = useNavigateCustom();
  const firebaseAppContext = useFirebaseApp();

  useEffect(() => {
    const fetchData = async () => {
      // ファンクション
      if (!firebaseAppContext.userObject) {
        await firebaseAppContext.refreshCustomData();
      }

      // 招待ユーザー情報
      const LoginProcessingAggregate = getAggregatePatientLogin(
        firebaseAppContext.currentUser?.uid ?? "",
      );

      return (await firebaseAppContext.functions([
        "mongo/client",
        {
          collection: Collection.PATIENTS,
          aggregate: LoginProcessingAggregate,
        },
      ])) as PatientLogin[];
    };

    const fetchInvitationData = async () => {
      // ファンクション
      if (!firebaseAppContext.userObject) {
        await firebaseAppContext.refreshCustomData();
      }

      // 招待ユーザー情報
      const LoginProcessingAggregate = getAggregateUsaInvitations(
        firebaseAppContext.currentUser?.uid ?? "",
      );

      return (await firebaseAppContext.functions([
        "mongo/client",
        {
          collection: Collection.PATIENT_INVITATIONS,
          aggregate: LoginProcessingAggregate,
        },
      ])) as USA028Type[];
    };

    void (async () => {
      try {
        // アクティベート日付
        const activationDate = firebaseAppContext.userObject
          ?.activation_date as Date;
        const fetchInvitationResult = await fetchInvitationData();

        if (!activationDate && fetchInvitationResult.length > 0) {
          // 会員・招待選択画面へ
          navigate(PATIENT.USA004);

          return;
        }
        if (!activationDate) {
          const status = firebaseAppContext.userObject?.status as number|null|undefined;
          if (status  === UserStatusID.INVITATION) {
            // 招待トップ
            navigate(INVITATION.USA029);

            return;
          }
          // 会員・招待選択画面へ
          navigate(PATIENT.USA022);

          return;
        }

        // patient検索
        const fetchResult = await fetchData();
        if (fetchResult.length === 0) {
          // 会員・招待選択画面へ
          navigate(PATIENT.USA022);

          return;
        }

        // status フィールドの取得
        const userObject: CustomData = firebaseAppContext.userObject || {};

        // statusフィールドの値を取得
        const isStatus = userObject.status === UserStatusID.UNREGISTERED;

        const isPresentUser =
          fetchResult[0].patient_type === PatientTypeID.INVITATION;
        const isPatientInvitationUser =
          fetchResult[0]?.patient_type === PatientTypeID.PRESENT;

        if (isStatus && isPatientInvitationUser) {
          // 承認待ち
          navigate(NON_ACTIVE_PRESENT.USA036);
        } else if (isPresentUser) {
          // 招待トップ
          navigate(INVITATION.USA029);
        } else {
          // トップ
          navigate(PATIENT.USB001);
        }
      } catch (e) {
        console.log(e);
        // ログアウトしてエラー画面
        void firebaseAppContext.logOut();
        navigate(PATIENT.USG010);
      }
    })();
  }, [navigate, firebaseAppContext]);

  return <></>;
};

export default LoginProcessing;
