import { useCallback, useState } from "react";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { Collection, SALES_CATEGORY } from "../../constants/common";
import useReceiptId from "./useReceiptId";

// salesテーブルの領収書発行日を更新
const useUpdateReceiptData = () => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;

  const [id] = useReceiptId();
  const [actionError, setActionError] = useState<Error | null>(null);

  const updateReceiptIssueDate = useCallback(async () => {
    try {
      await functions([
        "mongo/client",
        {
          collection: Collection.SALES,
          updateOne: {
            filter: {
              _id: id,
            },
            update: {
              $set: {
                receipt_issue_date: new Date(),
                updated_at: new Date(),
              },
            },
          },
        },
      ]);
    } catch (err) {
      setActionError(checkActionErr(err));
    }
  }, [functions, id]);

  const updateReceiptData = useCallback(async (sale:Receipt, name:string, nldata: SettingsType) => {
    try {
      await functions([
        "mongo/client",
        {
          collection: Collection.SALES,
          updateOne: {
            filter: {
              _id: id,
            },
            update: {
              $set: {
                receipt_issue_date: new Date(),
                metadata: {
                  name,
                  ...(sale.category === SALES_CATEGORY.pet ? {
                    hospital_name: sale.hospital_name,
                    prefecture: sale.prefecture,
                    address: sale.address,
                    invoice_num: sale.invoice_num,
                  } : {}),

                  nl_name: nldata.nl_name,
                  nl_address: nldata.nl_address,
                  nl_invoice: nldata.nl_invoice,
                },
                updated_at: new Date(),
              },
            },
          },
        },
      ]);
    } catch (err) {
      setActionError(checkActionErr(err));
    }
  }, [functions, id]);

  return {
    updateReceiptIssueDate,
    updateReceiptData,
    actionError,
  };
};

export default useUpdateReceiptData;
