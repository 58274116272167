import { useState, useEffect } from "react";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { formatDate } from "../../utils/utils";
import { FORMAT_STYLE } from "../../constants/common";

// 時間を比較可能な形式に変換
const getTimeValue = (timeString: string): number => {
  const [hours, minutes] = timeString.split(":").map(Number);

  return hours * 60 + minutes;
};

// 予約可能な時間を取得する
const useBookingTimeFetch = (
  bookingType: "interview" | "mri" | "pet",
  bookingDates: Array<Date | undefined>,
  hospitalId: string,
  setTimeOptions: (
    callback: React.SetStateAction<
      Array<Array<{ value: string; label: string }>>
    >,
  ) => void,
) => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;

  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    bookingDates.forEach((bookingDate, index) => {
      if (
        (bookingType === "interview" && bookingDate) ||
        (bookingType !== "interview" && bookingDate && hospitalId)
      ) {
        const fetchTimesForDate = async () => {
          addLoadCount();
          try {
            const result = (await functions([
              "booking/getValidTime",
              [
                bookingType,
                formatDate(bookingDate, FORMAT_STYLE["YYYY-MM-DD"]),
                hospitalId,
              ],
            ])) as Array<[string, string]>;

            let newTimeOptions: { value: string; label: string }[] = [];
            if (result.length > 0) {
              // 新しい時間オプションを時間順に並べる
              result.sort((a, b) => {
                // 開始時間を数値に変換
                const startTimeA = getTimeValue(a[0]);
                const startTimeB = getTimeValue(b[0]);

                // 比較してソート
                return startTimeA - startTimeB;
              });

              // 元の形式でソートされたnewTimeOptionsを使用する
              newTimeOptions = result.map((slot) => ({
                value: `${slot[0]}～${slot[1]}`,
                label: `${slot[0]}～${slot[1]}`,
              }));
            }

            // 成功した場合、対応するインデックスのtimeOptionsを更新
            setTimeOptions((currentOptions) =>
              currentOptions.map((options, idx) =>
                idx === index ? newTimeOptions : options,
              ),
            );
          } catch (err) {
            setError(checkFetchErr(err));
          } finally {
            decrementLoadCount();
          }
        };

        // 非同期関数を呼び出し
        void fetchTimesForDate();
      }
    });
  }, [bookingType, bookingDates, hospitalId, setTimeOptions, functions]);

  return { error };
};

export default useBookingTimeFetch;
