import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  Collection,
  PATIENT_INVITATIONS_STATUS,
} from "../../../constants/common";
import { getAggregateUsaInvitationsUser } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";

const useUsa034Actions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [result, setResult] = useState<PatientInvitation>();
  const [error, setError] = useState<Error | null>(null);
  const searchParams = new URLSearchParams(window.location.search);
  const invitedId = searchParams.get("invited_patient_id");
  const [id] = useId();

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        let idToUse: string | null = id;
        if (!idToUse) {
          idToUse = invitedId;
        }

        if (idToUse) {
          const aggregate = getAggregateUsaInvitationsUser(idToUse, [
            PATIENT_INVITATIONS_STATUS.registered,
            PATIENT_INVITATIONS_STATUS.denial,
          ]);
          const fetchResult = (await functions([
            "mongo/client",
            { collection: Collection.PATIENT_INVITATIONS, aggregate },
          ])) as PatientInvitation[];
          setResult(fetchResult[0]);
        }
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions, id, invitedId]);

  return {
    result,
    error,
  };
};

export default useUsa034Actions;
