import { useState } from "react";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../base/useLoadingAction";

const useCancelPresent = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [error, setError] = useState<Error | null>(null);

  // 退会キャンセルAPI 失敗時にはfalseを返します。
  const cancelPresent = async (email: string | undefined) => {
    openUpdateActionLoading();
    try {
      await functions([
        "account/present/cancellation",
        [currentUser?.uid, email],
      ]);

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    error,
    cancelPresent,
  };
};

export default useCancelPresent;
