import { useState } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

type UserDataType = {
  _id: string;
  email: string;
};

// usersに同一emailが存在するかチェックします
const useCheckUsersEmail = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [error, setError] = useState<Error | null>(null);

  const checkExistingEmail = async (email: string) => {
    addLoadCount();
    try {
      const existingEmail = (await functions([
        "mongo/client",
        {
          collection: Collection.USERS,
          find: {
            filter: {
              _id: { $ne: currentUser?.uid },
              email,
            },
          },
        },
      ])) as UserDataType[];

      if (existingEmail.length > 0) {
        return true;
      }

      return false;
    } catch (err) {
      setError(checkFetchErr(err));

      return false;
    } finally {
      decrementLoadCount();
    }
  };

  return { error, checkExistingEmail };
};

export default useCheckUsersEmail;
