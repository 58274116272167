export enum PATIENT {
  USA001 = "/login",
  USA002 = "/create-account",
  USA003 = "/account-email-sent",
  USA004 = "/account-input",
  USA006 = "/account-input-confirmation",
  USA020 = "/application-completed",
  USA022 = "/account-type-selection",
  USA023 = "/account-select-plan-standard",
  USA024 = "/account-select-plan",
  USA025 = "/invitation-account-input",
  USA026 = "/invitation-account-input-confirmation",
  USA027 = "/invitation-account-email-sent",
  USA028 = "/invitation-account-completed",
  USA029 = "/invitation-account-home",
  USA030 = "/invitation-account-list",
  USA031 = "/invitation-account-detail",
  USA032 = "/invitation-account-pending-list",
  USA033 = "/invitation-account-payment",
  USA034 = "/invitation-accepted",
  USA035 = "/invitation-denial",
  USA038 = "/invitation-account-cancel",
  USA039 = "/withdrawal-completed",
  USA042 = "/registration-terms-and-conditions",
  USA043 = "/re-registration-completed",
  USA044 = "/invitation-application-completed",
  USB001 = "/top",
  USB003 = "/mri-available",
  USB004 = "/pet-available",
  USB005 = "/pet-reserved",
  USC001 = "/test-result-nouknow-list",
  USC002 = "/test-result-mri-list",
  USC003 = "/test-result-pet-list",
  USC004 = "/test-result-screening",
  USC007 = "/mri-examination",
  USC008 = "/test-result-mri-reservation",
  USC009 = "/test-result-mri-reservation-completed",
  USC011 = "/test-result-mri",
  USC012 = "/pet-examination",
  USC013 = "/test-result-pet",
  USC014 = "/nouknow-completion",
  USC015 = "/medical-interview-completion",
  USC016 = "/test-result-pet-reservation-completed",
  USC017 = "/mri-examination-confirm",
  USC018 = "/pet-examination-confirm",
  USC019 = "/mri-terms-and-conditions",
  USC020 = "/pet-terms-and-conditions",
  USC021 = "/test-inspection-list",
  USC022 = "/test-inspection-nouknow-screening",
  USC023 = "/test-inspection-brain-screening",
  USC024 = "/test-inspection-mri-screening",
  USC025 = "/test-inspection-pet-screening",
  USC026 = "/test-result-list",
  USC039 = "/test-result-mri-cancel-completed",
  USC040 = "/test-result-pet-cancel-completed",
  USD001 = "/health-counseling-interview-before",
  USD002 = "/health-counseling-interview",
  USD003 = "/health-counseling-doctor-chat",
  USD004 = "/health-counseling-top",
  USD005 = "/health-counseling-reservation-completed",
  USD008 = "/health-counseling-interview-paid-confirm",
  USD009 = "/telephone-consultation-explanation",
  USD010 = "/health-counseling-interview-free-confirm",
  USE001 = "/yose-list-default",
  USE002 = "/yose-list",
  USE003 = "/yose-detail-default",
  USE004 = "/yose-detail",
  USF001 = "/ec-top",
  USF002 = "/ec-order-history",
  USF003 = "/ec-address",
  USF004 = "/ec-all-products",
  USF005 = "/ec-product-detail",
  USF006 = "/ec-cart",
  USF007 = "/ec-accountant",
  USF008 = "/ec-accountant-delivery-date",
  USF009 = "/ec-order-completed",
  USF010 = "/ec-order-detail",
  USF011 = "/ec-order-cancel",
  USF012 = "/ec-order-cancel-completed",
  USG001 = "/password-reset-email-send",
  USG002 = "/password-reset-email-send-completed",
  USG003 = "/password-reset-input",
  USG004 = "/password-reset-completed",
  USG005 = "/account-info",
  USG006 = "/account-info-edit",
  USG007 = "/account-info-withdrawal",
  USG008 = "/mail-resend",
  USG009 = "/verify",
  USG010 = "/auth/error",
  USG011 = "/withdrawn-top",
  USG012 = "/receipt-list",
  USG015 = "/login-password-reset-input",
  USG016 = "/login-password-completed",
  USG017 = "/login-email-reset-input",
  USG018 = "/login-email-completed",
  USG019 = "/email-reset-email-send-completed",
  USG020 = "/email-reset-email-send",
  USJ001 = "/USJ001",
  USJ002 = "/USJ002",
  USK001 = "/USK001",
  USK002 = "/test-result-brain-check-list",
  USK003 = "/test-result-brain-check",
  USH001 = "/fitbit-home",
  USH002 = "/fitbit-detail",
  USI001 = "/error",
  LOGIN_PROCESSING = "/login-processing",
  EXTERNAL_LOGIN = "/ExternalLogin",
  PRE_BOOK_LOADING = "/pre-book-loading",
  RECEIPT = "/receipt",
  RECEIPT_ADMIN = "/receipt-admin",
  NOT_FOUND = "/notfound",
}

export enum INVITATION {
  USA001 = "/login",
  USA029 = "/invitation-account-home",
  USA027 = "/invitation-account-email-sent",
  USA028 = "/invitation-account-completed",
  USA033 = "/invitation-account-payment",
  USA030 = "/invitation-account-list",
  USA031 = "/invitation-account-detail",
  USA032 = "/invitation-account-pending-list",
  USA034 = "/invitation-accepted",
  USA035 = "/invitation-denial",
  USA038 = "/invitation-account-cancel",
  USA039 = "/withdrawal-completed",
  USA044 = "/invitation-application-completed",
  USA004 = "/account-input",
  USA006 = "/account-input-confirmation",
  USA024 = "/account-select-plan",
  USG005 = "/account-info",
  USG006 = "/account-info-edit",
  USG015 = "/login-password-reset-input",
  USG016 = "/login-password-completed",
  USG017 = "/login-email-reset-input",
  USG018 = "/login-email-completed",
  USG012 = "/receipt-list",
  RECEIPT = "/receipt",
}

export enum NON_ACTIVE_PRESENT {
  USA036 = "/approval-pending",
  USG005 = "/account-info",
  USG006 = "/account-info-edit",
}

export enum WITHDRAWAL {
  USB001 = "/top",
  USC001 = "/test-result-nouknow-list",
  USC002 = "/test-result-mri-list",
  USC003 = "/test-result-pet-list",
  USC004 = "/test-result-screening",
  USC011 = "/test-result-mri",
  USC013 = "/test-result-pet",
  USC026 = "/test-result-list",
  USK002 = "/test-result-brain-check-list",
  USK003 = "/test-result-brain-check",
  USG005 = "/account-info",
  USG015 = "/login-password-reset-input",
  USG016 = "/login-password-completed",
  USG017 = "/login-email-reset-input",
  USG018 = "/login-email-completed",
  USG012 = "/receipt-list",
  USA004 = "/account-input",
  USA006 = "/account-input-confirmation",
  USA024 = "/account-select-plan",
  USA029 = "/invitation-account-home",
  USA030 = "/invitation-account-list",
  USA031 = "/invitation-account-detail",
  USA033 = "/invitation-account-payment",
  USA042 = "/registration-terms-and-conditions",
  USA043 = "/re-registration-completed",
}

export const HEADER_LINK = PATIENT.LOGIN_PROCESSING;
