import React from "react";
import { Navigate } from "react-router-dom";

import { isEmailVefied, useFirebaseApp } from "../contexts/FirebaseApp";
import { PATIENT } from "../constants/pagePaths";

// ログイン不要画面
const PublicElement: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const { currentUser } = useFirebaseApp();
  if (currentUser && !isEmailVefied(currentUser)) {
    return element;
  }

    return currentUser ? <Navigate to={PATIENT.LOGIN_PROCESSING} /> : element;
};

export default PublicElement;
