import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  checkFetchErr,
  redirectToNoDataPage,
} from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregatePatientMessageById } from "../../utils/query";
import useStateCustomObj from "../base/useStateCustomObj";

const usePatientMessageFetchById = (messageId: string) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [patientMessage, setPatientMessage] =
    useStateCustomObj<PatientMessage | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const aggregate = getAggregatePatientMessageById(messageId);
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_MESSAGE_RELATIONSHIPS, aggregate },
        ])) as PatientMessage[];

        if (fetchResult.length < 1) redirectToNoDataPage();

        setPatientMessage(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, setPatientMessage, messageId, functions]);

  return { error, patientMessage };
};

export default usePatientMessageFetchById;
