import { useEffect, useState } from "react";
import {
  canInitReceiveMri,
  isWithinExaminationPeriod,
} from "../../../utils/utils";
import {
  MriStatusID,
  ONLINE_TEST_STATUS,
  PatientNouKnowStatusID,
} from "../../../constants/common";
import usePatientNouknowsFetch from "../../common/usePatientNouknowsFetch";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import usePatientMriFetch from "../../common/usePatientMriFetch";
import usePatientOnlineTestsFetch from "../../common/usePatientOnlineTestsFetch";

const useUsc007Fetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser } = firebaseAppContext;

  const [isOnlineTest, setIsOnlineTest] = useState(false);
  const [onlineTestUrl, setOnlineTestUrl] = useState("");
  const [isNouknowComplete, setIsNouknowComplete] = useState(false);
  const [nouknowUrl, setNouknowUrl] = useState("");
  const [isInitMri, setIsInitMri] = useState(true);
  const [canNextMri, setCanNextMri] = useState(true);

  const [canReceiveMri, setCanReceiveMri] = useState(false);

  const { patientOnlineTests, error: OnlineTestGetError } =
    usePatientOnlineTestsFetch();
  const { patientNouknows, error: nouknowError } = usePatientNouknowsFetch();
  const { patientMri, error: mriError } = usePatientMriFetch();

  const error = OnlineTestGetError || nouknowError || mriError;

  // オンライン問診
  useEffect(() => {
    if (patientOnlineTests.length) {
      // 最新データが対象
      const onlineTestObj = patientOnlineTests[0];
      if (
        ONLINE_TEST_STATUS.NOT_IMPLEMENTED === onlineTestObj.online_test_status
      ) {
        setIsOnlineTest(false);
        setOnlineTestUrl(onlineTestObj.online_test_url);
      } else {
        setIsOnlineTest(true);
      }
    } else {
      setIsOnlineTest(false);
    }
  }, [patientOnlineTests]);

  // のうKNOW
  useEffect(() => {
    // 空の配列のチェック
    if (!patientNouknows.length) {
      setIsNouknowComplete(false);

      return;
    }

    // 期間内でテスト未実施のデータが対象
    const enabledPatientNouknows = patientNouknows.filter((item) => {
      const now = new Date();

      return (
        item.status === PatientNouKnowStatusID.PRETEST &&
        now >= item.exam_start &&
        now <= item.exam_end
      );
    });

    // 抽出された要素があるかどうかチェック
    if (enabledPatientNouknows.length === 0) {
      setIsNouknowComplete(true);

      return;
    }

    setNouknowUrl(enabledPatientNouknows[0].nouknow_url);
    setIsNouknowComplete(false);
  }, [patientNouknows, setNouknowUrl, setIsNouknowComplete]);

  // MRI
  useEffect(() => {
    // 取り下げ、NG以外のデータ対象
    const filteredArray = patientMri.filter(
      (data) =>
        data.mri_status !== MriStatusID.WITHDRAWN_AFTER_CONFIRMATION &&
        data.mri_status !== MriStatusID.NG_BEFORE_CONFIRMATION,
    );
    if (filteredArray.length) {
      // 2回目以降
      setIsInitMri(false);

      // MRI,PETが受診可能か
      const isMriPetWithinExaminationPeriod = isWithinExaminationPeriod(
        firebaseAppContext.userObject,
      );

      const mriObj = patientMri[0];
      if (MriStatusID.USER_REPORTED === mriObj.mri_status) {
        if (isMriPetWithinExaminationPeriod) {
          setCanNextMri(true);
        } else {
          setCanNextMri(false);
        }
      } else {
        setCanNextMri(false);
      }
    } else {
      // 初回
      setIsInitMri(true);
      setCanNextMri(false);
    }
  }, [patientMri, firebaseAppContext.currentUser, firebaseAppContext.userObject]);

  // MRI検査を受診可能か判定
  useEffect(() => {
    // MRI検査を受ける資格があるかどうかを判定する関数
    const isEligibleForMri = () => {
      // 初回の判定
      if (isInitMri) {
        return (
          canInitReceiveMri(currentUser) && isOnlineTest && isNouknowComplete
        );
      }

      // それ以降の判定
      return canNextMri && isOnlineTest && isNouknowComplete;
    };

    // 判定結果に基づいて状態を設定
    setCanReceiveMri(isEligibleForMri());
  }, [isOnlineTest, isNouknowComplete, isInitMri, currentUser, canNextMri]);

  return {
    isOnlineTest,
    onlineTestUrl,
    isNouknowComplete,
    nouknowUrl,
    canReceiveMri,
    error,
  };
};

export default useUsc007Fetch;
