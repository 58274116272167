import { useState, useEffect } from "react";
import { Collection, OrderStatusID } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateOneOrder } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useForceUpdate from "./useForceUpdate";

// ログインユーザのCartユーザ情報を取得
const useCartInfoFetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [cartInfo, setCartInfo] = useState<OrdersType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const id = currentUser?.uid;

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        // ログインユーザのCartユーザ情報を取得
        const conditions = { patient_id: id, status: OrderStatusID.CART_KEPT };
        const aggregate = getAggregateOneOrder(conditions);
        const searchCartInfo = (await functions([
          "mongo/client",
          { collection: Collection.ORDERS, aggregate },
        ])) as OrdersType[];

        setCartInfo(searchCartInfo[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, forceUpdate.forceUpdateCount, functions]);

  return { error, cartInfo };
};

export default useCartInfoFetch;
