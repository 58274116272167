import { useState, useEffect } from "react";
import { Collection, INTERVIEW_STATUS } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateInterview } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
// import { formatDate } from "../../utils/utils";

enum INTERVIEW_TICKETS_STATUS {
  unused, // 未使用
  spent, // 使用済み
}

type PatientInterviewsType = {
  _id: string;
  expired_at: Date;
  status: number;
  interviewTicketsStatus: ValueOf<typeof INTERVIEW_TICKETS_STATUS>;
  id: string;
  interview_fix_date_start: Date;
  interview_fix_date_end: Date;
  invitation_ticket_id: string;
  candidate_interview_dates: {
    fifth?: string;
    first?: string;
    fourth?: string;
    second?: string;
    third?: string;
  };
};

const useGetPaidInterview = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [interviewStatus, setInterviewStatus] = useState<
    ValueOf<typeof INTERVIEW_STATUS>
  >(INTERVIEW_STATUS.beforePaidApplication);

  const [interview, setInterview] = useState<PatientInterviewsType>();
  const [error, setError] = useState<Error | null>(null);
  // const [ticketExpireStr, setTicketExpireStr] = useState("");
  // const [invitationTicketId, setInvitationTicketId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // const toDay = new Date(
        //   new Date().setHours(0, 0, 0, 0) + 9 * 60 * 60 * 1000,
        // );

        const aggregate = getAggregateInterview({
          patientId: currentUser?.uid,
        });
        const aggregateResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_INTERVIEWS, aggregate },
        ])) as PatientInterviewsType[];

        const paidResult = aggregateResult.find(
          (record) => !record.invitation_ticket_id,
        );

        if (paidResult) {
          setInterview(paidResult);
        } else {
          setInterview(undefined);
        }

        // // 無料チケット
        // const ticketCollection = getMongoDb(
        //   currentUser,
        //   Collection.INVITATION_TICKETS,
        // );
        // const ticketResults = (await ticketCollection.find(
        //   {
        //     patient_id: currentUser?.uid,
        //     status: INTERVIEW_TICKETS_STATUS.unused,
        //   },
        //   {
        //     sort: { created_at: -1 },
        //   },
        // )) as InvitationTickets[];

        // if (ticketResults.length > 0 && !paidResult) {
        //   const ticketResult = ticketResults[0];
        //   if (toDay <= ticketResult.expired_at) {
        //     // 無料チケットが利用可能 && 無料チケットの有効期限以内
        //     setInterviewStatus(INTERVIEW_STATUS.beforeFreeApplication);
        //     setTicketExpireStr(formatDate(ticketResult.expired_at));
        //     setInvitationTicketId(ticketResult._id);

        //     return;
        //   }
        // }
        // if (ticketResults.length > 0 && paidResult) {
        //   const ticketResult = ticketResults[0];
        //   if (toDay <= ticketResult.expired_at) {
        //     setInterviewStatus(paidResult.status);
        //     setTicketExpireStr(formatDate(ticketResult.expired_at));
        //     setInvitationTicketId(ticketResult._id);

        //     return;
        //   }
        // }

        if (paidResult) {
          setInterviewStatus(paidResult.status);

          return;
        }
        // データが存在しない場合
        setInterviewStatus(INTERVIEW_STATUS.beforePaidApplication);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions]);

  return {
    interview,
    interviewStatus,
    error,
    // ticketExpireStr,
    // invitationTicketId,
  };
};

export default useGetPaidInterview;
