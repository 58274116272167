import { useState, useEffect } from "react";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

// 予約可能な日付を取得する
const useBookingDateFetch = (
  bookingType: "interview" | "mri" | "pet",
  hospitalId: string,
) => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;

  const [includeDates, setIncludeDates] = useState<Date[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // 予約可能日付取得API
        const result = (await functions([
          "booking/getValidDate",
          [bookingType, hospitalId],
        ])) as string[];

        // 文字列の配列をDateオブジェクトの配列に変換
        const dates = result.map((dateString) => new Date(dateString));

        setIncludeDates(dates);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    if (
      ((bookingType === "mri" || bookingType === "pet") && hospitalId) ||
      bookingType === "interview"
    ) {
      void fetchData();
    }
  }, [bookingType, hospitalId, functions]);

  return { error, includeDates };
};

export default useBookingDateFetch;
