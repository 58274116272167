import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection, USER_STATUS } from "../../../constants/common";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";

const useUSA043DbActions = () => {
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const firebaseAppContext = useFirebaseApp();
  const { currentUser } = firebaseAppContext;
  const [reloadCount, setReloadCount] = useState(0);
  const [shouldReload, setShouldReload] = useState(true);

  const fetchUserStatus = useCallback(async () => {
    const hasUserStatusUpdated =
      (await firebaseAppContext.functions([
        "mongo/client",
        {
          collection: Collection.USERS,
          countDocuments: {
            filter: {
              _id: currentUser?.uid,
              status: USER_STATUS.activeUser,
            },
          },
        },
      ])) === 1;

    setReloadCount((prev) => prev + 1);

    // reloadCountは無限ループさせ、この関数を監視する
    // hasUserStatusUpdatedでActiveなユーザーが取得できた段階で、リロードをやめる
    if (hasUserStatusUpdated && reloadCount >= 0) {
      setShouldReload(false);
    }

    return hasUserStatusUpdated;
  }, [currentUser?.uid, firebaseAppContext, reloadCount]);

  // 1秒間隔で、ユーザー情報が更新されたかを取得する。
  const { error } = useSWR<boolean, Error>("fetchUserStatus", fetchUserStatus, {
    refreshInterval: shouldReload ? 1000 : 0,
  });

  useEffect(() => () => addLoadCount(), []);

  useEffect(() => {
    if (error || !shouldReload) {
      decrementLoadCount();
    }
  }, [shouldReload, error]);

  useEffect(() => {
    if (error) setFetchError(checkFetchErr(error));
  }, [error]);

  return {
    fetchError,
  };
};

export default useUSA043DbActions;
