import { useState } from "react";

import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

type GcsUrl = {
  gcs_path: string;
  signed_url: string;
};

const useSignedBrainCheckUrl = () => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;

  const [signedBrainCheckError, setSignedBrainCheckError] =
    useState<Error | null>(null);

  const fetchSignedBrainCheckUrl = async (brainCheckId: string) => {
    addLoadCount();
    try {
      const response = (await functions([
        "braincheck/downloadSignUrl",
        brainCheckId,
      ])) as GcsUrl;

      const signedUrl = response.signed_url;

      if (!signedUrl) {
        const error = new Error("Signed URL not received");
        setSignedBrainCheckError(checkActionErr(error));
      }

      return signedUrl;
    } catch (err) {
      setSignedBrainCheckError(checkActionErr(err));

      return null;
    } finally {
      decrementLoadCount();
    }
  };

  return {
    fetchSignedBrainCheckUrl,
    signedBrainCheckError,
  };
};

export default useSignedBrainCheckUrl;
