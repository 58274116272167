import { useState, useEffect } from "react";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateYoseNewCount } from "../../../utils/query";

type totalCount = {
  totalCount: number;
}[];

const useMenuNavigationFetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [isNewYose, setIsNewYose] = useState(false);
  const [isInspectionResult, setExistInspectionResult] = useState(false);
  const [consultingChatCounts, setConsultingChatCounts] = useState(0);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // 寄席の新着を取得
        const aggregate = getAggregateYoseNewCount();
        const aggregateResult = (await functions([
          "mongo/client",
          { collection: Collection.YOSES, aggregate },
        ])) as totalCount;

        // 医師チャットの未読の記事を取得
        const chats = (await functions([
          "mongo/client",
          {
            collection: Collection.CHATS,
            find: {
              filter: {
                patient_id: currentUser?.uid,
                commented_user: "admin",
                is_read: false,
              },
            },
          },
        ])) as Chats[];

        setIsNewYose(!!aggregateResult?.[0]?.totalCount);
        setConsultingChatCounts(chats?.length ?? 0);

        // TODO 検査のデータを取得するようにする phase2以降の対応
        setExistInspectionResult(false);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions]);

  return {
    isNewYose,
    isInspectionResult,
    consultingChatCounts,
    fetchError,
  };
};

export default useMenuNavigationFetch;
