import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateMriHospitals } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// MRI受診可能な病院を都道府県で取得
const useMriHospitalsFetch = (prefecture: string) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [mriHospitals, setMriHospitals] = useStateCustomObj<MriHospital[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateMriHospitals(prefecture);
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.HOSPITALS, aggregate },
        ])) as MriHospital[];

        setMriHospitals(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (prefecture) void fetchData();
  }, [currentUser, setMriHospitals, prefecture, functions]);

  return { error, mriHospitals };
};

export default useMriHospitalsFetch;
