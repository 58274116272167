import React, { useCallback, useEffect } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Divider from "../components/Divider/Divider";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import PatientFooter from "../features/PatientFooter";
import { useCheckErrorThrowError } from "../utils/checkError";
import SpDropdown from "../components/Dropdown/SpDropdown";
import SpButton from "../components/Button/SpButton";
import { PREFECTURE_OPTIONS } from "../constants/common";
import useMriHospitalsFetch from "../hooks/common/useMriHospitalsFetch";
import {
  useNavigateWithBack,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import DatePicker from "../components/DatePicker/DatePicker";
import useBookingDateFetch from "../hooks/common/useBookingDateFetch";
import useBookingTimeFetch from "../hooks/common/useBookingTimeFetch";
import { checkBookingRequireRules, hasDuplicateBookings } from "../utils/utils";
import { ERROR_MESSAGES } from "../constants/messages";
import Button from "../components/Button/Button";
import ExternalMedicalInstitutionsButton from "../components/Button/ExternalMedicalInstitutionsButton";
import Dropdown from "../components/Dropdown/Dropdown";
import Tag from "../components/Tag/Tag";

const USC007 = () => {
  const navigateWithUrl = useNavigateWithUrl();
  const navigateWithBack = useNavigateWithBack(PATIENT.USB001);
  const [candidateCount, setCandidateCount] = React.useState(3);
  const [prefecture, setPrefecture] = React.useState("");
  const [selectHospitalId, setSelectHospitalId] = React.useState("");
  const [selectHospitalName, setSelectHospitalName] = React.useState("");
  const [selectError, setSelectError] = React.useState<string[]>([]);
  const [selectHospitalFrameTime, setSelectHospitalFrameTime] =
    React.useState("-");

  // 日付選択
  const [selectedDates, setSelectedDates] = React.useState<
    Array<Date | undefined>
  >(Array.from({ length: candidateCount }).map(() => undefined));

  // 時間選択
  const [selectedTimes, setSelectedTimes] = React.useState<
    Array<string | undefined>
  >(Array.from({ length: candidateCount }).map(() => undefined));

  // 時間の選択肢
  const [timeOptions, setTimeOptions] = React.useState<
    Array<Array<{ value: string; label: string }>>
  >(Array.from({ length: candidateCount }).map(() => []));

  const { mriHospitals, error: mriHospitalError } =
    useMriHospitalsFetch(prefecture);

  // 日時
  const { includeDates, error: bookingDateFetchError } = useBookingDateFetch(
    "mri",
    selectHospitalId,
  );

  // 時間
  const { error: bookingTimeFetchError } = useBookingTimeFetch(
    "mri",
    selectedDates,
    selectHospitalId,
    setTimeOptions,
  );

  const hospitals = prefecture
    ? mriHospitals.map((mriHospital) => ({
        value: mriHospital._id,
        label: mriHospital.hospital_name,
      }))
    : [];

  useCheckErrorThrowError([
    mriHospitalError,
    bookingDateFetchError,
    bookingTimeFetchError,
  ]);

  // 候補日追加時の状態反映
  useEffect(() => {
    setSelectError([]);
    setSelectedDates((currentSelectedDates) => {
      if (candidateCount > currentSelectedDates.length) {
        return [
          ...currentSelectedDates,
          ...Array.from({
            length: candidateCount - currentSelectedDates.length,
          }).map(() => undefined),
        ];
      }

      return currentSelectedDates;
    });
    setTimeOptions((currentSelectedDates) => {
      if (candidateCount > currentSelectedDates.length) {
        return [
          ...currentSelectedDates,
          ...Array.from({
            length: candidateCount - currentSelectedDates.length,
          }).map(() => []),
        ];
      }

      return currentSelectedDates;
    });
  }, [candidateCount]);

  // セッションストレージの値を復元
  useEffect(() => {
    const savedFormDataString = sessionStorage.getItem("mriBookingInput");
    if (savedFormDataString) {
      const savedFormData = JSON.parse(
        savedFormDataString,
      ) as MriPetSavedFormData;

      // savedFormData.selectedDates から Date オブジェクトの配列を生成
      const dates = savedFormData.selectedDates.map((dateString) =>
        dateString ? new Date(dateString) : undefined,
      );
      setPrefecture(savedFormData.prefecture);
      setSelectHospitalId(savedFormData.selectHospitalId);
      setSelectHospitalName(savedFormData.selectHospitalName);
      setSelectHospitalFrameTime(savedFormData.selectHospitalFrameTime);
      setSelectedDates(dates);
      setSelectedTimes(savedFormData.selectedTimes);
      setCandidateCount(dates.length);
    }
  }, []);

  // 都道府県選択
  const handleChangePrefecture = (value: string) => {
    setSelectError([]);
    setPrefecture(value);
    // 各項目リセット
    setSelectHospitalId("");
    setSelectHospitalName("");
    setSelectedDates(
      Array.from({ length: candidateCount }).map(() => undefined),
    );
    setSelectedTimes(
      Array.from({ length: candidateCount }).map(() => undefined),
    );
    setSelectHospitalFrameTime("-");
  };

  // 提携病院選択
  const handleChangeHospital = (id: string) => {
    setSelectError([]);

    // idと一致するHospitalを取得
    const selectedHospital = mriHospitals.find(
      (hospital) => hospital._id === id,
    );

    // 所要時間セット
    if (selectedHospital && selectedHospital.mri_interval) {
      setSelectHospitalFrameTime(selectedHospital.mri_interval.toString());
    }

    setSelectHospitalId(id);
    setSelectHospitalName(selectedHospital?.hospital_name ?? "");
    // 各項目リセット
    setSelectedDates(
      Array.from({ length: candidateCount }).map(() => undefined),
    );
    setSelectedTimes(
      Array.from({ length: candidateCount }).map(() => undefined),
    );
  };

  // 戻るボタン
  const handleClickBack = useCallback(
    () => navigateWithBack(),
    [navigateWithBack],
  );

  // 日付選択
  const handleChangeDate = React.useCallback(
    (index: number, date: Date) => {
      setSelectError([]);
      // 日付 セット
      const updatedDates = [...selectedDates];
      updatedDates[index] = date;
      setSelectedDates(updatedDates);
      // 時間 リセット
      const updatedTimes = [...selectedTimes];
      updatedTimes[index] = undefined;
      setSelectedTimes(updatedTimes);
    },
    [selectedDates, selectedTimes],
  );

  // 時間選択
  const handleChangeDropdown = (index: number, time: string) => {
    setSelectError([]);
    const updatedTimes = [...selectedTimes];
    updatedTimes[index] = time;
    setSelectedTimes(updatedTimes);
  };

  // 申し込みボタン押下
  const handleApply = () => {
    setSelectError([]);

    // 必須チェック
    const isRequire = checkBookingRequireRules(
      selectedDates,
      selectedTimes,
      candidateCount,
    );
    if (!isRequire) {
      setSelectError([ERROR_MESSAGES.REQUIRED_SELECTION]);

      return;
    }

    // 重複チェック
    const hasDuplicate = hasDuplicateBookings(selectedDates, selectedTimes);
    if (hasDuplicate) {
      setSelectError([ERROR_MESSAGES.DUPLICATE_BOOKING]);

      return;
    }

    // セッションストレージに保存
    sessionStorage.setItem(
      "mriBookingInput",
      JSON.stringify({
        prefecture,
        selectHospitalId,
        selectHospitalName,
        selectedDates,
        selectedTimes,
        selectHospitalFrameTime,
      }),
    );

    // MRI検査 確認に遷移
    navigateWithUrl(PATIENT.USC019);
  };

  // 候補日削除
  const handleRemoveCandidate = (index: number) => {
    setSelectedDates((currentSelectedDates) => {
      const updatedDates = currentSelectedDates.filter((_, i) => i !== index);

      return updatedDates;
    });

    setSelectedTimes((currentSelectedTimes) => {
      const updatedTimes = currentSelectedTimes.filter((_, i) => i !== index);

      return updatedTimes;
    });

    setTimeOptions((currentOptions) => {
      const updatedOptions = currentOptions.filter((_, i) => i !== index);

      return updatedOptions;
    });

    setCandidateCount((prevCount) => prevCount - 1);
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          selectedTab={SELECTED_TAB_NUM.examination}
          minWidth="1076px"
        />
        <LayoutBox
          fullWidth
          justify="center"
          align="center"
          gap="3x"
          direction="column"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="MRI検査"
            borderBottom="primary"
            onClickBack={handleClickBack}
          />
          <Sheet padding="0px 108px 40px 108px">
            <LayoutBox
              fullWidth
              direction="column"
              gap="3x"
              justify="center"
              align="center"
              maxWidth="440px"
            >
              <LayoutBox direction="column" justify="center" fullWidth>
                <LayoutBox align="center" fullWidth>
                  <Text size="2xl" bold>
                    医療機関の予約
                  </Text>
                </LayoutBox>
                <Divider orange margin="0" borderWidth="3px" />
              </LayoutBox>
              <Sheet type="card" padding="24px 16px" width="100%">
                <LayoutBox
                  fullWidth
                  direction="column"
                  justify="center"
                  align="center"
                  gap="3x"
                >
                  <LayoutBox fullWidth direction="column" gap="1x">
                    <LayoutBox fullWidth direction="column" gap="1x">
                      <Text size="xl">ご希望の医療機関をご選択ください。</Text>
                      <LayoutBox
                        fullWidth
                        direction="column"
                        gap="1x"
                        align="center"
                      >
                        <ExternalMedicalInstitutionsButton />
                      </LayoutBox>
                      <Text bold color="neutralUserLight" size="large">
                        都道府県
                      </Text>
                      <Dropdown
                        items={PREFECTURE_OPTIONS}
                        value={prefecture}
                        onChange={handleChangePrefecture}
                        width="100%"
                      />
                    </LayoutBox>
                    <LayoutBox fullWidth direction="column" gap="1x">
                      <Text bold color="neutralUserLight" size="large">
                        提携医療機関
                      </Text>
                      <Dropdown
                        value={selectHospitalId}
                        disabled={!prefecture}
                        items={hospitals}
                        onChange={handleChangeHospital}
                        width="100%"
                      />
                    </LayoutBox>
                  </LayoutBox>
                  <Sheet
                    color="primary-lightest"
                    padding="16px"
                    borderRadius="8px"
                  >
                    <LayoutBox
                      gap="1x"
                      fullWidth
                      align="center"
                      justify="between"
                    >
                      <Text bold size="large" color="neutralUserBlack">
                        所要時間
                      </Text>
                      <LayoutBox align="center" gap="1x">
                        <Text size="xl" color="primary" bold>
                          {selectHospitalFrameTime}
                        </Text>
                        <Text size="large" color="primary">
                          分
                        </Text>
                      </LayoutBox>
                    </LayoutBox>
                  </Sheet>
                  <Divider darkColor margin="0" />
                  <LayoutBox fullWidth direction="column" gap="3x">
                    <LayoutBox
                      direction="column"
                      justify="center"
                      align="center"
                      gap="1x"
                    >
                      {" "}
                      <Text size="xl">
                        ご希望の来院時間帯を3つ以上ご選択ください。
                        <br />
                        本日ご選択いただけるのは5日後から1か月間となります。
                      </Text>
                      <Text color="neutralUserLight">
                        ※それ以降をご希望の場合は、検査期間内に後日改めてご予約をお願いいたします。
                      </Text>
                    </LayoutBox>
                    {Array.from({ length: candidateCount }, (_, index) => (
                      <LayoutBox
                        fullWidth
                        gap="1x"
                        direction="column"
                        key={index}
                      >
                        <LayoutBox fullWidth gap="1x" align="center">
                          <Text bold size="xl">
                            第{index + 1}候補
                          </Text>
                          {index < 3 ? (
                            <Tag
                              label="必須"
                              state="error"
                              emphasis
                              showIcon={false}
                            />
                          ) : (
                            <Button
                              type="secondary"
                              size="small"
                              width="40px"
                              height="40px"
                              shape="circle"
                              icon="close"
                              onClick={() => handleRemoveCandidate(index)}
                            />
                          )}
                        </LayoutBox>
                        <LayoutBox direction="column" fullWidth gap="1x">
                          <Text size="large" bold color="neutralUserLight">
                            日付
                          </Text>
                          <DatePicker
                            width="100%"
                            disabled={!selectHospitalId}
                            selectedDate={selectedDates[index]}
                            includeDates={includeDates}
                            onChangeDate={(newDate) =>
                              handleChangeDate(index, newDate)
                            }
                          />
                        </LayoutBox>
                        <LayoutBox direction="column" fullWidth gap="1x">
                          <Text size="large" bold color="neutralUserLight">
                            来院時間帯
                          </Text>
                          <LayoutBox
                            direction="row"
                            fullWidth
                            gap="1x"
                            align="center"
                          >
                            <SpDropdown
                              items={timeOptions[index] || []}
                              disabled={!selectedDates[index]}
                              value={selectedTimes[index]}
                              onChange={(time) =>
                                handleChangeDropdown(index, time)
                              }
                              width="75%"
                            />
                            <Text size="xl">の間に来院</Text>
                          </LayoutBox>
                        </LayoutBox>
                      </LayoutBox>
                    ))}

                    <SpButton
                      size="small"
                      type="secondary"
                      icon="add"
                      width="200px"
                      onClick={() =>
                        setCandidateCount((prevCount) => prevCount + 1)
                      }
                      disabled={candidateCount === 5}
                    >
                      候補日を追加
                    </SpButton>
                  </LayoutBox>
                  <Divider darkColor margin="0" />
                  {selectError.map((error, index) => (
                    <div
                      key={index}
                      className="sp-form-set__caption-massage--error"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {error}
                    </div>
                  ))}
                  <SpButton
                    width="311px"
                    withArrowRight
                    size="medium"
                    onClick={() => {
                      void handleApply();
                    }}
                  >
                    確認
                  </SpButton>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC007;
