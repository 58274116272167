import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";
import { formatDate } from "../../utils/utils";

// patient_modality_booksテーブルに_idを元に1件取得
const usePatientModalityFetchById = (id: string) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [patientModality, setPatientModality] = useStateCustomObj({
    fix_book_date_start: "",
    interpretation_note: "",
  });
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const result = (await functions([
          "mongo/client",
          {
            collection: Collection.PATIENT_MODALITY_BOOKS,
            findOne: {
              filter: {
                _id: id,
              },
            },
          },
        ])) as PatientModalityById;

        setPatientModality({
          fix_book_date_start: result.fix_book_date_start
            ? formatDate(result.fix_book_date_start)
            : "",
          interpretation_note: result.interpretation_note || "",
        });
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, setPatientModality, id, functions]);

  return { error, patientModality };
};

export default usePatientModalityFetchById;
