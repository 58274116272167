import React, { useCallback, useEffect, useState } from "react";
import HeaderUser from "../components/Header/HeaderUser";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import Link from "../components/Link/Link";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import {
  useNavigateWithBack,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import { getUserYearSinceActivation, isActiveUser } from "../utils/utils";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import SpButton from "../components/Button/SpButton";
import Divider from "../components/Divider/Divider";
import Notification from "../components/Notification/Notification";
import { PetStatusID, USC_021_TAB_ITEMS } from "../constants/common";
import usePatientPetFetch from "../hooks/common/usePatientPetFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import ExternalMedicalInstitutionsButton from "../components/Button/ExternalMedicalInstitutionsButton";
import InformationDialog from "../components/Dialog/InformationDialog";
import usePath from "../hooks/common/usePath";
import useUsc024Actions from "../hooks/pages/USC024/useUsc024DBActions";

const USC025 = () => {
  const firebaseAppContext = useFirebaseApp();
  const navigate = useNavigateWithUrl();
  const isActive = isActiveUser(firebaseAppContext.userObject);
  const [selectedButton, setSelectedId] = React.useState<string | number>("1");
  const [fixBookMinutes, setFixBookMinutes] = useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [path] = usePath();
  const navigateWithBack = useNavigateWithBack(path);
  const { updateMriPetCancel } = useUsc024Actions();

  // 何年目のユーザか
  const userYearSinceActivation = getUserYearSinceActivation(
    firebaseAppContext.userObject,
  );

  const { patientPet, error: petGetError } = usePatientPetFetch(
    userYearSinceActivation,
  );

  // エラー処理
  useCheckErrorThrowError([petGetError]);

  // キャンセルダイアログclose
  const handleCloseCancelConfirm = () => {
    setIsOpen(false);
  };

  // キャンセルダイアログopen
  const handleCancelConfirm = () => {
    setIsOpen(true);
  };

  // Pet検査時間取得
  useEffect(() => {
    if (!patientPet.length) {
      return;
    }

    if (patientPet[0].fix_book_date_start && patientPet[0].fix_book_date_end) {
      const startDate = new Date(patientPet[0].fix_book_date_start);
      const endDate = new Date(patientPet[0].fix_book_date_end);

      // 検査時間が確定している場合差を計算
      const timeDifferenceInMs = endDate.getTime() - startDate.getTime();
      const timeDifferenceInMinutes = Math.floor(
        timeDifferenceInMs / (1000 * 60),
      );

      // 8日前以降予約キャンセル不可
      setFixBookMinutes(Number(timeDifferenceInMinutes));
    }
  }, [patientPet]);

  // Top画面に遷移
  const handleClickBack = useCallback(
    () => navigateWithBack(),
    [navigateWithBack],
  );

  // タブの切り替え
  const handleTabChange = useCallback(
    (selected: string | number) => {
      setSelectedId(selected);
      if (selected === "2") navigate(PATIENT.USC026);
    },
    [navigate],
  );

  // PET検査のボタン
  const petButton = (
    onClick: () => void,
    disabled: boolean,
    icon: string,
    content: string,
    showDisplay: boolean,
  ) => {
    if (!showDisplay) {
      return <></>;
    }
    const buttonType = icon === "block" ? "danger" : "primary";

    return (
      <SpButton
        size="medium"
        mobileFullWidth
        onClick={onClick}
        disabled={disabled}
        icon={icon}
        type={buttonType}
        width="350px"
      >
        {content}
      </SpButton>
    );
  };

  // PET検査のボタン
  const renderPetButton = (
    patientPet: PatientPet[],
    navigate: (path: string) => void,
  ) => {
    let icon;
    let content;
    let onClick;
    let disabled;
    let showDisplay = true;
    if (patientPet.length) {
      const petObj = patientPet[0];

      switch (petObj.pet_status) {
        case PetStatusID.RESERVED:
          icon = "block";
          content = "予約取消";
          onClick = handleCancelConfirm;
          disabled = false;
          break;
        case PetStatusID.RESERVATION_CONFIRMED:
        case PetStatusID.EXAMINED:
        case PetStatusID.DIAGNOSED:
          icon = "";
          content = "予約する";
          disabled = true;
          showDisplay = false;
          break;
        case PetStatusID.NO_EXAMINATION:
        case PetStatusID.USER_REPORTED:
        default:
          icon = "";
          content = "予約する";
          onClick = () => navigate(PATIENT.USC012);
          disabled = false;
          break;
      }
    } else {
      icon = "";
      content = "予約する";
      onClick = () => navigate(PATIENT.USC012);
      disabled = false;
    }

    return petButton(
      onClick ?? (() => {}),
      disabled,
      icon,
      content,
      showDisplay,
    );
  };

  const renderPetHeader = () => (
    <>
      <Text size="xl">FDG-PET検査</Text>
      <Divider darkColor orange={false} />
    </>
  );

  const renderPetDescription = () => (
    <Text size="xl">ご希望により別途料金で精密検査の受検が可能です。</Text>
  );

  const renderMedicalAppointment = () => (
    <>
      <Text size="xl" bold>
        医療機関の予約
      </Text>
      <Text size="xl">FDG-PET検査</Text>
    </>
  );

  const handleClickCancel = (petId: string) => {
    void (async () => {
      const isSuccess = await updateMriPetCancel(petId, "", true);
      if (!isSuccess) return;
      navigate(PATIENT.USC040);
      window.location.reload(); // ヘッダーを無理やり更新
    })();
  };

  const petData = patientPet.length > 0 ? patientPet[0] : undefined;

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
          isActiveUser={isActive}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="検査"
          content="各種検査の受検と結果の確認ができます"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <ToggleButton
                size="large"
                width="100%"
                items={USC_021_TAB_ITEMS.slice(0, 2)}
                onClick={handleTabChange}
                selectedButton={selectedButton}
              />
            </LayoutBox>
            <Title
              type="h2"
              header="FDG-PET検査"
              borderBottom="primaryLight"
              withButton={false}
            />
          </LayoutBox>
        </Sheet>
        <LayoutBox direction="column" gap="3x" fullWidth>
          <Sheet>
            <Sheet type="card" maxWidth="1064px">
              <LayoutBox direction="column" fullWidth align="center" gap="3x">
                {/* 予約前 */}
                {(patientPet.length === 0 ||
                  [
                    PetStatusID.USER_REPORTED,
                    PetStatusID.NO_EXAMINATION,
                  ].includes(patientPet[0]?.pet_status)) && (
                  <>
                    <LayoutBox
                      direction="column"
                      fullWidth
                      align="start"
                      gap="1x"
                    >
                      {renderPetHeader()}
                      {renderPetDescription()}
                      {renderMedicalAppointment()}
                    </LayoutBox>
                    <ExternalMedicalInstitutionsButton />
                  </>
                )}
                {/* キャンセル後 */}
                {[
                  PetStatusID.WITHDRAWN_AFTER_CONFIRMATION,
                  PetStatusID.NG_BEFORE_CONFIRMATION,
                ].includes(patientPet[0]?.pet_status) && (
                  <>
                    <LayoutBox
                      direction="column"
                      fullWidth
                      align="start"
                      gap="1x"
                    >
                      {renderPetHeader()}
                      {renderPetDescription()}
                      {renderMedicalAppointment()}
                    </LayoutBox>
                    <ExternalMedicalInstitutionsButton />
                  </>
                )}
                {/* 予約確定前 */}
                {patientPet[0]?.pet_status === PetStatusID.RESERVED && (
                  <LayoutBox
                    direction="column"
                    fullWidth
                    align="start"
                    gap="1x"
                  >
                    {renderPetHeader()}
                    {renderPetDescription()}
                    <Divider darkColor orange={false} />
                    {renderMedicalAppointment()}
                    <Notification
                      text="現在予約を調整中です。"
                      colorType="warning"
                    />
                    <Divider darkColor orange={false} />
                    <Text size="xl" color="neutralUserLight">
                      都道府県
                    </Text>
                    <Text size="xl">{patientPet[0]?.prefecture}</Text>
                    <Divider darkColor orange={false} />
                    <Text size="xl" color="neutralUserLight">
                      提携医療機関
                    </Text>
                    <Text size="xl">{patientPet[0]?.hospital_name}</Text>
                    <Divider darkColor orange={false} />
                    {petData && petData.candidate_book_dates && (
                      <LayoutBox direction="column" gap="3x" fullWidth>
                        {Object.entries(petData.candidate_book_dates).map(
                          ([key, value], index) =>
                            value ? (
                              <LayoutBox
                                direction="column"
                                gap="1x"
                                fullWidth
                                key={key}
                              >
                                <Text size="xl" bold color="neutralUserBlack">
                                  第{index + 1}候補
                                </Text>
                                <Text size="xl" color="neutralUserBlack">
                                  {value.split(" ")[0]}
                                </Text>
                                <Text size="xl" color="neutralUserBlack">
                                  {value.split(" ")[1]}の間に来院
                                </Text>
                                <Divider darkColor margin="0" />
                              </LayoutBox>
                            ) : null,
                        )}
                      </LayoutBox>
                    )}
                    {/* 予約の変更・キャンセルについて */}
                    <LayoutBox direction="column" gap="2x" fullWidth>
                      <Text size="xl" bold color="neutralUserBlack">
                        予約の変更・キャンセルについて
                      </Text>
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <LayoutBox direction="row" gap="1x">
                          <Link
                            size="xl"
                            color="primary"
                            url="https://dementia365.com/cancelpolicy/"
                          >
                            キャンセルポリシー
                          </Link>
                          <Text size="xl" color="neutralUserBlack">
                            をご確認ください。
                          </Text>
                        </LayoutBox>
                        <Divider darkColor margin="0" />
                      </LayoutBox>
                    </LayoutBox>
                  </LayoutBox>
                )}
                {/* 予約確定後 */}
                {patientPet[0]?.pet_status ===
                  PetStatusID.RESERVATION_CONFIRMED && (
                  <LayoutBox
                    direction="column"
                    fullWidth
                    align="start"
                    gap="1x"
                  >
                    {renderPetHeader()}
                    {renderPetDescription()}
                    <Divider darkColor orange={false} />
                    <Text size="xl" color="neutralUserLight">
                      都道府県
                    </Text>
                    <Text size="xl">{patientPet[0]?.prefecture}</Text>
                    <Divider darkColor orange={false} />
                    <Text size="xl" color="neutralUserLight">
                      提携医療機関
                    </Text>
                    <Text size="xl">{patientPet[0]?.hospital_name}</Text>
                    <Divider darkColor orange={false} />
                    <Text size="xl" color="neutralUserLight">
                      検査時間
                    </Text>
                    <Text size="xl">
                      {patientPet[0]?.fix_book_date}（{fixBookMinutes}分）
                    </Text>
                    <Divider darkColor orange={false} />
                    {/* 予約の変更・キャンセルについて */}
                    <LayoutBox direction="column" gap="2x" fullWidth>
                      <Text size="xl" color="neutralUserBlack">
                        予約の変更・キャンセルについて
                      </Text>
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <LayoutBox direction="row" gap="1x">
                          <Link
                            size="xl"
                            color="primary"
                            url="https://dementia365.com/cancelpolicy/"
                          >
                            キャンセルポリシー
                          </Link>
                          <Text size="xl" color="neutralUserBlack">
                            をご確認ください。
                          </Text>
                        </LayoutBox>
                        <Divider darkColor margin="0" />
                      </LayoutBox>
                    </LayoutBox>
                  </LayoutBox>
                )}
                {/* 受検後 */}
                {[PetStatusID.EXAMINED, PetStatusID.DIAGNOSED].includes(
                  patientPet[0]?.pet_status,
                ) && (
                  <LayoutBox
                    direction="column"
                    fullWidth
                    align="start"
                    gap="2x"
                  >
                    {renderPetHeader()}
                    <Notification text="受検期間外です" colorType="warning" />
                    {renderPetDescription()}
                    {renderMedicalAppointment()}
                  </LayoutBox>
                )}

                {isActive && renderPetButton(patientPet, navigate)}
              </LayoutBox>
            </Sheet>
          </Sheet>
        </LayoutBox>
      </div>
      <PatientFooter />
      {isOpen && (
        <InformationDialog
          open={isOpen}
          closeDialog={handleCloseCancelConfirm}
          title="予約取消"
          isShowCloseButton={false}
          alertLevel="error"
          width="350px"
          footer={
            <>
              <LayoutBox justify="center" wrapReverse gap="2x">
                <SpButton
                  type="danger"
                  width="275px"
                  onClick={() => handleClickCancel(patientPet[0]._id)}
                >
                  予約取消
                </SpButton>
                <SpButton
                  type="secondary"
                  width="275px"
                  onClick={handleCloseCancelConfirm}
                >
                  キャンセル
                </SpButton>
              </LayoutBox>
            </>
          }
        >
          <LayoutBox align="center">
            <LayoutBox
              direction="column"
              gap="none"
              align="start"
              justify="start"
            >
              <Text size="xl">
                FDG-PET検査の予約を取り消しますがよろしいですか？
              </Text>
            </LayoutBox>
          </LayoutBox>
        </InformationDialog>
      )}
    </div>
  );
};

export default USC025;
