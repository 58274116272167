import { Navigate, useParams } from "react-router-dom";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { PATIENT } from "../constants/pagePaths";


const RequiredAutoLoginElement: React.FC<{ element: JSX.Element }> = ({
    element,
}) => {
    const { currentUser, customTokenSignIn } = useFirebaseApp();
    const { token } = useParams();
    if (!currentUser && token) {
        customTokenSignIn(token).catch((e) => { console.log(e) })
    }

    // const isLoading = currentUser === undefined; // No direct way to check loading status. So check currentUser is undefined or null
    const isLogin = Boolean(currentUser);
    if (!isLogin) return <></>;

    return isLogin ? element : <Navigate to={PATIENT.USA001} />;
};

export default RequiredAutoLoginElement;
