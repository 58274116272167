import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import USI001 from "../pages/US-I001";
import {
  openNlj002Dialog,
  setMessage,
  setIsError,
} from "../hooks/pages/NLJ002/useNlj002Dialog";

export const ERROR_INFO = {
  // 不正アクセス
  INVALID_ACCESS: {
    MESSAGE: "Invalid access. Please access from the correct route.",
  },
  // 該当データ無し
  NO_DATA: {
    MESSAGE: "No data found.",
  },
  // 実装エラー
  INVALID_FUNCTION: {
    MESSAGE:
      "An unexpected error occurred. We'd appreciate it if you could report this issue to our system administrator.",
  },
  // CSVフォーマット
  INVALID_CSV_FORMAT: {
    MESSAGE:
      "The uploaded file format is invalid. Please upload a file in the correct format.",
  },
  NO_ROLE: {
    MESSAGE:
      "You do not have permission to view this page. Please contact your administrator if you believe this is an error.",
  },
  // ネットワークエラー
  NETWORK_ERROR: {
    MESSAGE:
      "You are not connected to the network. Please check your connection and reload the page.",
  },
  // 不正なリクエスト
  BAD_REQUEST: {
    MESSAGE: "Your request is invalid. Please check your input.",
  },
  // 不正な権限
  NO_ROLE_ACTION: {
    MESSAGE: "Your request is invalid. Please check your input.",
  },
  // 予期せぬエラー
  UNEXPECTED_ERROR: {
    MESSAGE: "An unexpected error has occurred.",
  },
};

const ERROR_MESSAGES = {
  NO_ROLE: {
    title: "権限がありません。",
    message: "管理者にお問い合わせください。",
  },
  USER_NOT_FOUND: {
    title: "送信に失敗しました。",
    message: "メールアドレスが未登録です。新規会員登録を行ってください。",
  },
  ALREADY_CONFIRMED: {
    title: "送信に失敗しました。",
    message: "既に認証済みのメールアドレスです。",
  },
  STOCK: {
    title: "処理に失敗しました。",
    message: "申し訳ございません。在庫が不足しております。",
  },
  TOO_MANY_ATTEMPTS_TRY_LATER: {
    title: "処理に失敗しました。",
    message: "短時間に複数回同じリクエストがありました。時間を空けて再度お試しください。",
  },
  COMPLETED_ORDER: {
    title: "処理に失敗しました。",
    message: "いただいた注文は、購入済みです。",
  },
  CAMPAIGN: {
    title: "処理に失敗しました。",
    message: "キャンペーンコードが存在しません",
  },
  MONGO: {
    title: "処理に失敗しました。",
    message: "もう一度お試しください。",
  },
};

const determineErrorMessage = (errorMessage: string) => {
  if (errorMessage === ERROR_INFO.NO_ROLE_ACTION.MESSAGE)
    return ERROR_MESSAGES.NO_ROLE;
  if (errorMessage.includes("user not found") || errorMessage.includes("User is not found"))
    return ERROR_MESSAGES.USER_NOT_FOUND;
  if (errorMessage.includes("already confirmed"))
    return ERROR_MESSAGES.ALREADY_CONFIRMED;
  if (errorMessage.includes("Stock is not enough."))
    return ERROR_MESSAGES.STOCK;
  if (errorMessage.includes("TOO_MANY_ATTEMPTS_TRY_LATER"))
    return ERROR_MESSAGES.TOO_MANY_ATTEMPTS_TRY_LATER;
  if (errorMessage.includes("order type is not cart order."))
    return ERROR_MESSAGES.COMPLETED_ORDER;
  if (errorMessage.includes("invalid campaign code"))
    return ERROR_MESSAGES.CAMPAIGN;
  if (
    errorMessage.includes("Failed to fetch") ||
    errorMessage.includes("Request failed")
  )
    return ERROR_MESSAGES.MONGO;

  return { title: "", message: "" };
};

// エラーページ遷移
export const redirectToInvalidAccessPage = () => {
  throw new Error(ERROR_INFO.INVALID_ACCESS.MESSAGE);
};
export const redirectToNoDataPage = () => {
  throw new Error(ERROR_INFO.NO_DATA.MESSAGE);
};
export const redirectToInvalidFunctionPage = () => {
  throw new Error(ERROR_INFO.INVALID_FUNCTION.MESSAGE);
};
export const redirectToInvalidCsvFormatPage = () => {
  throw new Error(ERROR_INFO.INVALID_CSV_FORMAT.MESSAGE);
};
export const redirectToNoRolePage = () => {
  throw new Error(ERROR_INFO.NO_ROLE.MESSAGE);
};

// エラーダイアログを表示
export const openNoRoleDialog = () => {
  throw new Error(ERROR_INFO.NO_ROLE_ACTION.MESSAGE);
};

const errorMessages = Object.values(ERROR_INFO).map(({ MESSAGE }) => MESSAGE);

export const checkFetchErr = (err: unknown): Error => {
  const error = err as Error;
  const errorMessage = error.message;

  // 設定されているエラーの場合
  if (errorMessages.includes(errorMessage)) {
    return error;
  }

  // MongoDB由来のエラーの場合
  if (errorMessage.includes("Failed to fetch")) {
    return new Error(ERROR_INFO.NETWORK_ERROR.MESSAGE);
  }

  return new Error(ERROR_INFO.UNEXPECTED_ERROR.MESSAGE);
};

// エラーメッセージに応じてダイアログを表示
const _openErrorDialog = (
  errorMessage: string,
  clickDialogButton = () => {},
) => {
  const { title, message } = determineErrorMessage(errorMessage);

  // エラーダイアログを表示
  if (title) {
    setMessage(title, message);
    setIsError(true);
    clickDialogButton();
    openNlj002Dialog();
  }

  return Boolean(title);
};
export const checkActionErr = (
  err: unknown,
  clickDialogButton = () => {},
): Error | null => {
  const error = err as Error;
  const errorMessage = error.message;

  if (_openErrorDialog(errorMessage, clickDialogButton)) return null;

  return new Error(ERROR_INFO.UNEXPECTED_ERROR.MESSAGE);
};

const ErrorFallback: React.FC<FallbackProps> = ({
  error: rawError,
  resetErrorBoundary,
}) => {
  const error = rawError as Error;
  const errorMessage = error.message;

  switch (errorMessage) {
    case ERROR_INFO.INVALID_ACCESS.MESSAGE:
    case ERROR_INFO.NO_DATA.MESSAGE:
    case ERROR_INFO.INVALID_FUNCTION.MESSAGE:
    case ERROR_INFO.INVALID_CSV_FORMAT.MESSAGE:
      return <USI001 resetErrorBoundary={resetErrorBoundary} />;
    default:
      return <USI001 resetErrorBoundary={resetErrorBoundary} />;
  }
};

const CustomErrorBoundary = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
);

export default CustomErrorBoundary;
