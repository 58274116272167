import useSWR from "swr";
import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateUSD003 } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import generateUniqueId from "../../../utils/generateUniqueId";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";

const useUSD003Actions = () => {
  const [actionError, setActionError] = useState<Error | null>(null);
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  /**
   *  ユーザーの全てのチャットを取得します。
   */
  const getAllChatData = async (): Promise<Chats[]> => {
    const aggregate = getAggregateUSD003(currentUser?.uid);
    const chats = (await functions([
      "mongo/client",
      { collection: Collection.CHATS, aggregate },
    ])) as Chats[];

    return chats;
  };

  // 1秒間隔で、チャットデータが更新されたかを取得
  const { data: allChatData = [], error: fetchError = null } = useSWR<
    Partial<Chats>[],
    Error
  >("getAllChatData", getAllChatData, {
    refreshInterval: 1000,
  });

  /**
   *  チャットを追加します。
   * @param {String} ご相談内容
   */
  const addComment = async (props: { comment: string }) => {
    const { comment } = props;

    try {
      const updateData = {
        _id: generateUniqueId(),
        comment,
        patient_id: currentUser?.uid,
        admin_user_id: undefined,
        commented_user: "patient",
        register_date: new Date(),
        files: undefined,
        monthly_report: false,
        is_read: false,
        created_at: new Date(),
        updated_at: new Date(),
      };
      await functions([
        "mongo/client",
        {
          collection: Collection.CHATS,
          insertOne: { doc: updateData },
        },
      ]);
    } catch (err) {
      setActionError(checkActionErr(err));
    }
  };

  useEffect(() => {
    // 管理者側のメッセージを既読に変更します。
    const changeToRead = async () => {
      try {
        const updateData = {
          is_read: true,
        };
        await functions([
          "mongo/client",
          {
            collection: Collection.CHATS,
            updateMany: {
              filter: {
                patient_id: currentUser?.uid,
                commented_user: "admin",
                is_read: false,
              },
              update: { $set: updateData },
            },
          },
        ]);
      } catch (err) {
        setActionError(checkActionErr(err));
      }
    };
    void changeToRead();
  }, [currentUser, allChatData, functions]);

  return {
    fetchError,
    actionError,
    allChatData,
    addComment,
  };
};

export default useUSD003Actions;
