import React, { useCallback } from "react";
import HeaderUser from "../components/Header/HeaderUser";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import { isActiveUser } from "../utils/utils";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import SpButton from "../components/Button/SpButton";
import Divider from "../components/Divider/Divider";
import {
  BRAIN_CHECKS_PHONE_NUMBER,
  USC_021_TAB_ITEMS,
} from "../constants/common";
import BrainHealth from "../assets/brain_health.png";
import useDeviceType from "../hooks/common/useDeviceType";
import useBrainCheckEligibility from "../hooks/common/useBrainCheckEligibility";
import usePath from "../hooks/common/usePath";
import InformationDialog from "../components/Dialog/InformationDialog";
import Link from "../components/Link/Link";
import FreeNumber from "../assets/toll-free-number_logo.png";

interface InformationSectionProps {
  isExpanded: boolean;
}

const InformationSection: React.FC<InformationSectionProps> = ({
  isExpanded,
}) => (
  <LayoutBox direction="column" fullWidth align="start" gap="2x">
    {isExpanded && (
      <>
        <LayoutBox direction="column" gap="1x" fullWidth>
          <Text size="large" color="neutralUserLight">
            【ご利用に際して】
          </Text>
          <Text size="large" color="neutralUserLight">
            ・&nbsp;本サービスは、医療行為および診察行為を行うものではなく、本テストを通じて、
            ご利用者の認知機能の経時変化を確認できる簡易チェックテストの提供や受検済者本人の
            認知症に関するアドバイス等を提供するものです。認知機能の一般的な評価を提供することを
            目的とし、認知機能に関与し得る病状または病気を単独で診断、治療、予防することを目的としたものではありません。
          </Text>
          <Text size="large" color="neutralUserLight">
            ・&nbsp;ご利用の際は、発信者番号を通知してお掛けください。非通知発信ではご利用いただけません。
          </Text>
          <Text size="large" color="neutralUserLight">
            ・&nbsp;国外の相談および国外からの相談はお受けできません。
          </Text>
          <Text size="large" color="neutralUserLight">
            ・&nbsp;本サービス利用中にご利用者の病気や症状が改善することを保証するものではありません。
          </Text>
          <Text size="large" color="neutralUserLight">
            ・&nbsp;サービス提供の際の録音、録画、撮影のご要望には原則として応じられません。
          </Text>
          <Text size="large" color="neutralUserLight">
            ・&nbsp;ご利用者の状況またはご相談内容により相談の制限・停止をさせていただく場合があります。
          </Text>
          <Text size="large" color="neutralUserLight">
            ・&nbsp;利用条件や、地域・内容により、ご要望に添えない場合がありますので、不明点はお問い合わせください。
          </Text>
          <Text size="large" color="neutralUserLight">
            ・&nbsp;本サービスは2024年10月現在のものであり、将来予告なく変更されることがあります。
          </Text>
          <Text size="large" color="neutralUserLight">
            ・&nbsp;本テストのライセンスは、株式会社ミレニアに帰属します。
          </Text>
        </LayoutBox>
      </>
    )}
  </LayoutBox>
);

const USC023 = () => {
  const firebaseAppContext = useFirebaseApp();
  const navigate = useNavigateWithUrl();
  const isActive = isActiveUser(firebaseAppContext.userObject);
  const [selectedButton, setSelectedId] = React.useState<string | number>("1");
  const [isExpanded, setIsExpanded] = React.useState(false);
  const isMobile = useDeviceType();
  const { isBrainCheckEligible } = useBrainCheckEligibility();
  const [isOpen, setIsOpen] = React.useState(false);
  const [path] = usePath();

  // Top画面に遷移
  const handleClickBack = useCallback(() => navigate(path), [navigate, path]);

  // 電話認知機能ダイアログclose
  const handleCloseCallConfirm = () => {
    setIsOpen(false);
  };

  // 電話認知機能ダイアログopen
  const handleCallConfirm = () => {
    setIsOpen(true);
  };

  // タブの切り替え
  const handleTabChange = useCallback(
    (selected: string | number) => {
      setSelectedId(selected);
      if (selected === "2") navigate(PATIENT.USC026);
    },
    [navigate],
  );

  const handleCallPhoneNumber = () => {
    const phoneLink = `tel:${BRAIN_CHECKS_PHONE_NUMBER}`;

    // ダミーアンカータグを作成してクリックイベントを発火
    const a = document.createElement("a");
    a.href = phoneLink;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
          isActiveUser={isActive}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="検査"
          content="各種検査の受検と結果の確認ができます"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <ToggleButton
                size="large"
                width="100%"
                items={USC_021_TAB_ITEMS.slice(0, 2)}
                onClick={handleTabChange}
                selectedButton={selectedButton}
              />
            </LayoutBox>
            <Title
              type="h2"
              header="電話認知機能評価"
              borderBottom="primaryLight"
              withButton={false}
            />
          </LayoutBox>
        </Sheet>
        <LayoutBox direction="column" gap="3x" fullWidth>
          <Sheet>
            <Sheet type="card" maxWidth="1064px">
              <LayoutBox direction="column" fullWidth align="center" gap="3x">
                <LayoutBox direction="column" fullWidth align="start" gap="2x">
                  <LayoutBox direction="row" align="center" gap="2x" fullWidth>
                    <img
                      src={BrainHealth}
                      alt="brainHealth"
                      className="image--brain_health"
                    />
                    <Text size="xl">あたまの健康チェック®</Text>
                  </LayoutBox>
                  <Divider darkColor orange={false} />
                  <LayoutBox direction="column" gap="1x" fullWidth>
                    <Text size="xl">年に1回受検してください。</Text>
                    <Text size="xl">
                      ご自宅にいながら、お電話で短時間・高精度の認知機能チェックが受けられます。もの忘れの訴えの無い時期からご自身の「あたまの健康」状態の変化を定期的にご確認いただけます。また希望に応じて認知症等に関するご相談にも対応します。
                    </Text>
                    <Link
                      size="medium"
                      color="primary"
                      url="https://www.millennia-corporation.jp/ninchi/mci/about_mci.html"
                    >
                      「あたまの健康チェック®」についてもっと詳しく見る
                    </Link>
                  </LayoutBox>
                  <LayoutBox direction="column" gap="1x" fullWidth>
                    <br />
                    <Text bold size="xl">
                      【受検方法】
                    </Text>
                    <Text size="xl">
                      <div style={{ paddingLeft: "20px" }}>
                        <strong>
                          １．「受検する」ボタンからフリーダイヤルにお繋ぎください。
                        </strong>
                        <br />
                      </div>
                      <div style={{ paddingLeft: "40px" }}>
                        つながりましたら、
                        <u>チャット相談でお知らせした 「PIN番号(受検番号)」</u>
                        をオペレーターにお伝えください。
                        <br />
                        <strong>
                          ＜受付時間：月～土
                          9:00～17:00、日曜･祝日･12/31～1/3を除く＞
                        </strong>
                        <br />
                        <Text size="base">
                          ※通話料は無料です。
                          <br />
                          ※電話が混み合って、つながりにくい場合があります。その場合は、少し時間を空けてからおかけ直しください。
                          <br />
                          ※PC版をご利用の方は「受検する」ボタンをクリックするとフリーダイヤルが表示されます。
                        </Text>
                      </div>
                    </Text>
                    <Text size="xl">
                      <div style={{ paddingLeft: "20px" }}>
                        <strong>
                          ２．オペレーターより、氏名・生年月日・住所などの基本情報をお伺い致します。
                        </strong>
                      </div>
                    </Text>
                    <Text size="xl">
                      <div style={{ paddingLeft: "20px" }}>
                        <strong>
                          ３．オペレーターの指示に従い、10分程度の本テストを行います。
                        </strong>
                      </div>
                    </Text>
                    <Text size="xl">
                      <div style={{ paddingLeft: "20px" }}>
                        <strong>
                          ４．本テスト終了後、1週間程度で結果レポートをご案内いたします。
                        </strong>
                      </div>
                      <div style={{ paddingLeft: "40px" }}>
                        （本サイト内でのご案内 および
                        ご本人様宛にティーペック株式会社より郵送）
                      </div>
                    </Text>
                    <br />
                  </LayoutBox>
                  <LayoutBox direction="column" gap="1x" fullWidth>
                    <Text size="xl">【注意事項】</Text>
                    <Text size="xl">
                      ・&nbsp;お電話でのテストとなりますので、電波状況など途中で通話が切れない環境での受検をお願いいたします。
                    </Text>
                    <Text size="xl">
                      ・&nbsp;適切なテスト実施のため、静かでリラックスできる状況での受検をお願いいたします。
                    </Text>
                    <Text size="xl">
                      ・&nbsp;1つのPIN番号で1回の「あたまの健康チェック®」が受けられます。
                    </Text>
                    <Text size="xl">
                      ・&nbsp;本テストに関するシステムの設計、管理には万全を期しておりますが、停電、システム障害、メンテナンス、その他やむを得ない事情により本テストコールセンターにおいて、予告なく本テストのご利用内容の一部またはすべてにつき一時的に停止する場合があります。
                    </Text>
                  </LayoutBox>
                  <InformationSection isExpanded={isExpanded} />
                </LayoutBox>
                <SpButton
                  type="secondary"
                  size="medium"
                  mobileFullWidth
                  icon={
                    isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"
                  }
                  onClick={() => setIsExpanded(!isExpanded)}
                  iconSize="large"
                >
                  {isExpanded ? "閉じる" : "すべて表示"}
                </SpButton>
                <SpButton
                  size="medium"
                  icon="phone"
                  mobileFullWidth
                  withArrowRight
                  onClick={isMobile ? handleCallPhoneNumber : handleCallConfirm}
                  disabled={!isBrainCheckEligible}
                >
                  受検する
                </SpButton>
              </LayoutBox>
            </Sheet>
          </Sheet>
        </LayoutBox>
      </div>
      <PatientFooter />
      {isOpen && (
        <InformationDialog
          open={isOpen}
          closeDialog={handleCloseCallConfirm}
          title="お電話での受検となります"
          isShowCloseButton={false}
          alertLevel="error"
          width="350px"
          footer={
            <>
              <LayoutBox justify="center" wrapReverse gap="2x">
                <SpButton
                  type="secondary"
                  width="275px"
                  onClick={handleCloseCallConfirm}
                >
                  キャンセル
                </SpButton>
              </LayoutBox>
            </>
          }
        >
          <LayoutBox align="center">
            <LayoutBox
              direction="column"
              gap="1x"
              align="start"
              justify="start"
            >
              <Text size="xl">
                「PIN番号(受検番号)」をご用意のうえ、こちらのフリーダイヤルにおかけください。
              </Text>
              <br />
              <Text size="xl">
                <img
                  className="image--free_number"
                  src={FreeNumber}
                  alt="freeNumber"
                />
                {BRAIN_CHECKS_PHONE_NUMBER}
              </Text>
            </LayoutBox>
          </LayoutBox>
        </InformationDialog>
      )}
    </div>
  );
};

export default USC023;
