import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsk001 } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

const useUsk001DBFetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [brainChecks, setBrainChecks] = useState<USK001Type[]>([]);
  const [brainChecksFetchError, setBrainChecksFetchError] =
    useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsk001(currentUser?.uid ?? "");
        const result = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_BRAIN_CHECKS, aggregate },
        ])) as USK001Type[];

        setBrainChecks(result);
      } catch (err) {
        setBrainChecksFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions]);

  return {
    brainChecks,
    brainChecksFetchError,
  };
};

export default useUsk001DBFetch;
