import { useState } from "react";

import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

type GcsUrl = {
  gcs_path: string;
  signed_url: string;
};

const useSignedMriUrl = () => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;

  const [signedMriUrlError, setSignedMriUrlError] = useState<Error | null>(
    null,
  );

  const fetchSignedMriUrl = async (modalityId: string) => {
    addLoadCount();
    try {
      const response = (await functions([
        "mri/brainsuite/downloadReport",
        modalityId,
      ])) as GcsUrl;

      const signedUrl = response.signed_url;

      if (!signedUrl) {
        const error = new Error("Signed URL not received");
        setSignedMriUrlError(checkActionErr(error));
      }

      return signedUrl;
    } catch (err) {
      setSignedMriUrlError(checkActionErr(err));

      return null;
    } finally {
      decrementLoadCount();
    }
  };

  return {
    fetchSignedMriUrl,
    signedMriUrlError,
  };
};

export default useSignedMriUrl;
