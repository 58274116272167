
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactNode, useEffect } from "react";
import { FirebaseError, initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateEmail,
  User,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

import { redirectToInvalidFunctionPage } from "./CustomErrorBoundary";
import { handleFirebaseError } from "./errors";

interface FirebaseAppContextInterface {
  functions: (data: [string, any]) => Promise<string | number | KV | KV[]>;
  currentUser?: User | null;
  userObject: KV | null;
  refreshCustomData: () => Promise<void>;
  register: (email: string, password: string) => Promise<string | null>;
  logIn: (email: string, password: string) => Promise<void>;
  logOut: () => Promise<void>;
  changeEmail: (newEmail: string) => Promise<void>;
  deleteUser: (userId: string) => Promise<string | null>;
  providerSignIn: (provider: string) => Promise<User | null>;
  customTokenSignIn: (token: string) => Promise<User | null>;
}

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const firebaseErrorMessage = (error: unknown) => {
  if (!(error instanceof FirebaseError)) {
    return error instanceof Error ? error.message : "エラーが発生しました。";
  }

  switch (error.code) {
    case "auth/invalid-email":
      return "メールアドレスが正しくありません。";
    case "auth/user-not-found":
      return "そのメールアドレスを持つユーザーが見つかりませんでした。";
    case "auth/wrong-password":
      return "パスワードが間違っています。";
    case "auth/user-disabled":
      return "このアカウントは無効になっています。";
    case "auth/email-already-in-use":
      return "このメールアドレスはすでに別のアカウントで使用されています。";
    case "auth/weak-password":
      return "パスワードが弱すぎます。";
    case "auth/popup-closed-by-user":
      return "認証が完了する前にポップアップが閉じられました。";
    case "auth/cancelled-popup-request":
      return "ポップアップのリクエストがキャンセルされました。";
    case "auth/unverified-email":
      return "メールアドレスが認証されていません。";
    case "auth/too-many-requests":
      return "リクエストが多すぎます。しばらくしてからもう一度お試しください。";
    case "auth/operation-not-allowed":
      return "この操作は許可されていません。";
    case "auth/requires-recent-login":
      return "この操作には最近のログインが必要です。";
    case "auth/invalid-api-key":
      return "APIキーが無効です。";
    case "auth/app-not-authorized":
      return "この操作は許可されていません。";
    default:
      return "認証エラーが発生しました。";
  }
};

const auth = getAuth(app);
const firebaseFunctions = getFunctions(app, "asia-northeast1");

const FirebaseAppContext = React.createContext<FirebaseAppContextInterface>(
  {} as FirebaseAppContextInterface,
);

// const transferMongoType = (obj: any): any => {
//   if (typeof obj !== "object" || obj === null) {
//     return obj; // Return primitives, null, and Dates as-is
//   }

//   if (Array.isArray(obj)) {
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-return
//     return obj.map(transferMongoType); // Recursively process arrays
//   }

//   if ("$numberInt" in obj) {
//     return parseInt((obj as KV).$numberInt as string, 10); // Convert and return the number
//   }
//   if ("$numberLong" in obj) {
//     return Number((obj as KV).$numberLong); // Convert and return the number
//   }

//   if ("$numberDouble" in obj) {
//     return Number((obj as KV).$numberDouble); // Convert and return the number
//   }

//   const newObj: KV = {};
//   Object.keys(obj as KV).forEach((key) => {
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
//     const value = (obj as KV)[key];
//     // Dateオブジェクトの場合、そのまま返す
//     if (["created_at", "updated_at", "activation_date"].includes(key)) {
//       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
//       newObj[key] = value;
//     } else {
//       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
//       newObj[key] = transferMongoType(value);
//     }
//   });

//   return newObj;
// };

// const getLineAuthUrl = () => {
//   const lineClientId = process.env.REACT_APP_LINE_CLIENT_ID as string;
//   const state = `line_${Math.floor(Math.random() * Date.now()).toString(36)}`;
//   // window.localStorage.setItem(`${id}:stateForAuth`, state);

//   return `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${lineClientId}&redirect_uri=${encodeURIComponent(
//     `${window.location.protocol}//${window.location.host}/ExternalLogin`,
//   )}&state=${state}&scope=profile%20openid&nonce=09876xyz`;
// };

export const useFirebaseApp = () => {
  const appContext = React.useContext(FirebaseAppContext);
  if (!appContext) {
    return redirectToInvalidFunctionPage();
  }

  return appContext;
};

export const isEmailVefied = (user?: User | null) => {
  if (!user) return false;
  if (user.providerData.find(v => v.providerId === 'password') && !user.emailVerified) return false;

  return true;
};


const getProviderUidFromUser = (user: User | null) => {
  if (!user || !user.providerData) {
    return {};
  }

  const googleProvider = user.providerData.find(
    (provider) => provider.providerId === 'google.com'
  );

  const lineProvider = user.providerData.find(
    (provider) => provider.providerId === 'oidc.line'
  );

  return googleProvider ? { google: googleProvider.uid } : lineProvider ? { line: lineProvider.uid } : {}
};



const isoStringToDate = (isoString: any): Date | null => {
  if (typeof isoString !== "string") return null;
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(isoString)) {
    return null;
  }
  const testDate = new Date(isoString);

  return !Number.isNaN(testDate.getTime()) &&
    testDate.toISOString() === isoString
    ? testDate
    : null;
};

const objectParser = (obj: any): number | Date | null => {
  if (!(obj instanceof Object)) return null;
  if ((obj as KV).$date)
    return new Date((obj as KV).$date as string);
  if ((obj as KV).$numberInt)
    return parseInt((obj as KV).$numberInt as string, 10);
  if ((obj as KV).$numberLong)
    return parseInt((obj as KV).$numberLong as string, 10);
  if ((obj as KV).$numberDouble) return Number((obj as KV).$numberDouble);
  if ((obj as KV).$numberDecimal) return Number((obj as KV).$numberDecimal);

  return null;
};

/**
 * Call Firebase function.
 * Unauthenticated call is allowed, but checked at portal function that require authentication of same firebase project.
 * @param data Attributes to pass to the function
 * @returns Returned value from executed firebase function.  Date and number are transformed to Date and number of javascript.
 */
const functions = async (payloads: [string, any]) => {
  // eslint-disable-next-line prefer-destructuring
  const data = (
    await httpsCallable<
      string,
      string | string[] | number | number[] | KV | KV[]
    >(
      firebaseFunctions,
      "portal",
    )(JSON.stringify(payloads))
  ).data;
  // if (Array.isArray(data)) {
  //   data.forEach((datum, i) => {
  //     if (datum instanceof Object) {
  //       Object.keys(datum as KV).forEach(key => {
  //         const dateValue = isoStringToDate((datum as KV)[key])
  //         if (dateValue) (data[i] as KV)[key] = dateValue
  //         const numberValue = objectToNumber((datum as KV)[key])
  //         if (numberValue !== null) (data[i] as KV)[key] = numberValue
  //       })
  //     }
  //   })
  // } else if (data instanceof Object) {
  //   Object.keys(data).forEach(key => {
  //     const dateValue = isoStringToDate(data[key])
  //     if (dateValue) data[key] = dateValue
  //     const numberValue = objectToNumber(data[key])
  //     if (numberValue !== null) data[key] = numberValue
  //   })
  // }

  if (typeof data !== "string") return data;

  try {
    const parsedData = JSON.parse(
      data,
      (_key, value) =>
        isoStringToDate(value) ??
        objectParser(value) ??
        (value as string | string[] | number | number[] | KV | KV[]),
    ) as string | string[] | number | number[] | KV | KV[];

    return parsedData;
  } catch (e) {
    return data;
  }
};

/**
 * Send verification email using own SMTP.  Use confirmEmail in firebase function
 * @param user User to send verification
 * @returns Error message or null if succeed
 */
export const sendVerification = async (email?: string | null) => {
  try {
    if (!email) return "メールアドレスが正しく設定されていません。";
    const result = await functions(["confirmEmail", [email]]);
    if (result) throw new Error(result as string);

    return null;
  } catch (e) {
    return e instanceof Error ? e.message : "認証メールが送信できませんでした。";
  }
};

/**
 * Send password reset email using own SMTP.  Use resetPassword in firebase function
 * @param email Email to send password reset
 * @returns Error message or null if succeed
 */
export const sendPasswordReset = async (email?: string | null) => {
  try {
    if (!email) return "メールアドレスが正しく設定されていません。";
    const result = await functions(["resetPassword", [email]]);
    if (result) throw new Error(result as string);

    return null;
  } catch (e) {
    return (e instanceof Error ? e.message : e);
  }
};

export const FirebaseAppProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, setCurrentUser] = React.useState<User | null>();
  const [userObject, setUserObject] = React.useState<KV | null>(null);

  // Used in useUsa002DbActions.  Set error handling at useUsa002DbActions.  Create user in db here.
  const register = async (email: string, password: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      if (userCredential?.user) {
        await functions([
          "auth/createUser",
          [userCredential.user.uid, userCredential.user.email],
        ]);
        const error = await sendVerification(userCredential.user.email);

        return error;
      }

      return "アカウントの作成に失敗しました。";
    } catch (e) {
      return handleFirebaseError(e);
    }
  };

  // sendpasdswordresetemail before auth is in US-G001.  Need to use custom email sending
  // sendemailverification needs to do in server-side if not authenticated
  // updatePassword is called in US-G015

  // Called from US-A001 Login
  const logIn = async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    if (userCredential.user && !isEmailVefied(userCredential.user)) {
      throw new Error("メールアドレスが認証されていません。");
    }
    setCurrentUser(userCredential.user);
  };

  // Called from US-A022 Logout and several other logout handler
  const logOut = async () => {
    await auth.signOut();
    setCurrentUser(null);
    // セッションストレージクリア
    sessionStorage.clear();
  };

  // Called from useUsg017DbActions (Logged in status is required);
  const changeEmail = async (newEmail: string) => {
    if (getAuth().currentUser) {
      await updateEmail(getAuth().currentUser!, newEmail);
    }
  };

  // Usually not called.  Using flag to cancel user.
  const deleteUser = React.useCallback(
    async (userId: string) => {
      if (!currentUser)
        return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
      if (
        !window.confirm(
          "アカウントを削除します。この操作は取り消せません。よろしいですか？",
        )
      )
        await functions(["auth/deleteUser", [userId, currentUser.email]]); // Set cancelled flag to user db and delete firebase user by admin app
      // await auth.currentUser?.delete(); // Usually not required because above function handles it.  Session invalidate and logout are performed automatically

      return null;
    },
    [currentUser],
  );

  const customTokenSignIn = async (token: string) => {

    try {
      const result = await signInWithCustomToken(auth, token);
      setCurrentUser(result?.user || null);

      return result?.user || null
    } catch (e) {
      setCurrentUser(null);
    }

    return null;
  };


  // Called from US-A001/US-A002 Google or Line
  const providerSignIn = async (provider: string) => {
    const providerObj =
      provider === "google"
        ? new GoogleAuthProvider()
        : new OAuthProvider("oidc.line");
    if (provider === "google")
      providerObj.addScope("https://www.googleapis.com/auth/userinfo.email");

    try {
      const result = await signInWithPopup(auth, providerObj);
      // if (result.user.new) {
      //   await functions([
      //     "mongo/client",
      //     {
      //       collection: "users",
      //       insertOne: {
      //         doc: {
      //           _id: result.user?.uid,
      //           email: result.user?.email,
      //           [`${provider}_id`]: result.user?.providerData[0]?.uid,
      //         },
      //       },
      //     },
      //   ]);
      // }
      setCurrentUser(result?.user || null);

      return result?.user || null
    } catch (e) {
      setCurrentUser(null);
    }

    return null;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
      // Get user data from db and set it to userObject
      const func = async () => {
        if (user && !isEmailVefied(user)) {
          await auth.signOut();

          return
        }
        setCurrentUser(user);
        if (user) {
          if (!userObject || userObject._id !== user.uid) {
            // Mainly initial load, login or user change
            const userData = await functions([
              "mongo/client",
              { collection: "users", findOne: { filter: { _id: user.uid } } },
            ]);
            if (userData) {
              if (user.email && (userData as KV).email !== user.email) {
                // User change email and click verification when logged out
                await functions([
                  "mongo/client",
                  {
                    collection: "users",
                    updateOne: {
                      filter: { _id: user.uid },
                      update: { $set: { email: user.email } },
                    },
                  },
                ]);
                (userData as KV).email = user.email;
              }
              setUserObject(userData as KV);
            } else if (!user.providerData.find(v => v.providerId === 'password')) {
              // If SNS user, set SNS ID
              const pid = getProviderUidFromUser(user)
              const settingValue = pid.google ? { email: user.email, google_id: pid.google } : pid.line ? { line_id: pid.line } : { email: user.email };
              await functions([
                "mongo/client",
                {
                  collection: "users",
                  updateOne: { filter: { _id: user.uid }, update: { $set: settingValue, $setOnInsert: { _id: user.uid } }, options: { upsert: true } },
                },
              ]);
            }
          } else if (user.email !== userObject?.email) {
            // User change email and click verification when logged in
            await functions([
              "mongo/client",
              {
                collection: "users",
                updateOne: {
                  filter: { _id: user.uid },
                  update: { $set: { email: user.email } },
                },
              },
            ]);
            setUserObject({ ...userObject, email: user.email });
          }
        } else {
          setUserObject(null);
        }
      };
      func().catch((e: any) => {
        console.error(e);
      });
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get updated user data from db
  const refreshCustomData = async () => {
    if (currentUser) {
      const userData = await functions([
        "mongo/client",
        { collection: "users", findOne: { filter: { _id: currentUser.uid } } },
      ]);
      if (userData) {
        setUserObject(userData as KV);
      } else {
        setUserObject(null);
      }
    }
  };

  const memoizedFirebaseAppContext = React.useMemo(() => {
    const firebaseAppContext = {
      functions,
      currentUser,
      userObject,
      refreshCustomData,
      register,
      logIn,
      logOut,
      changeEmail,
      deleteUser,
      providerSignIn,
      customTokenSignIn
    };

    return firebaseAppContext;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, userObject]);

  return (
    <FirebaseAppContext.Provider value={memoizedFirebaseAppContext}>
      {children}
    </FirebaseAppContext.Provider>
  );
};
