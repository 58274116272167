import { useCallback, useState } from "react";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import {
  Collection,
  PatientMessageRelationshipStatusID,
} from "../../constants/common";

// メッセージテーブルのステータス更新
const useUpdateMessageStatus = () => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;

  const [actionError, setActionError] = useState<Error | null>(null);

  const updateStatus = useCallback(
    async (messageId: string) => {
      try {
        await functions([
          "mongo/client",
          {
            collection: Collection.PATIENT_MESSAGE_RELATIONSHIPS,
            updateOne: {
              filter: {
                _id: messageId,
                status: {
                  $ne: PatientMessageRelationshipStatusID.ALREADY_READ,
                },
              },
              update: {
                $set: {
                  status: PatientMessageRelationshipStatusID.ALREADY_READ,
                  updated_at: new Date(),
                },
              },
            },
          },
        ]);
      } catch (err) {
        setActionError(checkActionErr(err));
      }
    },
    [functions],
  );

  return {
    updateStatus,
    actionError,
  };
};

export default useUpdateMessageStatus;
