import { useState } from "react";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../base/useLoadingAction";

// 予約取消
const usePatientInterviewsActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;
  const [error, setError] = useState<Error | null>(null);

  // 予約取消API 失敗時にはfalseを返します。
  const updateInterviewsCancel = async (id: string | null | undefined) => {
    openUpdateActionLoading();
    try {
      // 予約取消API
      await functions(["interview/delete", [id]]);

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    error,
    updateInterviewsCancel,
  };
};

export default usePatientInterviewsActions;
