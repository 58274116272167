import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Input from "../components/Input/Input";
import Divider from "../components/Divider/Divider";
import SpButtonAccount from "../components/Button/SpButtonAccount";
import Link from "../components/Link/Link";
import PatientFooter from "../features/PatientFooter";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";
import useLogInInputObj, {
  handleLoginFailure,
  setId,
  setInitLoginInput,
  setPassword,
} from "../hooks/base/useLogInInputObj";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import Notification from "../components/Notification/Notification";
import SpButton from "../components/Button/SpButton";

const USA001 = () => {
  const navigate = useNavigateCustom();
  const firebaseAppContext = useFirebaseApp();
  const { logIn } = firebaseAppContext;
  const [logInInputObj] = useLogInInputObj();
  const {
    id,
    password,
    isErrorId,
    isErrorPassword,
    idValidationMessage,
    passwordValidationMessage,
    isLoginFailed,
    loginFailedMessage,
  } = logInInputObj;

  const handleChangeId = (value: string) => {
    setId(value, logInInputObj);
  };

  const handleChangePassword = (value: string) => {
    setPassword(value, logInInputObj);
  };

  // 新規会員登録ボタン
  const handleNavigateRegister = () => {
    navigate(PATIENT.USA002);
  };

  // パスワードを忘れた方はこちらボタン
  const handleNavigatePassword = () => {
    navigate(PATIENT.USG001);
  };

  // ログインボタン
  const handleEmailLogin = () => {
    void (async () => {
      addLoadCount();
      try {
        await logIn(id, password);
        setInitLoginInput();
      } catch (e) {
        handleLoginFailure(logInInputObj, e as Error);
      } finally {
        decrementLoadCount();
      }
    })();
  };

  // LINE
  const handleLine = () => {
    void (async () => {
      try {
        await firebaseAppContext.providerSignIn("line");
      } catch (e) {
        handleLoginFailure(logInInputObj, e as Error);
      }
    })();
  };

  // Google
  const handleGoogle = () => {
    void (async () => {
      try {
        await firebaseAppContext.providerSignIn("google");
      } catch (e) {
        handleLoginFailure(logInInputObj, e as Error);
      }
    })();
  };

  return (
    <div className="admin-area admin-area_no-header">
      <HeaderUser withRightControl={false} />
      <div className="admin-inner area-with-header-footer">
        <LayoutBox
          fullWidth
          justify="center"
          align="center"
          gap="3x"
          direction="column"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="ログイン"
            withButton={false}
            borderBottom="primary"
          />
          <Sheet padding="0">
            <LayoutBox
              direction="column"
              justify="center"
              align="center"
              maxWidth="440px"
              gap="2x"
            >
              <Sheet type="card" padding="24px 16px 32px 16px">
                <SpButton
                  width="311px"
                  size="medium"
                  onClick={handleNavigateRegister}
                >
                  新規会員登録
                </SpButton>
              </Sheet>
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" gap="3x" fullWidth>
                  <LayoutBox direction="column" gap="2x" maxWidth="311px">
                    {isLoginFailed && (
                      <Notification
                        text="ログインできません"
                        description={loginFailedMessage}
                      />
                    )}
                    <LayoutBox gap="1x" direction="column" fullWidth>
                      <Text size="xl">メールアドレス</Text>
                      <Input
                        width="311px"
                        inputMode="email"
                        autoComplete="email"
                        value={id}
                        onChange={handleChangeId}
                        error={isErrorId}
                      />
                      {[idValidationMessage].map((errorText, index) => (
                        <div
                          key={`caption-massage-${index}`}
                          className="sp-form-set__caption-massage--error"
                        >
                          {errorText}
                        </div>
                      ))}
                    </LayoutBox>
                    <LayoutBox gap="1x" direction="column" fullWidth>
                      <Text size="xl">パスワード</Text>
                      <Input
                        width="311px"
                        leftIcon="lock"
                        rightButton="password"
                        autoComplete="current-password"
                        password
                        value={password}
                        onChange={handleChangePassword}
                        error={isErrorPassword}
                      />
                      {[passwordValidationMessage].map((errorText, index) => (
                        <div
                          key={`caption-massage-${index}`}
                          className="sp-form-set__caption-massage--error"
                        >
                          {errorText}
                        </div>
                      ))}
                    </LayoutBox>
                    <SpButton
                      width="311px"
                      size="medium"
                      onClick={handleEmailLogin}
                    >
                      ログイン
                    </SpButton>
                    <SpButton
                      type="secondary"
                      width="311px"
                      size="medium"
                      fontSize="small"
                      onClick={handleNavigatePassword}
                    >
                      パスワードをお忘れの方はこちら
                    </SpButton>
                    <LayoutBox fullWidth justify="center">
                      <Link
                        size="large"
                        url={PATIENT.USG008}
                        external={false}
                        color="primary"
                      >
                        認証メールを再送する
                      </Link>
                    </LayoutBox>
                  </LayoutBox>
                  <LayoutBox fullWidth gap="1/2x" align="center">
                    <Divider darkColor orange={false} />
                    <Text size="large" noWrap>
                      または
                    </Text>
                    <Divider darkColor orange={false} />
                  </LayoutBox>
                  <LayoutBox
                    direction="column"
                    gap="2x"
                    align="center"
                    fullWidth
                  >
                    <Text size="xl" bold>
                      下記アカウントでログイン
                    </Text>
                    <SpButtonAccount
                      type="line"
                      width="100%"
                      onClick={handleLine}
                    >
                      LINEアカウント
                    </SpButtonAccount>
                    <SpButtonAccount
                      type="google"
                      width="100%"
                      onClick={handleGoogle}
                    >
                      Googleアカウント
                    </SpButtonAccount>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
          <div />
        </LayoutBox>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USA001;
