import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";
import { formatDate } from "../../utils/utils";

// patient_brain_checksテーブルに_idを元に1件取得
const useBrainCheckFetchById = (id: string) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [brainCheck, setBrainCheck] = useStateCustomObj({
    exam_start_date: "",
    note: "",
  });
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const result = (await functions([
          "mongo/client",
          {
            collection: Collection.PATIENT_BRAIN_CHECKS,
            findOne: {
              filter: {
                _id: id,
              },
            },
          },
        ])) as BrainCheckById;

        setBrainCheck({
          exam_start_date: result.exam_start_date
            ? formatDate(result.exam_start_date)
            : "",
          note: result.note || "",
        });
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, setBrainCheck, id, functions]);

  return { error, brainCheck };
};

export default useBrainCheckFetchById;
