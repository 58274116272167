import { useEffect, useRef, useState } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Link from "../components/Link/Link";

import Avatar from "../components/Avatar/Avatar";
import Text from "../components/Text/Text";
import Icon from "../components/Icon/Icon";
import Input from "../components/Input/Input";
import SpButton from "../components/Button/SpButton";
import ContentFooter from "../components/ContentFooter/ContentFooter";
import useUSD003DbActions from "../hooks/pages/USD003/useUSD003DbActions";
import { formatDateByISO } from "../utils/utils";
import { FORMAT_STYLE } from "../constants/common";
import { useCheckErrorThrowError } from "../utils/checkError";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import useGetSignedUrl from "../hooks/common/useGetSignedUrl";

/**
 *  ドクターまたはユーザーのチャットコンポーネントを取得します。
 */
const getCommentComponent = (
  chat: Partial<Chats>,
  index: number,
  onFileLinkClicked: (fileGcsPath: string) => void,
) => {
  const formatPostingTime = (date: Date | undefined) =>
    formatDateByISO(date, FORMAT_STYLE["YYYY/MM/DD HH:MM"]);

  const {
    created_at: createdAt,
    comment,
    commented_user: commentedUser,
    file_name: fileName,
    file_gcs_path: fileGcsPath = "",
  } = chat;

  const renderCommentWithLinks = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) =>
      urlRegex.test(part) ? (
        <Link key={index} color="primary" url={part} externalIcon={false}>
          {part}
        </Link>
      ) : (
        <Text key={index} whiteSpace="pre-line" width="100%">
          {part.trimStart()}
        </Text>
      ),
    );
  };

  if (commentedUser === "admin") {
    return (
      <LayoutBox gap="1/2x" fullWidth key={index}>
        <Avatar size="large" imageUrl="./assets/avatar-doctor.png" />
        <LayoutBox direction="column" gap="1/2x" fullWidth maxWidth="60%">
          <Text size="xs" width="100%">
            {formatPostingTime(createdAt)}
          </Text>
          <Sheet
            padding="16px"
            color="neutral-lightest"
            borderRadius="0px 16px 16px 16px"
            width="100%"
          >
            <LayoutBox direction="column" gap="1x" width="100%">
              {renderCommentWithLinks(comment || "")}
              {fileName && fileGcsPath && (
                <LayoutBox align="center" gap="1/2x">
                  <Icon icon="attach_file" size="xs" />
                  <div
                    onClick={() => {
                      onFileLinkClicked(fileGcsPath);
                    }}
                  >
                    <Text underline color="primary">
                      {fileName}
                    </Text>
                  </div>
                </LayoutBox>
              )}
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </LayoutBox>
    );
  }

  return (
    <LayoutBox justify="end" fullWidth key={index}>
      <LayoutBox direction="column" align="end" fullWidth maxWidth="60%">
        <Text size="xs">{formatPostingTime(createdAt)}</Text>
        <Sheet
          padding="16px"
          color="primary-lightest"
          borderRadius="16px 0px 16px 16px"
          width="100%"
          className="left-align-comment"
        >
          <LayoutBox direction="column" align="start" fullWidth>
            {renderCommentWithLinks(comment || "")}
          </LayoutBox>
        </Sheet>
      </LayoutBox>
    </LayoutBox>
  );
};

const USD003 = () => {
  const navigateWithBack = useNavigateWithBack(PATIENT.USD004);
  const [formValue, setFormValue] = useState("");
  const [shouldScroll, setShouldScroll] = useState(true);
  const scrollableArea = useRef<HTMLDivElement>(null);

  const { allChatData, addComment, fetchError, actionError } =
    useUSD003DbActions();

  const { getSignedUrl } = useGetSignedUrl();

  /**
   *  戻るボタン押下時に発火します。
   */
  const onBackBtnClicked = () => {
    navigateWithBack();
  };

  /**
   *  チャットのファイルリンク押下時に発火します。
   */
  const onFileLinkClicked = (fileGcsPath: string) => {
    void (async () => {
      const url = await getSignedUrl({ fileGcsPath });
      window.open(url, "_blank");
    })();
  };

  /**
   *  送信ボタン押下時に発火します。
   */
  const onSubmitBtnClicked = () => {
    void (async () => {
      // データ登録を待たずに、画面に一旦表示させる
      allChatData.push({
        comment: formValue,
        commented_user: "patient",
        created_at: new Date(),
      });

      await addComment({ comment: formValue });

      // フォームクリア
      setFormValue("");

      // 最新のチャットにスクロール
      setShouldScroll(true);
    })();
  };

  useEffect(() => {
    if (scrollableArea.current && shouldScroll && allChatData.length > 1) {
      // 初回チャット読み込み時 or shouldScrollがtrueの場合、最新のチャットにスクロール
      const scrollPosition = scrollableArea.current.scrollHeight;
      scrollableArea.current.scrollTop = scrollPosition;
      setShouldScroll(false);
    }
  }, [allChatData, shouldScroll]);

  // エラー処理
  useCheckErrorThrowError([fetchError, actionError]);

  return (
    <div className="admin-area user-body-sp-full-height" ref={scrollableArea}>
      <HeaderUser />
      <div className="admin-inner">
        <MenuNavigation selectedTab={SELECTED_TAB_NUM.healthAdvice} />
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="チャット相談"
          content="医師やコミュニケーターに直接相談・連絡ができます。各種検査に関するご連絡事項もこちらでご案内いたします。"
          borderBottom="primary"
          onClickBack={onBackBtnClicked}
        />
        <div className="util-mb-40" />

        <LayoutBox
          align="center"
          fullHeight
          fullWidth
          direction="column"
          gap="2x"
        >
          <div className="util-mb-16 util-px-16 util-full-width util-flex util-flex--justify-center">
            <Sheet
              withBorderRadius
              color="white"
              type="card"
              maxWidth="1064px"
              padding="24px 16px"
            >
              {allChatData.length ? (
                <LayoutBox direction="column" fullWidth gap="2x">
                  {allChatData.map((x, index) =>
                    getCommentComponent(x, index, onFileLinkClicked),
                  )}
                </LayoutBox>
              ) : (
                <Text
                  color="neutralUserLightest"
                  lineHeight="80px"
                  size="large"
                  bold
                >
                  表示する内容がありません
                </Text>
              )}
            </Sheet>
          </div>
        </LayoutBox>
        {!!allChatData.length && <div className="util-mb-24" />}
        <ContentFooter>
          <Sheet color="white" className="user-footer--padding-84" spPadding>
            <LayoutBox fullWidth direction="column" gap="2x" maxWidth="1064px">
              <LayoutBox fullWidth direction="column" gap="1x">
                <Text size="large" color="neutralLight" bold>
                  ご相談内容
                </Text>
                <Input
                  multiLine
                  width="100%"
                  value={formValue}
                  onChange={(text) => setFormValue(text)}
                />
              </LayoutBox>
              <SpButton
                buttonType="submit"
                width="100%"
                size="medium"
                withArrowRight
                onClick={onSubmitBtnClicked}
                disabled={!formValue}
              >
                送信
              </SpButton>
            </LayoutBox>
          </Sheet>
        </ContentFooter>
        <div />
      </div>
    </div>
  );
};
export default USD003;
