import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderUser from "../components/Header/HeaderUser";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import { isActiveUser, isWithinExaminationPeriod } from "../utils/utils";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Card from "../components/Card/Card";
import SpDropdown from "../components/Dropdown/SpDropdown";
import SpFormSet from "../components/FormSet/SpFormSet";
import useUsc021Conditions from "../hooks/pages/USC021/useUsc021Conditions";
import useUsc021Actions from "../hooks/pages/USC021/useUsc021DBActions";
import {
  convertUsc021Brain,
  convertUsc021Mri,
  convertUsc021NowKnow,
  convertUsc021Pet,
} from "../utils/convertDisplay";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  MriStatusID,
  PetStatusID,
  USC_021_TAB_ITEMS,
} from "../constants/common";
import usePath from "../hooks/common/usePath";

const EXAMINATION_TAB_ITEMS = [
  { value: "All", label: "すべて" },
  { value: "1", label: "オンライン脳の健康度チェック" },
  { value: "2", label: "電話認知機能評価" },
  { value: "3", label: "MRI検査＋AI解析" },
  { value: "4", label: "FDG-PET検査" },
];

const USC026 = () => {
  const [conditions] = useUsc021Conditions();
  const firebaseAppContext = useFirebaseApp();
  const navigate = useNavigateWithUrl();
  const isActive = isActiveUser(firebaseAppContext.userObject);
  const [selectedButton, setSelectedId] = React.useState<string | number>("2");
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const [testLists, setTestLists] = useState<USC021ListType[]>([]);
  const {
    error,
    selectOrderYear,
    listResult,
    brainListResult,
    mriListResult,
    petListResult,
    fetchError,
  } = useUsc021Actions({
    orderYear: searchCriteria.orderYear,
  });
  const [path] = usePath();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");

  // エラー処理
  useCheckErrorThrowError([error, fetchError]);

  // MRI,PETが受診可能か
  const isMriPetWithinExaminationPeriod = isWithinExaminationPeriod(
    firebaseAppContext.userObject,
  );

  // MRI検査のカードコンテンツ
  const renderMri = (
    result: USC002Type | null,
    navigate: (path: string) => void,
    isMriPetWithinExaminationPeriod: boolean,
  ) => {
    let onClick;
    let clickable;
    if (result) {
      switch (result.mri_status) {
        case MriStatusID.RESERVED:
        case MriStatusID.RESERVATION_CONFIRMED:
          onClick = () => {
            navigate(PATIENT.USC024);
          };
          clickable = false;
          break;
        case MriStatusID.DONE_IMAGE_NOT_LINKED:
        case MriStatusID.DONE_AI_DIAGNOSING:
        case MriStatusID.DONE_AI_DIAGNOSED:
        case MriStatusID.DONE_DOCTOR_DIAGNOSED:
          onClick = () => {
            navigate(PATIENT.USC024);
          };
          clickable = false;
          break;
        case MriStatusID.USER_REPORTED:
          onClick = () => {
            navigate(PATIENT.USC011);
          };
          clickable = true;
          break;
        case MriStatusID.NO_EXAMINATION:
          onClick = () => {
            navigate(PATIENT.USC024);
          };
          clickable = false;
          break;
        default:
          onClick = () => navigate(PATIENT.USC024);
          clickable = true;
          break;
      }
    } else {
      onClick = () => navigate(PATIENT.USC024);
      clickable = isMriPetWithinExaminationPeriod;
    }

    return { onClick, clickable };
  };

  // PET検査のカードコンテンツ
  const renderPet = (
    result: USC003Type | null,
    navigate: (path: string) => void,
    isMriPetWithinExaminationPeriod: boolean,
  ) => {
    let onClick;
    let clickable;
    if (result) {
      switch (result.pet_status) {
        case PetStatusID.RESERVED:
        case PetStatusID.RESERVATION_CONFIRMED:
          onClick = () => {
            navigate(PATIENT.USC025);
          };
          clickable = false;
          break;
        case PetStatusID.EXAMINED:
        case PetStatusID.DIAGNOSED:
          onClick = () => navigate(PATIENT.USC025);
          clickable = false;
          break;
        case PetStatusID.USER_REPORTED:
          onClick = () => {
            navigate(PATIENT.USC013);
          };
          clickable = true;
          break;
        default:
          onClick = () => navigate(PATIENT.USC025);
          clickable = true;
          break;
      }
    } else {
      onClick = () => navigate(PATIENT.USC012);
      clickable = isMriPetWithinExaminationPeriod;
    }

    return { onClick, clickable };
  };

  // 検査結果を整形
  const nowKnowLists = useMemo(
    () => listResult.map((result) => convertUsc021NowKnow(result, navigate)),
    [listResult, navigate],
  );
  const brainLists = useMemo(
    () => brainListResult.map((result) => convertUsc021Brain(result, navigate)),
    [brainListResult, navigate],
  );
  const mriLists = useMemo(
    () =>
      mriListResult.map((result) =>
        convertUsc021Mri(
          result,
          renderMri(result, navigate, isMriPetWithinExaminationPeriod),
        ),
      ),
    [isMriPetWithinExaminationPeriod, mriListResult, navigate],
  );
  const petLists = useMemo(
    () =>
      petListResult.map((result) =>
        convertUsc021Pet(
          result,
          renderPet(result, navigate, isMriPetWithinExaminationPeriod),
        ),
      ),
    [isMriPetWithinExaminationPeriod, navigate, petListResult],
  );

  useEffect(() => {
    let newTestLists: React.SetStateAction<USC021ListType[]> = [];
    if (searchCriteria.category === "") {
      const newCategory = category ?? "All";
      setSearchCriteria((prevState) => ({
        ...prevState,
        category: newCategory,
      }));
    }

    switch (searchCriteria.category) {
      case "All":
        newTestLists = [
          ...nowKnowLists,
          ...brainLists,
          ...mriLists,
          ...petLists,
        ];
        break;
      case "1":
        newTestLists = [...nowKnowLists];
        break;
      case "2":
        newTestLists = [...brainLists];
        break;
      case "3":
        newTestLists = [...mriLists];
        break;
      case "4":
        newTestLists = [...petLists];
        break;
      default:
        break;
    }

    // testListsの内容が変更される場合のみsetTestListsを呼び出す
    if (JSON.stringify(testLists) !== JSON.stringify(newTestLists)) {
      setTestLists(newTestLists);
    }
  }, [
    searchCriteria,
    brainLists,
    mriLists,
    nowKnowLists,
    petLists,
    testLists,
    category,
  ]);

  // Top画面に遷移
  const handleClickBack = useCallback(() => navigate(path), [navigate, path]);

  // タブの切り替え
  const handleTabChange = useCallback(
    (selected: string | number) => {
      setSelectedId(selected);
      if (selected === "1") navigate(PATIENT.USC021);
    },
    [navigate],
  );

  // 検査 カテゴリ
  const handleChangeExamination = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, category: value }));
  };

  // 注文日 年
  const handleChangeYear = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, orderYear: value }));
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
          isActiveUser={isActive}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="検査"
          content="各種検査の受検と結果の確認ができます"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <ToggleButton
                size="large"
                width="100%"
                items={USC_021_TAB_ITEMS.slice(0, 2).map((item, index) => ({
                  ...item,
                  disabled: index === 0 && !isActive,
                }))}
                onClick={handleTabChange}
                selectedButton={selectedButton}
              />
            </LayoutBox>
            <LayoutBox direction="column" gap="1x" fullWidth>
              <SpFormSet label="検査" vertical>
                <SpDropdown
                  items={EXAMINATION_TAB_ITEMS}
                  value={
                    searchCriteria.category ? searchCriteria.category : "All"
                  }
                  onChange={handleChangeExamination}
                  width="256px"
                />
              </SpFormSet>
              <SpFormSet label="受検した年で絞り込む" vertical>
                <SpDropdown
                  items={selectOrderYear}
                  value={
                    searchCriteria.orderYear
                      ? searchCriteria.orderYear
                      : "すべて"
                  }
                  onChange={handleChangeYear}
                  width="256px"
                />
              </SpFormSet>
              {testLists[0] ? (
                testLists.map((data, index) => (
                  <Card
                    onClick={data.onClick}
                    clickable={data.clickable}
                    status={data.status}
                    key={index}
                    header={
                      <Text bold size="xl">
                        {data.title}
                      </Text>
                    }
                    content={
                      data.finishedAt ? (
                        <Text size="large" color="neutralUserLight">
                          受検日：{data.finishedAt}
                        </Text>
                      ) : (
                        <></>
                      )
                    }
                  />
                ))
              ) : (
                <LayoutBox justify="center" align="center" fullWidth>
                  <Text size="large" color="neutralLight">
                    表示する結果がありません
                  </Text>
                </LayoutBox>
              )}
            </LayoutBox>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC026;
