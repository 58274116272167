import Card from "../components/Card/Card";
import Divider from "../components/Divider/Divider";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { MriStatusID } from "../constants/common";
import { PATIENT } from "../constants/pagePaths";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import usePatientMriFetch from "../hooks/common/usePatientMriFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  isWithinExaminationPeriod,
  formatDate,
  getNextExaminationStartDate,
  getUserYearSinceActivation,
} from "../utils/utils";
import BrainSuite from "../assets/brain_suite.png";

const MriCard = () => {
  const firebaseAppContext = useFirebaseApp();
  const navigate = useNavigateWithUrl();
  const { patientMri, error: mriGetError } = usePatientMriFetch();

  // エラー処理
  useCheckErrorThrowError([mriGetError]);

  // MRI,PETが受診可能か
  const isMriPetWithinExaminationPeriod = isWithinExaminationPeriod(
    firebaseAppContext.userObject,
  );

  // カード押下可能か
  const isMriClickable =
    (isMriPetWithinExaminationPeriod && patientMri.length === 0) ||
    (isMriPetWithinExaminationPeriod &&
      patientMri.length > 0 &&
      patientMri[0].mri_status !== MriStatusID.USER_REPORTED &&
      patientMri[0].mri_status !== MriStatusID.NO_EXAMINATION);
  // 何年目のユーザか
  const userYearSinceActivation = getUserYearSinceActivation(
    firebaseAppContext.userObject,
  );

  // MRI、PET次回開始日取得
  const nextExaminationStartDate = getNextExaminationStartDate(
    firebaseAppContext.userObject,
    userYearSinceActivation,
  );

  const renderContent = () => {
    if (patientMri.length) {
      const mriObj = patientMri[0];

      switch (mriObj.mri_status) {
        case MriStatusID.RESERVED:
          return (
            <Text size="large" color="neutralUserLight">
              予約調整中...
            </Text>
          );
        case MriStatusID.RESERVATION_CONFIRMED:
        case MriStatusID.DONE_IMAGE_NOT_LINKED:
        case MriStatusID.DONE_AI_DIAGNOSING:
        case MriStatusID.DONE_AI_DIAGNOSED:
        case MriStatusID.DONE_DOCTOR_DIAGNOSED:
          return (
            <>
              <Text size="large" color="neutralUserLight">
                {mriObj.fix_book_date || ""}
              </Text>
              <Text size="large" color="neutralUserLight">
                {mriObj.hospital_name}
              </Text>
            </>
          );
        case MriStatusID.NO_EXAMINATION:
          return (
            <>
              <Text size="large" color="neutralUserLight">
                {mriObj.fix_book_date || ""} (受検なし)
              </Text>
              <Text size="large" color="neutralUserLight">
                {mriObj.hospital_name}
              </Text>
            </>
          );
        case MriStatusID.USER_REPORTED:
          if (
            isMriPetWithinExaminationPeriod &&
            !isWithinExaminationPeriod(
              firebaseAppContext.userObject,
              mriObj.fix_book_date_start,
            )
          ) {
            return (
              <>
                <Text size="large" color="neutralUserLight">
                  年に1回受検してください。
                </Text>
                <Text size="large" color="neutralUserLight">
                  ※ご予約前に「のうKNOW®」とオンライン問診を受けてください。
                </Text>
              </>
            );
          }

          return (
            <>
              <Text size="large" color="neutralUserLight">
                MRI検査は年に1回受検できます。
              </Text>
              <Text size="large" color="neutralUserLight">
                次回:{formatDate(nextExaminationStartDate)}以降
              </Text>
            </>
          );

        default:
          return (
            <>
              <Text size="large" color="neutralUserLight">
                年に1回受検してください。
              </Text>
              <Text size="large" color="neutralUserLight">
                ※ご予約前に「のうKNOW®」とオンライン問診を受けてください。
              </Text>
            </>
          );
      }
    } else {
      return (
        <>
          <Text size="large" color="neutralUserLight">
            年に1回受検してください。
          </Text>
          <Text size="large" color="neutralUserLight">
            ※ご予約前に「のうKNOW®」とオンライン問診を受けてください。
          </Text>
        </>
      );
    }
  };

  const getOnClickHandler = () => {
    if (patientMri.length) {
      const mriObj = patientMri[0];
      switch (mriObj.mri_status) {
        case MriStatusID.RESERVED:
          return () => navigate(PATIENT.USC024);
        case MriStatusID.RESERVATION_CONFIRMED:
        case MriStatusID.DONE_IMAGE_NOT_LINKED:
        case MriStatusID.DONE_AI_DIAGNOSING:
        case MriStatusID.DONE_AI_DIAGNOSED:
        case MriStatusID.DONE_DOCTOR_DIAGNOSED:
        case MriStatusID.NO_EXAMINATION:
        case MriStatusID.USER_REPORTED:
          return () => navigate(PATIENT.USC024);
        default:
          return () => navigate(PATIENT.USC007);
      }
    } else {
      return () => navigate(PATIENT.USC024);
    }
  };

  return (
    <Card
      header={
        <>
          <LayoutBox
            gap="1/2x"
            justify="start"
            align="start"
            flexShrink={0}
            direction="row"
            minHeight="75px"
          >
            <img
              src={BrainSuite}
              alt="nouknow"
              className={`image--icon image--brain_suite ${
                !isMriClickable ? "image--icon--disabled" : ""
              }`}
            />
            <LayoutBox
              gap="1/2x"
              justify="center"
              align="start"
              flexShrink={0}
              direction="column"
            >
              <Text bold size="xl">
                BrainSuite®
              </Text>
              <Text size="large">MRI検査+AI解析</Text>
            </LayoutBox>
          </LayoutBox>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <LayoutBox direction="column" gap="1x">
          {renderContent()}
        </LayoutBox>
      }
      onClick={getOnClickHandler()}
      clickable={isMriClickable}
      disabled={!isMriClickable}
    />
  );
};
export default MriCard;
