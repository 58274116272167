import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { getYoseStatus } from "../../../utils/utils";

const USE004DbActions = (props: { id: string }) => {
  const { id } = props;
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;

  const [yoseDetail, setYoseDetail] = useState<yoseDetailData>();
  const [error, setError] = useState<Error | null>(null);

  // 寄席詳細取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const yoseDetail = (await functions([
          "getYoseDetail",
          id,
        ])) as yoseDetailData;

        const yoseDocument = yoseDetail.document;
        // ステータスを計算して設定する。
        const status = getYoseStatus({
          streamingDate: yoseDocument.streaming_date,
          streamingEndDate: yoseDocument.streaming_end_date,
          newPeriod: yoseDocument.new_period,
          isSuspend: yoseDocument.is_suspend,
        });
        yoseDocument.status = status;

        setYoseDetail(yoseDetail);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [functions, id]);

  return {
    yoseDetail,
    error,
  };
};

export default USE004DbActions;
