import { FirebaseError } from "firebase/app";

enum FirebaseAuthResultStatus {
  Successful,
  EmailAlreadyExists,
  WrongPassword,
  InvalidEmail,
  UserNotFound,
  UserDisabled,
  OperationNotAllowed,
  TooManyRequests,
  RequireRecentLogin,
  Undefined,
}

const getException = (e: FirebaseError ) => {
  switch (e.code) {
    case "auth/invalid-email":
      return FirebaseAuthResultStatus.InvalidEmail;
    case "auth/wrong-password":
      return FirebaseAuthResultStatus.WrongPassword;
    case "auth/user-not-found":
      return FirebaseAuthResultStatus.UserNotFound;
    case "auth/user-disabled":
      return FirebaseAuthResultStatus.UserDisabled;
    case "auth/too-many-requests":
      return FirebaseAuthResultStatus.TooManyRequests;
    case "auth/operation-not-allowed":
      return FirebaseAuthResultStatus.OperationNotAllowed;
    case "auth/email-already-in-use":
      return FirebaseAuthResultStatus.EmailAlreadyExists;
    case "auth/requires-recent-login":
      return FirebaseAuthResultStatus.RequireRecentLogin;
    default:
      return FirebaseAuthResultStatus.Undefined;
  }
};

export const getErrorMessage = (e: FirebaseError) => {
  switch (e.code) {
    case "auth/invalid-action-code":
      return "期限切れか、すでに使用されたリンクです。このままログインしてください。ログイン後にログイン保留中と表示された場合は認証メール再送ボタンをクリックし、再度メール認証してください。"; // added non-listed
    case "auth/invalid-email":
      return "メールアドレスが間違っています。";
    case "auth/wrong-password":
      return "パスワードが間違っています。";
    case "auth/user-not-found":
      return "このアカウントは存在しません。";
    case "auth/user-disabled":
      return "このメールアドレスは無効になっています。";
    case "auth/too-many-requests":
      return "回線が混雑しています。時間をおいてもう一度試してみてください。";
    case "auth/operation-not-allowed":
      return "メールアドレスとパスワードでのログインは有効になっていません。";
    case "auth/email-already-in-use":
      return "このメールアドレスはすでに登録されています。";
    case "auth/requires-recent-login":
      return "ログインからの経過時間が長すぎるためこの操作はできません。一旦ログアウトしてください。";
    default:
      return "";
  }
};

export const getAdminErrorMessage = (e: FirebaseError) => {
  switch (e.code) {
    case "auth/claims-too-large":
      return "クレームペイロードが、最大許容サイズを超えています。";
    case "auth/email-already-exists":
      return "提供されたメールアドレスはすでに既存のユーザーによって使用されています。";
    case "auth/id-token-expired":
      return "指定されたIDトークンは期限切れです。";
    case "auth/id-token-revoked":
      return "IDトークンが取り消されました。";
    case "auth/insufficient-permission":
      return "認証情報に、リソースにアクセスするための権限がありません。";
    case "auth/internal-error":
      return "リクエストの処理中に、認証サーバーで予期しないエラーが発生しました。";
    case "auth/invalid-action-code":
      return "無効なコードが指定されいます。"; // added non-listed
    case "auth/invalid-argument":
      return "無効な引数がメソッドに提供されました。";
    case "auth/invalid-claims":
      return "カスタムクレーム属性が無効です。";
    case "auth/invalid-continue-uri":
      return "続行URLは有効なURL文字列でなければなりません。";
    case "auth/invalid-creation-time":
      return "作成時刻は有効なUTC日付文字列でなければなりません。";
    case "auth/invalid-credential":
      return "認証に使用された認証情報は、目的のアクションの実行には使用できません。";
    case "auth/invalid-disabled-field":
      return "disabledユーザープロパティに指定された値は無効です。";
    case "auth/invalid-display-name":
      return "displayNameユーザープロパティに指定された値は無効です。空でない文字列を指定する必要があります。";
    case "auth/invalid-dynamic-link-domain":
      return "指定されたダイナミックリンクのドメインは、現在のプロジェクトに対して構成されていないか、承認されていません。";
    case "auth/invalid-email":
      return "emailユーザープロパティに指定された値は無効です。文字列のメールアドレスを指定する必要があります。";
    case "auth/invalid-email-verified":
      return "emailVerifiedユーザープロパティに指定された値は無効です。";
    case "auth/invalid-hash-algorithm":
      return "ハッシュアルゴリズムは、サポートされているアルゴリズムのリスト内の文字列の1つと一致している必要があります。";
    case "auth/invalid-hash-block-size":
      return "ハッシュのブロックサイズには有効な数値を指定してください。";
    case "auth/invalid-hash-derived-key-length":
      return "ハッシュ派生キーの長さには有効な数値を指定してください。";
    case "auth/invalid-hash-key":
      return "ハッシュキーは有効なバイトバッファでなければなりません。";
    case "auth/invalid-hash-memory-cost":
      return "ハッシュメモリのコストには有効な数値を指定してください。";
    case "auth/invalid-hash-parallelization":
      return "ハッシュ並列化には有効な数値を指定してください。";
    case "auth/invalid-hash-rounds":
      return "ハッシュラウンドには有効な数値を指定してください。";
    case "auth/invalid-hash-salt-separator":
      return "ハッシングアルゴリズムのsalt separatorフィールドは、有効なバイトバッファでなければなりません。";
    case "auth/invalid-id-token":
      return "指定されたIDトークンは有効なトークンではありません。";
    case "auth/invalid-last-sign-in-time":
      return "最終ログイン時間は、有効なUTC日付文字列でなければなりません。";
    case "auth/invalid-page-token":
      return "listUsers()に指定されたネクストページトークンが無効です。空ではない文字列を指定する必要があります。";
    case "auth/invalid-password":
      return "passwordに指定された値は無効です。6文字以上の文字列を指定する必要があります。";
    case "auth/invalid-password-hash":
      return "パスワードハッシュは有効なバイトバッファでなければなりません。";
    case "auth/invalid-password-salt":
      return "パスワードソルトは有効なバイトバッファでなければなりません。";
    case "auth/invalid-phone-number":
      return "電話番号に指定された値は無効です。空でないE.164標準準拠のID文字列を指定する必要があります。";
    case "auth/invalid-photo-url":
      return "photoURLに指定された値は無効です。文字列URLを指定する必要があります。";
    case "auth/invalid-provider-data":
      return "providerDataは、有効なUserInfoオブジェクトの配列でなければなりません。";
    case "auth/invalid-provider-id":
      return "providerIdは、サポートされている有効なプロバイダID文字列でなければなりません。";
    case "auth/invalid-oauth-responsetype":
      return "OAuth responseTypeを1つだけtrueに設定する必要があります。";
    case "auth/invalid-session-cookie-duration":
      return "セッションCookieの期間には、5分から2週間の間の有効な数値をミリ秒単位で指定してください。";
    case "auth/invalid-uid":
      return "uidは、128文字以下の空でない文字列を指定する必要があります。";
    case "auth/invalid-user-import":
      return "インポート対象のユーザーレコードが無効です。";
    case "auth/maximum-user-count-exceeded":
      return "インポートするユーザーの最大許容数を超えています。";
    case "auth/missing-android-pkg-name":
      return "Androidアプリをインストールする必要がある場合は、Androidパッケージ名を入力する必要があります。";
    case "auth/missing-continue-uri":
      return "リクエストで有効な続行URLを提供する必要があります。";
    case "auth/missing-hash-algorithm":
      return "パスワードハッシュを使用してユーザーをインポートするには、ハッシングアルゴリズムとそのパラメータを指定する必要があります。";
    case "auth/missing-ios-bundle-id":
      return "リクエストにバンドルIDが指定されていません。";
    case "auth/missing-uid":
      return "現在のオペレーションにはuid識別子が必要です。";
    case "auth/missing-oauth-client-secret":
      return "OIDCコードフローを有効にするには、OAuth構成クライアントシークレットが必要です。";
    case "auth/operation-not-allowed":
      return "提供されたログインプロバイダはプロジェクトで無効になっています。";
    case "auth/phone-number-already-exists":
      return "提供された電話番号はすでに既存のユーザーによって使用されています。";
    case "auth/project-not-found":
      return "認証情報に対応するプロジェクトが見つかりませんでした。";
    case "auth/reserved-claims":
      return "カスタムユーザークレームが予約済みです。";
    case "auth/session-cookie-expired":
      return "提供されたセッションのCookieは期限切れです。";
    case "auth/session-cookie-revoked":
      return "セッションCookieが取り消されました。";
    case "auth/uid-already-exists":
      return "提供されたuidはすでに既存のユーザーによって使用されています。";
    case "auth/unauthorized-continue-uri":
      return "続行URLのドメインがホワイトリストに登録されていません。";
    case "auth/user-not-found":
      return "提供されたIDに対応する既存のユーザーレコードはありません。";
    default:
      return "";
  }
};

// return appropriate error message
export const handleFirebaseError = (e: unknown) => {
  console.log(e);
  if (e instanceof Error) {
    if (e instanceof FirebaseError) {
      return (
        getErrorMessage(e) ||
        getAdminErrorMessage(e) ||
        "認証プロセスにおいて予期しないエラーが発生ました。"
      );
    }

      return e.message || "予期しない内部エラーが発生しました";

  }

  return "予期しない内部エラーが発生しました";

};



export const handleFirebaseException = (e: FirebaseError): string => {
  const result = getException(e);
  switch (result) {
    case FirebaseAuthResultStatus.InvalidEmail:
      return "メールアドレスが間違っています。";
    case FirebaseAuthResultStatus.WrongPassword:
      return "パスワードが間違っています。";
    case FirebaseAuthResultStatus.UserNotFound:
      return "このアカウントは存在しません。";
    case FirebaseAuthResultStatus.UserDisabled:
      return "このメールアドレスは無効になっています。";
    case FirebaseAuthResultStatus.TooManyRequests:
      return "回線が混雑しています。時間をおいてもう一度試してみてください。";
    case FirebaseAuthResultStatus.OperationNotAllowed:
      return "メールアドレスとパスワードでのログインは有効になっていません。";
    case FirebaseAuthResultStatus.EmailAlreadyExists:
      return "このメールアドレスはすでに登録されています。";
    case FirebaseAuthResultStatus.RequireRecentLogin:
      return "ログインからの経過時間が長すぎるためこの操作はできません。一旦ログアウトしてください。";
    default:
      return "認証プロセス内で予期せぬエラーが発生しました。";
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleException = (e: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (["InvalidPassword", "AuthError"].includes(e.errorCode as string)) {
    return "メールアドレスまたはパスワードが正しくありません";
  }
  if (e instanceof Error && e.message.includes("メールアドレス")) return e.message

  return "認証プロセス内で予期せぬエラーが発生しました。";
};

export default handleException;
