import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregatePatientInterview } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// PatientInterviewsテーブル検索
const usePatientInterviewsFetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [patientInterviews, setPatientInterviews] = useStateCustomObj<
    PatientInterviews[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregatePatientInterview(currentUser?.uid ?? "");
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_INTERVIEWS, aggregate },
        ])) as PatientInterviews[];

        setPatientInterviews(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, functions, setPatientInterviews]);

  return { error, patientInterviews };
};

export default usePatientInterviewsFetch;
