import { useCallback, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useItemInfoFetch from "../../common/useItemInfoFetch";
import usePatientInfoFetch from "../../common/usePatientInfoFetch";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useCartInfoFetch from "../../common/useCartInfoFetch";
import useForceUpdate from "../../common/useForceUpdate";

const useUsf005DBActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;
  const { itemInfo, error: itemInfoError } = useItemInfoFetch();
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const { cartInfo } = useCartInfoFetch();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [actionError, setActionError] = useState<Error | null>(null);

  const addCart = useCallback(
    async (quantity: number, skuId: string) => {
      openUpdateActionLoading();
      try {
        const transaction = cartInfo?.transactions_info.find(
          (transactionInfo) => transactionInfo.sku_id === skuId,
        );

        if (transaction) {
          await functions([
            "cartControlApi",
            {
              transaction_id: transaction?._id,
              quantity: quantity + transaction.quantity,
            },
          ]);
        } else {
          await functions([
            "cartControlApi",
            {
              patient_id: patientInfo?._id,
              sku_id: skuId,
              quantity,
            },
          ]);
        }

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });

        return true;
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }

      return false;
    },
    [
      cartInfo?.transactions_info,
      functions,
      forceUpdate.forceUpdateCount,
      patientInfo?._id,
      setForceUpdate,
    ],
  );

  return {
    itemInfo,
    patientInfo,
    itemInfoError,
    patientInfoError,
    actionError,
    addCart,
  };
};

export default useUsf005DBActions;
