import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";

// オンライン問診完了
const useUsc015DBActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;
  const location = useLocation();
  const isFirstRender = useRef(true);

  useEffect(() => {
    void (async () => {
      addLoadCount();
      try {
        if (isFirstRender.current) {
          // 初回のみ実行
          isFirstRender.current = false;

          const queryParams = new URLSearchParams(location.search);
          const nlPatientId = queryParams.get("nlPatientId") || "";
          const visitId = queryParams.get("visitId") || "";

          // オンライン問診完了API
          await functions(["mri/getOnlineTestResult", [nlPatientId, visitId]]);
        }
      } catch (e) {
        checkActionErr(e);
      } finally {
        decrementLoadCount();
      }
    })();
  }, [functions, location.search]);

  return <></>;
};

export default useUsc015DBActions;
