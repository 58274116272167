/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import ImageNextLogic from "../assets/image-next-logic.png";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  calculateTaxAmount,
  convertToCommaSeparatedString,
  formatDate,
  getSalesCategoryDisplay,
} from "../utils/utils";
import useSalesFetchById from "../hooks/common/useSalesFetchById";
import { FORMAT_STYLE, SALES_CATEGORY, UserTypeID } from "../constants/common";
import useUpdateReceiptData from "../hooks/common/useUpdateReceiptData";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { PATIENT } from "../constants/pagePaths";

const ReceiptDefault = () => {
  const { userObject } = useFirebaseApp();
  const { id } = useParams(); // id is provided when called from admin
  const { sale, nldata, error: fetchSalesError } = useSalesFetchById(id);
  const { updateReceiptData, actionError } = useUpdateReceiptData();
  const {
    patientInfo,
    loading: patientLoading,
    error: fetchPatientError,
  } = usePatientInfoFetch(
    id ? (sale?.patient_id as string) ?? null : undefined,
  );

  // エラー処理
  useCheckErrorThrowError([
    !id || (sale?.patient_id && !patientLoading) ? fetchPatientError : null,
    fetchSalesError,
    actionError,
  ]);

  // 発行日等登録
  useEffect(() => {
    const update = async () => { // called only when sale exist
      try {
        if (sale!.receiptIssueDate === undefined) {
          await updateReceiptData(sale!, `${patientInfo?.name_sei ?? ""} ${patientInfo?.name_mei ?? ""}`!, nldata!);
        }
      } catch (error) {
        const err = error as Error;
        throw new Error(err.message);
      }
    };
    if (sale && patientInfo && nldata && !id) {
      void update();
    }
  }, [updateReceiptData, sale, id, nldata, patientInfo]);

  // 合計消費税
  // const totalConsumptionTax =
  //   sale?.total_amount_per_tax?.reduce((sum, { tax }) => sum + tax, 0) || 0;

  if (id && userObject && userObject.user_type !== UserTypeID.ADMIN)
    return <Navigate to={PATIENT.NOT_FOUND} />; // Protect not to show other user's receipt

  return (
    <div className="receipt-page-container">
      <div className="receipt-page">
        <div className="receipt-page__header">
          <span>領収書</span>
          <span className="receipt-page__text--amount">{sale?.receiptIssueDate && sale.receiptIssueDate.getTime() + 10 * 1000 < new Date().getTime() ? "再発行" : ""}</span>
        </div>
        <div className="receipt-page__content">
          <LayoutBox gap="5x" fullWidth>
            <LayoutBox
              gap="3x"
              className="util-flex--grow-one"
              direction="column"
              fullWidth
            >
              <LayoutBox gap="1/2x" direction="column" fullWidth>
                <span className="receipt-page__text--title">
                  {sale?.metadata?.name ?? `${patientInfo?.name_sei ?? ""} ${patientInfo?.name_mei ?? ""}`} 様
                </span>
              </LayoutBox>
              <LayoutBox gap="1/2x" direction="column" fullWidth>
                <span className="receipt-page__text">
                  下記、正に領収いたしました。
                </span>
                <span className="receipt-page__card receipt-page__card--large">
                  <LayoutBox gap="1/2x" align="end" justify="between">
                    <span
                      className="receipt-page__text util-mb-3 util-font-bold"
                      style={{ fontSize: "8px" }}
                    >
                      合計金額
                    </span>

                    <LayoutBox gap="1/2x" align="end">
                      <span className="receipt-page__text--amount">
                        {sale?.amount
                          ? convertToCommaSeparatedString(sale?.amount)
                          : ""}
                      </span>
                      <span className="receipt-page__text util-mb-3">
                        円（税込）
                      </span>
                    </LayoutBox>
                  </LayoutBox>
                </span>
                <LayoutBox gap="none" fullWidth>
                  <div className="receipt-page__cell">
                    <span className="receipt-page__text util-font-bold">
                      決済日
                    </span>
                  </div>
                  <div className="receipt-page__cell receipt-page__cell--right util-flex--grow-one">
                    <span className="receipt-page__text">
                      {sale?.payment_date
                        ? formatDate(sale?.payment_date, FORMAT_STYLE.JPN_YMD)
                        : ""}
                    </span>
                  </div>
                </LayoutBox>
                <LayoutBox gap="none" fullWidth>
                  <div className="receipt-page__cell">
                    <span className="receipt-page__text util-font-bold">
                      決済方法
                    </span>
                  </div>
                  <div className="receipt-page__cell receipt-page__cell--right util-flex--grow-one">
                    <span className="receipt-page__text">
                      クレジットカード
                    </span>
                  </div>
                </LayoutBox>
              </LayoutBox>
            </LayoutBox>
            <LayoutBox direction="column" flexShrink={0} gap="1x">
              <LayoutBox gap="none" fullWidth>
                <div className="receipt-page__cell">
                  <span className="receipt-page__text util-font-bold">
                    発行日
                  </span>
                </div>
                <div className="receipt-page__cell receipt-page__cell--right util-flex--grow-one ">
                  <span className="receipt-page__text">
                    {sale?.receiptIssueDate
                      ? formatDate(sale?.receiptIssueDate, FORMAT_STYLE.JPN_YMD)
                      : formatDate(new Date(), FORMAT_STYLE.JPN_YMD)}
                  </span>
                </div>
              </LayoutBox>
              {SALES_CATEGORY.pet === sale?.category && (
                <>
                  <LayoutBox
                    className="util-px-8"
                    gap="1/2x"
                    direction="column"
                  >
                    <span className="receipt-page__text receipt-page__text--small util-font-bold">
                      発行元
                    </span>
                    <span className="receipt-page__text--title">
                      {sale?.metadata?.hospital_name ?? sale?.hospital_name ?? ""}
                    </span>
                    <LayoutBox direction="column" gap="none">
                      <span className="receipt-page__text">
                        {sale?.metadata?.prefecture ?? sale?.prefecture ?? ""} {sale?.metadata?.address ?? sale?.address ?? ""}
                      </span>
                    </LayoutBox>
                    <LayoutBox gap="1/2x">
                      <span className="receipt-page__text">登録番号：</span>
                      <span className="receipt-page__text">
                        {sale?.metadata?.invoice_num ?? sale?.invoice_num ?? ""}
                      </span>
                    </LayoutBox>
                  </LayoutBox>
                  <div className="receipt-page__divider" />
                </>
              )}

              <LayoutBox className="util-px-8" gap="1/2x" direction="column">
                <span className="receipt-page__text receipt-page__text--small util-font-bold">
                  {SALES_CATEGORY.pet === sale?.category
                    ? "代理発行元"
                    : "発行元"}
                </span>
                <img className="util-py-8" src={ImageNextLogic} alt="logo" />
                <span className="receipt-page__text--title">
                  {sale?.metadata?.nl_name ?? nldata?.nl_name as string ?? ""}
                </span>
                <LayoutBox direction="column" gap="none">
                  <span className="receipt-page__text">
                    {sale?.metadata?.nl_address ?? nldata?.nl_address as string ?? ""}
                  </span>
                </LayoutBox>
                <LayoutBox gap="1/2x">
                  <span className="receipt-page__text">登録番号：</span>
                  <span className="receipt-page__text">{sale?.metadata?.nl_invoice ?? nldata?.nl_invoice as string ?? ""}</span>
                </LayoutBox>
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
          <LayoutBox direction="column" gap="1/2x" fullWidth>
            <table className="receipt-page__table util-full-width">
              <thead>
                <tr>
                  <th>内容</th>
                  <th className="text-center" style={{ width: "52px" }}>
                    軽減税率
                  </th>
                  <th className="text-right" style={{ width: "48px" }}>
                    数量
                  </th>
                  <th className="text-center" style={{ width: "48px" }}>
                    単位
                  </th>
                  <th className="text-right" style={{ width: "80px" }}>
                    単価（税込）
                  </th>
                  <th className="text-right" style={{ width: "48px" }}>
                    税率
                  </th>
                  <th className="text-right" style={{ width: "80px" }}>
                    金額（税込）
                  </th>
                </tr>
              </thead>
              {SALES_CATEGORY.ec === sale?.category ? (
                <tbody>
                  {sale.transactions_info.map((transaction) => (
                    <tr key={transaction._id}>
                      <td>{`${transaction.item_name} ${transaction.sku_name}`}</td>
                      <td className="text-center">
                        {transaction.consumption_tax_rate === 8 ? "※" : ""}
                      </td>
                      <td className="text-right">{transaction.quantity}</td>
                      <td className="text-center">個</td>
                      <td className="text-right">
                        {transaction.total_amount
                          ? `${convertToCommaSeparatedString(
                            Math.floor(
                              transaction.total_amount / transaction.quantity,
                            ),
                          )}円`
                          : ""}
                      </td>
                      <td className="text-right">
                        {transaction.consumption_tax_rate}%
                      </td>
                      <td className="text-right">
                        {transaction.total_amount
                          ? `${convertToCommaSeparatedString(
                            transaction.total_amount,
                          )}円`
                          : ""}
                      </td>
                    </tr>
                  ))}
                  {Array.from(
                    { length: 9 - sale.transactions_info.length },
                    (_, index) => (
                      <tr key={index}>
                        <td colSpan={7} />
                      </tr>
                    ),
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>
                      {sale?.category !== undefined && sale?.category !== null
                        ? `${getSalesCategoryDisplay(sale?.category) ?? ""}${sale?.category === SALES_CATEGORY.subscription
                          ? ` (${sale?.payment_date.toLocaleDateString()}〜分)`
                            : sale?.category === SALES_CATEGORY.interview && sale?.interview_date
                              ? ` (${sale.interview_date.toLocaleDateString(undefined, { month: "numeric", day: "numeric" })}分)`
                            : sale?.category === SALES_CATEGORY.pet && sale?.exam_date
                                ? ` (${sale.exam_date.toLocaleDateString(undefined, { month: "numeric", day: "numeric" })}分)`
                              : ""}`
                        : ""}
                    </td>
                    <td className="text-center" />
                    <td className="text-right">1</td>
                    <td className="text-center">式</td>
                    <td className="text-right">
                      {sale?.amount
                        ? `${convertToCommaSeparatedString(sale.amount + (sale.amount_off ?? 0))}円`
                        : ""}
                    </td>
                    <td className="text-right">{sale?.tax_rate}%</td>
                    <td className="text-right">
                      {sale?.amount
                        ? `${convertToCommaSeparatedString(sale.amount + (sale.amount_off ?? 0))}円`
                        : ""}
                    </td>
                  </tr>
                  {sale?.campaign_code && <tr>
                    <td>
                      {`キャンペーン(${sale?.campaign_code})適用`}
                    </td>
                    <td className="text-center" />
                    <td className="text-right">1</td>
                    <td className="text-center">式</td>
                    <td className="text-right">
                      -{sale?.amount_off
                        ? `${convertToCommaSeparatedString(sale.amount_off)}円`
                        : ""}
                    </td>
                    <td className="text-right">{sale?.tax_rate}%</td>
                    <td className="text-right">
                      -{sale?.amount_off
                        ? `${convertToCommaSeparatedString(sale.amount_off)}円`
                        : ""}
                    </td>
                  </tr>}
                  {Array.from(
                    { length: SALES_CATEGORY.pet === sale?.category ? 5 : sale?.campaign_code ? 8 : 9 },
                    (_, index) => (
                      <tr key={index}>
                        <td colSpan={7} />
                      </tr>
                    ),
                  )}
                </tbody>
              )}
            </table>
            {SALES_CATEGORY.ec === sale?.category ? (
              <LayoutBox gap="2x" justify="between" fullWidth>
                <LayoutBox direction="column" gap="1x">
                  <span className="receipt-page__text receipt-page__text--small">
                    ※は軽減税率対象です。
                  </span>
                  <table className="receipt-page__table" width={319}>
                    <thead>
                      <tr>
                        <th className="text-right" style={{ width: "30%" }}>
                          税率区分
                        </th>
                        <th className="text-right" style={{ width: "30%" }}>
                          金額（税込）
                        </th>
                        <th className="text-right" style={{ width: "30%" }}>
                          消費税
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sale.total_amount_per_tax?.map((taxRate) => (
                        <tr key={taxRate.rate}>
                          <td className="text-right">{`${taxRate.rate}%対象`}</td>
                          <td className="text-right">
                            {`${convertToCommaSeparatedString(
                              taxRate.amount,
                            )}円`}
                          </td>
                          <td className="text-right">
                            {`${convertToCommaSeparatedString(taxRate.tax)}円`}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </LayoutBox>
              </LayoutBox>
            ) : (
              <LayoutBox gap="2x" justify="between" fullWidth>
                <LayoutBox direction="column" gap="1x">
                  <span className="receipt-page__text receipt-page__text--small">
                    ※は軽減税率対象です。
                  </span>
                  <table className="receipt-page__table" width={319}>
                    <thead>
                      <tr>
                        <th className="text-right" style={{ width: "30%" }}>
                          税率区分
                        </th>
                        <th className="text-right" style={{ width: "30%" }}>
                          金額（税込）
                        </th>
                        <th className="text-right" style={{ width: "30%" }}>
                          消費税
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-right">10%対象</td>
                        <td className="text-right">
                          {sale?.amount
                            ? `${convertToCommaSeparatedString(sale.amount)}円`
                            : ""}
                        </td>
                        <td className="text-right">
                          {sale?.amount
                            ? `${convertToCommaSeparatedString(
                              Math.floor(
                                calculateTaxAmount(
                                  sale.amount,
                                  sale?.tax_rate,
                                ),
                              ),
                            )}円`
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </LayoutBox>
              </LayoutBox>
            )}
          </LayoutBox>
          <div className="receipt-page__box">
            <div className="receipt-page__card">
              <span className="receipt-page__text util-font-bold">備考</span>
            </div>
            <div style={{ height: "50px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptDefault;
