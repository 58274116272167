import { useCallback, useEffect, useState } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Card from "../components/Card/Card";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import Title from "../components/Title/Title";

import ToggleButton from "../components/ToggleButton/ToggleButton";
import { PATIENT } from "../constants/pagePaths";
import useUsc002Actions from "../hooks/pages/USC002/useUsc002DBActions";
import {
  MriStatusID,
  PatientNouKnowStatusID,
  USC_TAB_ITEMS,
} from "../constants/common";
import usePatientNouknowsFetch from "../hooks/common/usePatientNouknowsFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import { setSelectMriId } from "../hooks/pages/USC011/useUsc011Conditions";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { isActiveUser } from "../utils/utils";

const USC002 = () => {
  const firebaseAppContext = useFirebaseApp();
  const [nouKnowUrl, setNouKnowUrl] = useState("");
  const [previousNouknowFinishedAt, setPreviousNouknowFinishedAt] =
    useState("");
  const { result, error } = useUsc002Actions();
  const { patientNouknows, error: nouknowGetError } = usePatientNouknowsFetch();
  const navigate = useNavigateWithUrl();

  // エラー処理
  useCheckErrorThrowError([error, nouknowGetError]);

  const isActive = isActiveUser(firebaseAppContext.userObject);

  // のうKNOWテスト
  useEffect(() => {
    if (!patientNouknows.length) {
      return;
    }

    const validDates = patientNouknows.filter((p) => p.finished_at_date);
    if (validDates.length > 0) {
      // 前回受検日取得
      const mostRecentPatient = patientNouknows.reduce(
        (mostRecent, current) => {
          const mostRecentDate = mostRecent.finished_at_date
            ? new Date(mostRecent.finished_at_date)
            : new Date(0);
          const currentDate = current.finished_at_date
            ? new Date(current.finished_at_date)
            : new Date(0);

          return mostRecentDate > currentDate ? mostRecent : current;
        },
      );
      setPreviousNouknowFinishedAt(mostRecentPatient.finished_at);
    }

    // 期間内でテスト未実施のデータが対象
    const enabledPatientNouknows = patientNouknows.filter((item) => {
      const now = new Date();

      return (
        item.status === PatientNouKnowStatusID.PRETEST &&
        now >= item.exam_start &&
        now <= item.exam_end
      );
    });

    if (enabledPatientNouknows.length === 0) {
      return;
    }

    setNouKnowUrl(enabledPatientNouknows[0].nouknow_url);
  }, [patientNouknows]);

  // タブの切り替え
  const handleTabChange = (index: string | number) => {
    const selectedTab = typeof index === "string" ? parseInt(index, 10) : index;
    switch (selectedTab) {
      case 1:
        navigate(PATIENT.USC001);
        break;
      case 2:
        navigate(PATIENT.USC002);
        break;
      case 3:
        navigate(PATIENT.USC003);
        break;
      case 4:
        navigate(PATIENT.USK002);
        break;
      default:
        break;
    }
  };

  // テスト結果画面に遷移
  const handleNavigateTest = (id: string) => {
    setSelectMriId(id);
    navigate(PATIENT.USC011);
  };

  // Top画面に遷移
  const handleClickBack = useCallback(
    () => navigate(PATIENT.USB001),
    [navigate],
  );

  // 文字列を渡す
  const iconStatus = (
    status: number,
  ): "pending" | "description" | "none" | "waiting" | "noExamination" => {
    const statusIcons: {
      [key: number]:
        | "pending"
        | "description"
        | "none"
        | "waiting"
        | "noExamination";
    } = {
      [MriStatusID.RESERVATION_CONFIRMED]: "waiting",
      [MriStatusID.DONE_IMAGE_NOT_LINKED]: "pending",
      [MriStatusID.DONE_AI_DIAGNOSING]: "pending",
      [MriStatusID.DONE_AI_DIAGNOSED]: "pending",
      [MriStatusID.DONE_DOCTOR_DIAGNOSED]: "pending",
      [MriStatusID.USER_REPORTED]: "description",
      [MriStatusID.NO_EXAMINATION]: "noExamination",
    };

    return statusIcons[status] || "none";
  };

  // テスト開始画面に遷移
  const handleNavigateTestStart = () => {
    window.open(nouKnowUrl, "_blank");
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
          isActiveUser={isActive}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="テスト・結果"
          content="認知機能テストや各種検査の結果を確認できます"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <Title
                type="h2"
                header="テスト"
                subheader={
                  previousNouknowFinishedAt
                    ? `前回受検日: ${previousNouknowFinishedAt}`
                    : ""
                }
                borderBottom="primaryLight"
                withButton={false}
              />
              <Card
                type="button"
                buttonItem={{
                  label: "テスト開始",
                  width: "311px",
                  disabled: nouKnowUrl === "" || !isActive,
                }}
                header={
                  <Text bold size="xl" color="neutralUserBlack">
                    のうKNOW
                  </Text>
                }
                content={
                  <Text size="large" color="neutralUserLight">
                    あなたの海馬状態を把握するためのテストです。
                  </Text>
                }
                onButtonItemClick={handleNavigateTestStart}
              />
            </LayoutBox>
            <LayoutBox direction="column" gap="3x" fullWidth>
              <Title
                type="h2"
                header="結果"
                borderBottom="primaryLight"
                withButton={false}
              />
              <div className="util-flex util-flex-col util-sp-flex--column util-flex--gap8 util-full-width">
                <ToggleButton
                  selectedButton="2"
                  size="large"
                  width="100%"
                  items={USC_TAB_ITEMS.slice(0, 2)}
                  onClick={handleTabChange}
                />
                <ToggleButton
                  selectedButton="2"
                  size="large"
                  width="100%"
                  items={USC_TAB_ITEMS.slice(2, 4)}
                  onClick={handleTabChange}
                />
              </div>
              <LayoutBox gap="1x" direction="column" fullWidth>
                {result[0] ? (
                  result.map((data, index) => (
                    <Card
                      onClick={() => handleNavigateTest(data._id)}
                      key={index}
                      status={iconStatus(data?.mri_status)}
                      clickable={data?.mri_status === MriStatusID.USER_REPORTED}
                      header={
                        <Text size="large" color="neutralUserLight">
                          受検日：{data?.fix_book_date_start}
                        </Text>
                      }
                      content={
                        <Text bold size="xl">
                          MRI検査
                        </Text>
                      }
                    />
                  ))
                ) : (
                  <LayoutBox justify="center" align="center" fullWidth>
                    <Text size="large" color="neutralLight">
                      表示する結果がありません
                    </Text>
                  </LayoutBox>
                )}
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC002;
