import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import { INVITATION, PATIENT } from "../constants/pagePaths";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { UserStatusID } from "../constants/common";

// 未設定パスの画面コンポーネント
const NotFound: React.FC = () => {
  const firebaseAppContext = useFirebaseApp();
  const navigate = useNavigateCustom();

  // ステータス
  const status = firebaseAppContext.userObject?.status as number|null|undefined;

  const nextPage = status
    ? status === UserStatusID.INVITATION
      ? INVITATION.USA029
      : PATIENT.USB001
    : PATIENT.USA001;

  const goToTopPage = () => navigate(nextPage);

  return (
    <div className="admin-area admin-area_no-header user-body-sp-full-height">
      <HeaderUser withRightControl={false} />
      <div className="admin-inner area-with-header-footer ">
        <Sheet
          padding="40px"
          className="util-py-40 util-sp-py-24"
          minWidth="1088px"
        >
          <LayoutBox direction="column" maxWidth="440px" width="440px">
            <Sheet
              type="card"
              padding="24px 16px 32px 16px"
              className="util-sp-pt-16"
            >
              <LayoutBox
                gap="3x"
                direction="column"
                align="center"
                fullWidth
                className="util-sp-flex--gap16"
              >
                <LayoutBox direction="column" gap="2x" align="center">
                  <Icon color="#D33A2F" icon="error" size="xl" />
                  <LayoutBox wrap justify="center">
                    <Text
                      bold
                      size="2xl"
                      color="neutralUserBlack"
                      lineHeight="150%"
                      textAlign="center"
                    >
                      ページが見つかりません。
                    </Text>
                  </LayoutBox>
                </LayoutBox>
                <SpButton
                  type="primary"
                  width="100%"
                  size="medium"
                  iconSize="large"
                  onClick={goToTopPage}
                >
                  トップへ
                </SpButton>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
    </div>
  );
};

export default NotFound;
