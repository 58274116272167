import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToNoDataPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateOneSetting } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useForceUpdate from "./useForceUpdate";

// Setting情報を取得
const useSettingInfoFetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [settingsInfo, setSettingInfo] = useState<SettingsType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const conditions = { _id: "standard" };
        const aggregate = getAggregateOneSetting(conditions);
        const searchSettingInfo = (await functions([
          "mongo/client",
          { collection: Collection.SETTINGS, aggregate },
        ])) as SettingsType[];

        if (searchSettingInfo.length < 1) redirectToNoDataPage();
        setSettingInfo(searchSettingInfo[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, functions]);

  return { error, settingsInfo };
};

export default useSettingInfoFetch;
