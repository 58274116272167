import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { getAggregateReceipts } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { Collection } from "../../../constants/common";

const useUsg012Fetch = (props: { skip?: number; perPage?: number }) => {
  const { perPage = 10, skip = 0 } = props;
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [receipts, setReceipts] = useState<ReceiptList[]>([]);
  const [count, setCount] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateReceipts({
          id: currentUser?.uid ?? "",
          skip,
          perPage,
        });

        const aggregateResult = (await functions([
          "mongo/client",
          { collection: Collection.SALES, aggregate },
        ])) as AggregateResultType<ReceiptList>;

        const { result, count } = aggregateResult[0];

        setCount(count[0]?.count ?? 0);
        setReceipts(result);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions, perPage, skip]);

  return {
    receipts,
    count,
    error,
  };
};

export default useUsg012Fetch;
