import { useState } from "react";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";

const useGetSignedUrl = () => {
  const [actionError, setActionError] = useState<Error | null>(null);
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;

  /**
   *  GCSのpathから著名付きのURLを返します。
   */
  const getSignedUrl = async (props: { fileGcsPath: string }) => {
    const { fileGcsPath } = props;
    addLoadCount();

    try {
      const signedUrl = (await functions([
        "getSignUrlApi",
        fileGcsPath,
      ])) as string;

      return signedUrl;
    } catch (err) {
      setActionError(checkActionErr(err));

      return undefined;
    } finally {
      decrementLoadCount();
    }
  };

  return {
    getSignedUrl,
    actionError,
  };
};

export default useGetSignedUrl;
