import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateInvitationUser } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

// 招待者の情報を取得
const useInvitationUserFetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [invitationUserInfo, setInvitationUserInfo] =
    useState<InvitationUser | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const id = currentUser?.uid;

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const conditions = { invited_patient_id: id };
        const aggregate = getAggregateInvitationUser(conditions);
        const result = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_INVITATIONS, aggregate },
        ])) as InvitationUser[];

        setInvitationUserInfo(result[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, functions, id]);

  return { error, invitationUserInfo };
};

export default useInvitationUserFetch;
