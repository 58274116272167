import { useCallback, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";

import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useOrderInfoFetch from "../../common/useOrderInfoFetch";
import { OrderStatusID } from "../../../constants/common";

const useUsf011DBActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;
  const { orderInfo, error } = useOrderInfoFetch(
    OrderStatusID.PAYMENT_COMPLETED,
  );
  const [actionError, setActionError] = useState<Error | null>(null);

  const orderCancel = useCallback(async () => {
    openUpdateActionLoading();
    if (orderInfo) {
      try {
        await functions(["stripe/ec/cancelPayment", orderInfo?._id]);
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    }

    return undefined;
  }, [functions, orderInfo]);

  return { orderInfo, orderCancel, actionError, error };
};

export default useUsf011DBActions;
