import { useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  closeActionLoading,
  openCreateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { useNavigateCustom } from "../../base/usePageTransitionCustom";
import { PATIENT } from "../../../constants/pagePaths";
import { setExistingEmailError } from "../../input/useCreateAccountInput";
import { ERROR_MESSAGES } from "../../../constants/messages";

const useUsa002DbActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigateCustom();

  // Eメールユーザ登録
  const handleEmailRegister = (
    email: string,
    password: string,
    isInvitation: boolean,
  ) => {
    void (async () => {
      openCreateActionLoading();
      try {
        // ファンクション
        const error = await firebaseAppContext.register(email, password);
        if (error) {
          throw new Error(error);
        }

        // 送信完了画面へ
        navigate(PATIENT.USA003);
      } catch (err) {
        console.log(err)
        // メール重複チェック
        // 未ログインであり、mongoDb接続できないため、エラーで判定
        const errorMessage = (err as Error).message;
        if (errorMessage.includes("このメールアドレスはすでに登録されています。") && !isInvitation) {
          setExistingEmailError(ERROR_MESSAGES.ALREADY_EMAIL_REGISTERED);

          return;
        }
        // 招待の場合、メッセージを分ける
        if (errorMessage.includes("このメールアドレスはすでに登録されています。") && isInvitation) {
          setExistingEmailError(
            ERROR_MESSAGES.ALREADY_INVITATION_EMAIL_REGISTERED,
          );

          return;
        }
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleEmailRegister,
  };
};

export default useUsa002DbActions;
