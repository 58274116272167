import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsaInvitationsUser } from "../../../utils/query";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import useUserRegisterInput from "../../input/useUserRegisterInput";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";

const genderObject = {
  male: "1",
  female: "2",
} as const;

const useUsa006DBActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [result, setResult] = useState<PatientInvitation>();
  const [error, setError] = useState<Error | null>(null);

  const [
    {
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      tel,
      zipcode,
      pref,
      city,
      town1,
      town2,
      enabledModality,
      pastDiagnosis,
    },
  ] = useUserRegisterInput();

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsaInvitationsUser(
          currentUser?.uid ?? "",
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_INVITATIONS, aggregate },
        ])) as PatientInvitation[];
        setResult(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions]);

  // 会員情報更新 失敗時にはfalseを返します。
  const updateUser = async () => {
    openUpdateActionLoading();
    try {
      // ユーザ情報更新API
      const params = {
        name_sei: nameSei.value,
        name_mei: nameMei.value,
        name_sei_kana: nameSeiKana.value,
        name_mei_kana: nameMeiKana.value,
        gender: gender.value === genderObject.male ? "male" : "female",
        birth_year: birthYear.value,
        birth_month: birthMonth.value,
        birth_day: birthDay.value,
        tel: tel.value,
        zipcode: zipcode.value,
        pref: pref.value,
        city: city.value,
        town1: town1.value,
        town2,
        is_enabled_modality: !enabledModality[0].checked,
        past_diagnosis: pastDiagnosis[0].checked,
      };
      await functions([
        "account/present/create_invited_user",
        [currentUser?.uid, params],
      ]);

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    result,
    error,
    updateUser,
  };
};

export default useUsa006DBActions;
