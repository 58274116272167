import { useState } from "react";

import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { INVITATION, PATIENT } from "../../constants/pagePaths";

type BackUrl = PATIENT.USB001 | INVITATION.USA031 | PATIENT.USG005;

const useStripeActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;

  const [stripeError, setStripeError] = useState<Error | null>(null);

  const fetchStripeCardUpdateLink = async (
    stripeSubscriptionId: string,
    backUrl: BackUrl,
  ) => {
    addLoadCount();
    try {
      const stripeUrl = (await functions([
        "stripe/subscription/updateCard",
        [
          stripeSubscriptionId,
          `${window.location.protocol}//${window.location.host}${backUrl}`,
        ],
      ])) as string;

      if (!stripeUrl) {
        const error = new Error("Stripe URL not received");
        setStripeError(checkActionErr(error));
      }

      return stripeUrl;
    } catch (err) {
      setStripeError(checkActionErr(err));

      return null;
    } finally {
      decrementLoadCount();
    }
  };

  return {
    fetchStripeCardUpdateLink,
    stripeError,
  };
};

export default useStripeActions;
