import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregatePatientPet } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// patient_modality_booksテーブルのPETを取得
const usePatientPetFetch = (yearsSinceActivation?: number | undefined) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [patientPet, setPatientPet] = useStateCustomObj<PatientPet[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregatePatientPet(
          currentUser?.uid ?? "",
          yearsSinceActivation,
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate },
        ])) as PatientPet[];

        setPatientPet(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, functions, setPatientPet, yearsSinceActivation]);

  return { error, patientPet };
};

export default usePatientPetFetch;
