import { useEffect, useState } from "react";
import { Collection } from "../../constants/common";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateShippings } from "../../utils/query";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";

// shippings取得
const useShippingsFetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [fetchShippingResult, setFetchShippingResult] =
    useState<ShippingsType | null>(null);
  const [fetchShippingError, setFetchShippingError] = useState<Error | null>(
    null,
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const aggregate = getAggregateShippings();
        const result = (await functions([
          "mongo/client",
          { collection: Collection.SHIPPINGS, aggregate },
        ])) as ShippingsType[];

        setFetchShippingResult(result[0]);
      } catch (err) {
        setFetchShippingError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, functions, setFetchShippingResult]);

  return { fetchShippingResult, fetchShippingError };
};

export default useShippingsFetch;
