import React from "react";
import { Navigate } from "react-router-dom";

import { useFirebaseApp } from "../contexts/FirebaseApp";
import { PATIENT } from "../constants/pagePaths";

// ログイン必須画面(ユーザの状態に関係なくアクセス)
const RequiredLoginElement: React.FC<{ element: JSX.Element }> = ({
  element,
}) => {
  const firebaseAppContext = useFirebaseApp();
  const isLoading = firebaseAppContext.currentUser === undefined; // No direct way to check loading status. So check currentUser is undefined or null
  const isLogin = Boolean(firebaseAppContext.currentUser);

  if (isLoading) return <></>;

  return isLogin ? element : <Navigate to={PATIENT.USA001} />;
};

export default RequiredLoginElement;
