import Card from "../components/Card/Card";
import Divider from "../components/Divider/Divider";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PetStatusID } from "../constants/common";
import { PATIENT } from "../constants/pagePaths";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
// import usePatientMriFetch from "../hooks/common/usePatientMriFetch";
import usePatientPetFetch from "../hooks/common/usePatientPetFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  getUserYearSinceActivation,
  // endOfPeriodForPet,
} from "../utils/utils";

const PetCard = () => {
  const firebaseAppContext = useFirebaseApp();
  const navigate = useNavigateWithUrl();

  // 何年目のユーザか
  const userYearSinceActivation = getUserYearSinceActivation(
    firebaseAppContext.userObject,
  );

  const { patientPet, error: petGetError } = usePatientPetFetch(
    userYearSinceActivation,
  );
  // const { patientMri, error: mriGetError } = usePatientMriFetch(
  //  userYearSinceActivation,
  // );

  // エラー処理
  useCheckErrorThrowError([petGetError]);

  // PET受検可能か
  const isPetPossible = () => true;

  const renderContent = () => {
    if (patientPet.length) {
      const petObj = patientPet[0];

      switch (petObj.pet_status) {
        case PetStatusID.RESERVED:
          return (
            <Text size="large" color="neutralUserLight">
              予約調整中...
            </Text>
          );
        case PetStatusID.RESERVATION_CONFIRMED:
        case PetStatusID.EXAMINED:
        case PetStatusID.DIAGNOSED:
          return (
            <>
              <Text size="large" color="neutralUserLight">
                {petObj.fix_book_date || ""}
              </Text>
              <Text size="large" color="neutralUserLight">
                {petObj.hospital_name}
              </Text>
            </>
          );
        default:
          return (
            <Text size="large" color="neutralUserLight">
              ご希望により別途料金で精密検査の受検が可能です。
            </Text>
          );
      }
    } else {
      return (
        <>
          <Text size="large" color="neutralUserLight">
            ご希望により別途料金で精密検査の受検が可能です。
          </Text>
        </>
      );
    }
  };

  const getOnClickHandler = () => {
    if (patientPet.length) {
      const petObj = patientPet[0];
      switch (petObj.pet_status) {
        case PetStatusID.RESERVED:
        case PetStatusID.RESERVATION_CONFIRMED:
        case PetStatusID.EXAMINED:
        case PetStatusID.DIAGNOSED:
        case PetStatusID.NO_EXAMINATION:
        case PetStatusID.USER_REPORTED:
          return () => navigate(PATIENT.USC025);
        default:
          return () => navigate(PATIENT.USC012);
      }
    } else {
      return () => navigate(PATIENT.USC025);
    }
  };

  return (
    <Card
      header={
        <>
          <Text bold size="xl">
            FDG-PET検査(別途料金)
          </Text>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <LayoutBox direction="column" gap="1x">
          {renderContent()}
        </LayoutBox>
      }
      onClick={getOnClickHandler()}
      clickable={isPetPossible()}
      disabled={!isPetPossible()}
      minHeight="mh-85px"
    />
  );
};
export default PetCard;
