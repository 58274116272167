import React from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import SpButton from "../components/Button/SpButton";
import Link from "../components/Link/Link";
import Divider from "../components/Divider/Divider";
import Icon from "../components/Icon/Icon";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import PatientFooter from "../features/PatientFooter";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import usePatientInterviewsFetch from "../hooks/common/usePatientInterviewsFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  CONSULTATION_PHONE_NUMBER_TOKYO,
  PatientInterviewStatusID,
} from "../constants/common";
import useInterviewTypeConditions from "../hooks/common/useInterviewTypeConditions";
import usePatientInterviewsActions from "../hooks/common/usePatientInterviewsActions";
import InformationDialog from "../components/Dialog/InformationDialog";

const USD002 = () => {
  const navigateWithBack = useNavigateWithBack(PATIENT.USD004);
  const { patientInterviews, error } = usePatientInterviewsFetch();
  console.log(patientInterviews);
  const { updateInterviewsCancel, error: cancelError } =
    usePatientInterviewsActions();
  const [interviewType] = useInterviewTypeConditions();
  const confirmedInterviews =
    patientInterviews?.filter((interview) => {
      const isConfirmedReservation =
        interview.status === PatientInterviewStatusID.CONFIRMED_RESERVATION;
      const isReservationUnsettled =
        interview.status === PatientInterviewStatusID.RESERVATION_UNSETTLED;
      if (interviewType === "0") {
        // interviewTypeが"0"の場合、invitation_ticket_idがあるデータ
        return isConfirmedReservation && interview.invitation_ticket_id != null;
      }
      if (interviewType === "1") {
        // interviewTypeが"1"の場合、invitation_ticket_idがないデータ
        return isConfirmedReservation && interview.invitation_ticket_id == null;
      }
      if (interviewType === "3") {
        // interviewTypeが"3"の場合、invitation_ticket_idがないデータ
        return isReservationUnsettled && interview.invitation_ticket_id == null;
      }

      return isConfirmedReservation;
    }) ?? [];

  // エラー処理
  useCheckErrorThrowError([error, cancelError]);

  // Zoomを開く
  const handleOpenZoom = () => {
    const zoomLink = confirmedInterviews[0]?.zoom_link;
    if (zoomLink) {
      window.open(zoomLink, "_blank");
    }
  };

  // Zoom testを開く
  const handleOpenZoomTest = () => {
    window.open("https://zoom.us/ja/test", "_blank");
  };

  const [isOpen, setIsOpen] = React.useState(false);
  // キャンセルダイアログclose
  const handleCloseCancelConfirm = () => {
    setIsOpen(false);
  };

  //  予約取消
  const handleCancelReservation = (id: string | null) => {
    void (async () => {
      const isSuccess = await updateInterviewsCancel(id);
      if (!isSuccess) return;
      navigateWithBack();
      window.location.reload(); // ヘッダーを無理やり更新
    })();
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.healthAdvice}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="オンライン面談"
          headerNote={
            interviewType === "0" ? "（MRI検査付帯）" : "（別途料金）"
          }
          content="オンライン会議システム（Zoom）を利用して医師面談ができます"
          borderBottom="primary"
          onClickBack={navigateWithBack}
        />
        <div className=" util-full-width util-py-40 util-sp-py-24">
          <Sheet padding="0" minWidth="1076px">
            <LayoutBox direction="column" gap="3x" width="1064px">
              <Sheet
                type="card"
                padding="16px 16px 24px 16px"
                className="util-sp-pt-16"
              >
                <LayoutBox gap="3x" direction="column" fullWidth>
                  <LayoutBox
                    direction="column"
                    gap="1x"
                    align="start"
                    fullWidth
                  >
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      面談
                    </Text>
                    <LayoutBox
                      justify="between"
                      align="center"
                      fullWidth
                      className="util-sp-flex--column"
                      gap="1x"
                    >
                      <LayoutBox
                        align="start"
                        fullWidth
                        gap="1x"
                        direction="column"
                      >
                        <Text size="xl" color="neutralLight" lineHeight="150%">
                          {confirmedInterviews.length > 0 && (
                            <>
                              {confirmedInterviews[0].interviewFixDateFromTo}
                              <br />
                              場所： オンライン
                            </>
                          )}
                        </Text>
                        <Text size="xl" lineHeight="150%">
                          面談時間外に「Zoomを開く」ボタンをクリックしないでください。
                        </Text>
                      </LayoutBox>
                      {confirmedInterviews.length > 0 && (
                        <SpButton
                          type="primary"
                          width="311px"
                          size="small"
                          mobileFullWidth
                          onClick={handleOpenZoom}
                        >
                          Zoomを開く
                        </SpButton>
                      )}
                    </LayoutBox>
                  </LayoutBox>
                  <Divider orange={false} darkColor margin="0" />
                  <LayoutBox
                    justify="between"
                    align="center"
                    fullWidth
                    className="util-sp-flex--column"
                    gap="1x"
                  >
                    <LayoutBox
                      align="start"
                      fullWidth
                      gap="1x"
                      direction="column"
                    >
                      <Text size="xl" lineHeight="150%">
                        オンライン面談の前にマイク・スピーカー・カメラの接続確認を行ってください。
                        <br />
                        いつでもご確認いただけます。
                      </Text>
                    </LayoutBox>
                    <SpButton
                      type="secondary"
                      width="311px"
                      size="small"
                      mobileFullWidth
                      onClick={handleOpenZoomTest}
                    >
                      接続テストを行う
                    </SpButton>
                  </LayoutBox>
                  <Divider orange={false} darkColor margin="0" />
                  <LayoutBox
                    gap="1x"
                    direction="column"
                    fullWidth
                    align="start"
                    className="util-sp-flex--align-center"
                  >
                    <Text size="large">
                      Zoomが開けないなど、お困りの際はチャット相談またはお電話にてお問い合わせください。
                    </Text>
                    <Text size="large">
                      受付時間：月曜～金曜 10:00～18:00（祝日・年末年始を除く）
                    </Text>
                    <LayoutBox gap="1x">
                      <Icon icon="phone" />
                      <Text size="xl" bold>
                        {CONSULTATION_PHONE_NUMBER_TOKYO}
                      </Text>
                    </LayoutBox>
                    <Divider orange={false} darkColor margin="0" />
                    {/* 予約の変更・キャンセルについて */}
                    <LayoutBox direction="column" gap="2x" fullWidth>
                      <Text size="xl" bold color="neutralUserBlack">
                        予約の変更・キャンセルについて
                      </Text>
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <LayoutBox direction="row" gap="1x">
                          <Link
                            size="xl"
                            color="primary"
                            url="https://dementia365.com/cancelpolicy/"
                          >
                            キャンセルポリシー
                          </Link>
                          <Text size="xl" color="neutralUserBlack">
                            をご確認ください。
                          </Text>
                        </LayoutBox>
                        <Divider darkColor margin="0" />
                      </LayoutBox>
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
        </div>
      </div>
      <PatientFooter />
      {isOpen && (
        <InformationDialog
          open={isOpen}
          closeDialog={handleCloseCancelConfirm}
          title="予約取消"
          isShowCloseButton={false}
          alertLevel="error"
          width="350px"
          footer={
            <>
              <LayoutBox justify="center" wrapReverse gap="2x">
                <SpButton
                  type="danger"
                  width="275px"
                  onClick={() =>
                    handleCancelReservation(confirmedInterviews[0]?._id)
                  }
                >
                  予約取消
                </SpButton>
                <SpButton
                  type="secondary"
                  width="275px"
                  onClick={handleCloseCancelConfirm}
                >
                  キャンセル
                </SpButton>
              </LayoutBox>
            </>
          }
        >
          <LayoutBox align="center">
            <LayoutBox
              direction="column"
              gap="none"
              align="start"
              justify="start"
            >
              <Text size="xl">
                オンライン面談を取り消しますがよろしいですか？
              </Text>
            </LayoutBox>
          </LayoutBox>
        </InformationDialog>
      )}
    </div>
  );
};

export default USD002;
