import { useState, useEffect } from "react";
import { Collection, PAYMENT_PERIOD } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getSubscriptionPlans } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

const useSubscriptionPlansFetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlansType<typeof PAYMENT_PERIOD>[]
  >([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  useEffect(() => {
    // プラン情報取得
    void (async () => {
      addLoadCount();
      try {
        const aggregate = getSubscriptionPlans();
        const result = (await functions([
          "mongo/client",
          { collection: Collection.SUBSCRIPTION_PLANS, aggregate },
        ])) as SubscriptionPlansType<typeof PAYMENT_PERIOD>[];
        setSubscriptionPlans(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    })();
  }, [currentUser, functions]);

  return { fetchError, subscriptionPlans };
};

export default useSubscriptionPlansFetch;
