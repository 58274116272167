import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsc001 } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

const useUsc001Actions = (selectedYear: string) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [result, setResult] = useState<USC001Type[]>([]);
  const [finishedYears, setFinishedYears] = useState([
    {
      value: "All",
      label: "すべて",
    },
  ]);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  // nouknow取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsc001(
          selectedYear,
          currentUser?.uid ?? "",
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_NOUKNOWS, aggregate },
        ])) as USC001Type[];

        // 受検した年を検索・セット
        const usc001AggregateYear = getAggregateUsc001(
          "All",
          currentUser?.uid ?? "",
        );
        const fetchResultYear = (await functions([
          "mongo/client",
          {
            collection: Collection.PATIENT_NOUKNOWS,
            aggregate: usc001AggregateYear,
          },
        ])) as USC001Type[];

        const uniqueYears = [
          ...new Set(
            fetchResultYear
              .filter((item) => item.finished_at)
              .map((item) => item.finished_at.split("/")[0]),
          ),
        ];
        setFinishedYears((prevState) => {
          const existingYears = new Set(prevState.map((item) => item.label));
          const newYears = uniqueYears.filter(
            (year) => !existingYears.has(year),
          );

          return [
            ...prevState,
            ...newYears.map((year) => ({
              value: String(year),
              label: year,
            })),
          ];
        });

        setResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, functions, selectedYear]);

  return {
    result,
    error,
    finishedYears,
  };
};

export default useUsc001Actions;
