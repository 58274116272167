import { useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useUserRegisterInput from "../../input/useUserInvitationInput";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";

const genderObject = {
  male: "1",
  female: "2",
} as const;

const useUsa026DBActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [error, setError] = useState<Error | null>(null);

  const [
    {
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      tel,
      // selectedNotice,
    },
  ] = useUserRegisterInput();

  // 会員情報更新 失敗時にはfalseを返します。
  const updateUser = async () => {
    openUpdateActionLoading();
    try {
      // FIXME: ユーザ情報更新API
      const params = {
        name_sei: nameSei.value,
        name_mei: nameMei.value,
        name_sei_kana: nameSeiKana.value,
        name_mei_kana: nameMeiKana.value,
        gender: gender.value === genderObject.male ? "male" : "female",
        birth_year: birthYear.value,
        birth_month: birthMonth.value,
        birth_day: birthDay.value,
        tel: tel.value,
        mail_notice: true,
      };
      await functions([
        "account/present/create_parent_user",
        [currentUser?.uid, params],
      ]);

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    error,
    updateUser,
  };
};

export default useUsa026DBActions;
