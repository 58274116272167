import React, { useCallback, useEffect, useState } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Card from "../components/Card/Card";
import Title from "../components/Title/Title";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import SpFormSet from "../components/FormSet/SpFormSet";
import SpDropdown from "../components/Dropdown/SpDropdown";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import {
  PatientBrainCheckStatusID,
  PatientNouKnowStatusID,
  USC_TAB_ITEMS,
} from "../constants/common";
import PatientFooter from "../features/PatientFooter";
import useUsk002DBFetch from "../hooks/pages/USK002/useUsk002DBFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useUsk002Conditions, {
  setSelectedResult,
} from "../hooks/pages/USK002/useUsk002Conditions";
import { setSelectBrainCheckId } from "../hooks/pages/USK003/useUsk003Conditions";
import usePatientNouknowsFetch from "../hooks/common/usePatientNouknowsFetch";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { isActiveUser } from "../utils/utils";

const DROPDOWN_FILTER = [
  { value: "All", label: "すべて" },
  { value: "1", label: "結果待ち" },
  { value: "2", label: "レポート受信済み" },
];

const USK002 = () => {
  const firebaseAppContext = useFirebaseApp();
  const [nouKnowUrl, setNouKnowUrl] = useState("");
  const [previousNouknowFinishedAt, setPreviousNouknowFinishedAt] =
    useState("");

  const [{ selectedResult }] = useUsk002Conditions();
  const { fetchResult, fetchError } = useUsk002DBFetch(selectedResult);
  const { patientNouknows, error: nouknowError } = usePatientNouknowsFetch();
  const navigate = useNavigateWithUrl();

  // エラー処理
  useCheckErrorThrowError([fetchError, nouknowError]);

  const isActive = isActiveUser(firebaseAppContext.userObject);

  // のうKNOWテスト
  useEffect(() => {
    if (!patientNouknows.length) {
      return;
    }

    const validDates = patientNouknows.filter((p) => p.finished_at_date);
    if (validDates.length > 0) {
      // 前回受検日取得
      const mostRecentPatient = patientNouknows.reduce(
        (mostRecent, current) => {
          const mostRecentDate = mostRecent.finished_at_date
            ? new Date(mostRecent.finished_at_date)
            : new Date(0);
          const currentDate = current.finished_at_date
            ? new Date(current.finished_at_date)
            : new Date(0);

          return mostRecentDate > currentDate ? mostRecent : current;
        },
      );
      setPreviousNouknowFinishedAt(mostRecentPatient.finished_at);
    }

    // 期間内でテスト未実施のデータが対象
    const enabledPatientNouknows = patientNouknows.filter((item) => {
      const now = new Date();

      return (
        item.status === PatientNouKnowStatusID.PRETEST &&
        now >= item.exam_start &&
        now <= item.exam_end
      );
    });

    if (enabledPatientNouknows.length === 0) {
      return;
    }

    setNouKnowUrl(enabledPatientNouknows[0].nouknow_url);
  }, [patientNouknows]);

  // セレクトボックス 結果で絞り込む
  const handleChangeFilter = (value: string) => {
    setSelectedResult(value);
  };

  // タブの切り替え
  const handleTabChange = (index: string | number) => {
    const selectedTab = typeof index === "string" ? parseInt(index, 10) : index;
    switch (selectedTab) {
      case 1:
        navigate(PATIENT.USC001);
        break;
      case 2:
        navigate(PATIENT.USC002);
        break;
      case 3:
        navigate(PATIENT.USC003);
        break;
      case 4:
        navigate(PATIENT.USK002);
        break;
      default:
        break;
    }
  };

  // Top画面に遷移
  const handleClickBack = useCallback(
    () => navigate(PATIENT.USB001),
    [navigate],
  );

  // テスト結果画面に遷移
  const handleNavigateResult = (id: string) => {
    setSelectBrainCheckId(id);
    navigate(PATIENT.USK003);
  };

  // テスト開始画面に遷移
  const handleNavigateTestStart = () => {
    window.open(nouKnowUrl, "_blank");
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
          isActiveUser={isActive}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="テスト・結果"
          content="認知機能テストや各種検査の結果を確認できます"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <Title
                type="h2"
                header="テスト"
                subheader={
                  previousNouknowFinishedAt
                    ? `前回受検日: ${previousNouknowFinishedAt}`
                    : ""
                }
                borderBottom="primaryLight"
                withButton={false}
              />
              <Card
                type="button"
                buttonItem={{
                  label: "テスト開始",
                  width: "311px",
                  disabled: nouKnowUrl === "" || !isActive,
                }}
                header={
                  <Text bold size="xl" color="neutralUserBlack">
                    のうKNOW
                  </Text>
                }
                content={
                  <Text size="large" color="neutralUserLight">
                    あなたの海馬状態を把握するためのテストです。
                  </Text>
                }
                onButtonItemClick={handleNavigateTestStart}
              />
            </LayoutBox>
            <LayoutBox direction="column" gap="3x" fullWidth>
              <Title
                type="h2"
                header="結果"
                borderBottom="primaryLight"
                withButton={false}
              />
              <div className="util-flex util-flex-col util-sp-flex--column util-flex--gap8 util-full-width">
                <ToggleButton
                  size="large"
                  width="100%"
                  selectedButton="4"
                  items={USC_TAB_ITEMS.slice(0, 2)}
                  onClick={handleTabChange}
                />
                <ToggleButton
                  size="large"
                  width="100%"
                  selectedButton="4"
                  items={USC_TAB_ITEMS.slice(2, 4)}
                  onClick={handleTabChange}
                />
              </div>
              <SpFormSet label="結果で絞り込む" vertical>
                <SpDropdown
                  value={selectedResult}
                  items={DROPDOWN_FILTER}
                  onChange={handleChangeFilter}
                  width="195px"
                />
              </SpFormSet>
              <LayoutBox gap="1x" direction="column" fullWidth>
                {fetchResult.length > 0 ? (
                  fetchResult.map((result) => (
                    <Card
                      key={result._id}
                      status={
                        result.status === PatientBrainCheckStatusID.COMPLETED
                          ? "pending"
                          : "description"
                      }
                      header={
                        <Text size="large" color="neutralUserLight">
                          受検案内日: {result.examStartDate}
                        </Text>
                      }
                      content={
                        <Text bold size="xl">
                          あたまの健康チェック
                        </Text>
                      }
                      clickable={
                        result.status === PatientBrainCheckStatusID.REPORTED
                      }
                      onClick={() => handleNavigateResult(result._id)}
                    />
                  ))
                ) : (
                  <LayoutBox justify="center" align="center" fullWidth>
                    <Text size="large" color="neutralLight">
                      表示する結果がありません
                    </Text>
                  </LayoutBox>
                )}
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USK002;
