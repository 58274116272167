import { useLocation, useNavigate } from "react-router-dom";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import PatientFooter from "../features/PatientFooter";
import { PATIENT } from "../constants/pagePaths";
import Icon from "../components/Icon/Icon";
import { checkActionErr } from "../contexts/CustomErrorBoundary";
import useEmailInput, {
  checkEmailInputError,
  setEmail,
} from "../hooks/input/useEmailInput";
import { sendVerification } from "../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";

type LocationState = {
  isFailed: boolean;
};

/* メール再送画面 */
const USG008 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isFailed = (location.state as LocationState)?.isFailed || false;
  const [{ email }] = useEmailInput();
  // 送信ボタン押下
  const handleSendEmail = async () => {
    addLoadCount();
    try {
      // ファンクションの実行
      const result = await sendVerification(email.value);
      if (result) throw new Error(result);
      // await sendEmailVerification(getAuth().currentUser!); // Firebase default email verification
      navigate(PATIENT.USG019);
    } catch (error) {
      const err = error as Error;
      checkActionErr(err);
    } finally {
        decrementLoadCount();
    }
  };

  // ログインへ戻るボタン
  const handleNavigateLogin = () => {
    navigate(PATIENT.USA001);
  };

  return (
    <div className="admin-area admin-area_no-header">
      <div className="admin-inner">
        <HeaderUser withRightControl={false} />
        <LayoutBox
          fullWidth
          justify="center"
          align="center"
          gap="5x"
          direction="column"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="メール再送"
            withButton={false}
            borderBottom="primary"
          />
          <Sheet padding="0">
            <LayoutBox
              direction="column"
              gap="3x"
              justify="center"
              align="center"
              maxWidth="440px"
            >
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox
                  direction="column"
                  justify="center"
                  align="center"
                  gap="2x"
                >
                  {isFailed && (
                    <>
                      <Icon icon="error" size="large" color="#A21B11" />
                      <Text size="base">
                        メールの有効期限が過ぎているか認証処理中にエラーが発生しました。
                      </Text>
                      <br />
                    </>
                  )}
                  <Text size="xl" color="neutralBlack" lineHeight="150%">
                    メールを再送します。
                    登録しているメールアドレスを入力し、「送信」を押してしてください。
                    再設定ページのURLをメールで送信します。
                  </Text>
                  <LayoutBox
                    direction="column"
                    justify="center"
                    align="center"
                    fullWidth
                  >
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      メールアドレス
                    </Text>
                    <Input
                      width="311px"
                      value={email.value}
                      onChange={(value) => setEmail(value)}
                      error={email.isError}
                    />
                    {[email.validationMessage].map((errorText, index) => (
                      <div
                        key={`caption-massage-${index}`}
                        className="sp-form-set__caption-massage--error"
                      >
                        {errorText}
                      </div>
                    ))}
                  </LayoutBox>
                  <Button
                    shape="circle"
                    width="311px"
                    size="medium"
                    underlined
                    onClick={() => {
                      void handleSendEmail();
                    }}
                    disabled={checkEmailInputError()}
                  >
                    送信
                  </Button>
                </LayoutBox>
              </Sheet>
              <Button
                shape="circle"
                type="secondary"
                width="343px"
                underlined
                icon="arrow_circle_left"
                iconPosition="far-left"
                onClick={handleNavigateLogin}
              >
                ログインへ戻る
              </Button>
            </LayoutBox>
          </Sheet>

          <div />
        </LayoutBox>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USG008;
