import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { getNewPatientMessageCount } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { Collection } from "../../../constants/common";
import useUsj002Dialog from "../../pages/USJ002/useUsj002Dialog";

const useHeaderActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [newNotificationCount, setNewNotificationCount] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);

  // メッセージ詳細を監視
  const [isMessageDetailDialog] = useUsj002Dialog();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getNewPatientMessageCount(currentUser?.uid ?? "");

        // 新着の個数
        const fetchNewNotificationResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_MESSAGE_RELATIONSHIPS, aggregate },
        ])) as mongoDbCountResult[];
        const fetchNewNotificationCount =
          fetchNewNotificationResult.length > 0
            ? fetchNewNotificationResult[0].count
            : 0;

        setNewNotificationCount(fetchNewNotificationCount);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions, isMessageDetailDialog]);

  return {
    newNotificationCount,
    error,
  };
};

export default useHeaderActions;
