import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregatePatientNouknow } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// PatientNouknowsテーブル検索
const usePatientNouknowsFetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [patientNouknows, setPatientNouknows] = useStateCustomObj<
    PatientNouknows[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  const id = currentUser?.uid;

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const aggregate = getAggregatePatientNouknow(currentUser?.uid ?? "");
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_NOUKNOWS, aggregate },
        ])) as PatientNouknows[];

        setPatientNouknows(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, functions, id, setPatientNouknows]);

  return { error, patientNouknows };
};

export default usePatientNouknowsFetch;
