import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateInvitations } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";
import { formatDate } from "../../utils/utils";

type SubscriptionInfo = {
  nextBillingDate: Date;
  price: number;
  interval: string;
};

// patient_invitationsテーブル検索
const usePatientInvitationsFetch = (limitCount: number | "all") => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [patientInvitations, setPatientInvitations] = useStateCustomObj<
    PatientInvitation[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateInvitations(
          limitCount,
          currentUser?.uid ?? "",
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_INVITATIONS, aggregate },
        ])) as PatientInvitation[];

        // 次回更新日取得のため
        const updatedFetchResult = await Promise.all(
          fetchResult.map(async (invitation) => {
            // ストライプ情報取得API
            if (!invitation.stripeSubscriptionId) {
              return invitation;
            }
            const stripeInfo = (await functions([
              "stripe/subscription/info",
              invitation.stripeSubscriptionId,
            ])) as SubscriptionInfo;

            const nextBillingDateStr = stripeInfo?.nextBillingDate
              ? formatDate(stripeInfo.nextBillingDate)
              : "";

            return {
              ...invitation,
              nextBillingDateStr,
            };
          }),
        );

        setPatientInvitations(updatedFetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, setPatientInvitations, limitCount, functions]);

  return { error, patientInvitations };
};

export default usePatientInvitationsFetch;
