import { useLocation, useNavigate } from "react-router-dom";
import { confirmPasswordReset, getAuth } from "firebase/auth";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Input from "../components/Input/Input";
import SpButton from "../components/Button/SpButton";
import PatientFooter from "../features/PatientFooter";
import usePasswordInput, {
  setPassword,
  setPasswordError,
  setRePassword,
} from "../hooks/input/usePasswordInput";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";
import { PATIENT } from "../constants/pagePaths";

type LocationState = {
  oobCode: string;
};

const USG003 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const oobCode = (location.state as LocationState)?.oobCode || "";
  // const tokenId = (location.state as LocationState)?.tokenId || "";
  const [{ password, rePassword }] = usePasswordInput();

  // パスワード再設定ボタン
  const handleSendPassword = async () => {
    if (password.value !== rePassword.value) {
      // パスワードバリデーション
      setPasswordError();

      return;
    }
    try {
      addLoadCount();
      // ファンクションの実行
      await confirmPasswordReset(getAuth(), oobCode, password.value);
      // 変更完了
      navigate(PATIENT.USG004);
    } catch (error) {
      // メール再送画面に遷移
      navigate(PATIENT.USG008, { state: { isFailed: true } });
    } finally {
      decrementLoadCount();
    }
  };

  return (
    <div className="admin-area">
      <HeaderUser withRightControl={false} />
      <div className="admin-inner area-with-header-footer">
        <LayoutBox
          fullWidth
          justify="center"
          align="center"
          gap="5x"
          direction="column"
          className="util-sp-flex--gap24"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="パスワードの再設定"
            withButton={false}
            borderBottom="primary"
          />
          <Sheet padding="0">
            <LayoutBox
              direction="column"
              justify="center"
              align="center"
              minWidth="440px"
              className="util-sp-full-width"
            >
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" gap="2x" fullWidth>
                  <Text size="xl" color="neutralBlack" lineHeight="150%">
                    新しいパスワード入力してください。
                  </Text>
                  <LayoutBox gap="1x" direction="column" fullWidth>
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      新しいパスワード
                    </Text>
                    <Input
                      width="100%"
                      leftIcon="lock"
                      rightButton="password"
                      password
                      autoComplete="new-password"
                      value={password.value}
                      onChange={(value) => setPassword(value)}
                      error={password.isError}
                    />
                    {[password.validationMessage].map((errorText, index) => (
                      <div
                        key={`caption-massage-${index}`}
                        className="sp-form-set__caption-massage--error"
                      >
                        {errorText}
                      </div>
                    ))}
                  </LayoutBox>
                  <LayoutBox gap="1x" direction="column" fullWidth>
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      新しいパスワード（確認用）
                    </Text>
                    <Input
                      width="100%"
                      leftIcon="lock"
                      rightButton="password"
                      password
                      autoComplete="new-password"
                      value={rePassword.value}
                      onChange={(value) => setRePassword(value)}
                      error={rePassword.isError}
                    />
                    {[rePassword.validationMessage].map((errorText, index) => (
                      <div
                        key={`caption-massage-${index}`}
                        className="sp-form-set__caption-massage--error"
                      >
                        {errorText}
                      </div>
                    ))}
                  </LayoutBox>
                  <SpButton
                    width="100%"
                    size="medium"
                    onClick={() => {
                      void handleSendPassword();
                    }}
                  >
                    パスワードを再設定する
                  </SpButton>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>

          <div />
        </LayoutBox>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USG003;
