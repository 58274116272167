import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import ChatBubble2 from "../assets/chat_bubbles.png";
import Notebook from "../assets/tabs-icons/notebook-icon.svg";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import Banner from "../components/Banner/Banner";
import {
  EmbeddedPageID,
  PATIENT_INVITATIONS_STATUS,
  PatientTypeID,
  UserStatusID,
} from "../constants/common";

import useGetCampaigns from "../hooks/common/useGetCampaigns";
import { useCheckErrorThrowError } from "../utils/checkError";
import { PATIENT } from "../constants/pagePaths";
import PatientFooter from "../features/PatientFooter";
import SectionOnlineVaudeville from "../components/SectionOnlineVaudeville/SectionOnlineVaudeville";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import { setPath } from "../hooks/common/usePath";
import ConsultationDialCard from "../features/ConsultationDialCard";
import ChatCard from "../features/ChatCard";
import MriCard from "../features/MriCard";
import PetCard from "../features/PetCard";
import FreeInterviewCard from "../features/FreeInterviewCard";
import PaidInterviewCard from "../features/PaidInterviewCard";
import NotificationCard from "../features/NotificationCard";
import PaymentFailureCard from "../features/PaymentFailureCard";
import { isActiveUser } from "../utils/utils";
import WithdrawnCard from "../features/WithdrawnCard";
import ServiceGiftSheet from "../features/ServiceGiftSheet";
// POC EC非表示
// import ShopSheet from "../features/ShopSheet";
import TopNouknowCard from "../features/TopNouknowCard";
import TopBrainCheckCard from "../features/TopBrainCheckCard";
import TopNouknowCardWithdrawal from "../features/TopNouknowCardWithdrawal";
import TopBrainCheckCardWithdrawal from "../features/TopBrainCheckCardWithdrawal";
import MriCardWithdrawal from "../features/MriCardWithdrawal";
import PetCardWithdrawal from "../features/PetCardWithdrawal";

const USB001 = () => {
  const firebaseAppContext = useFirebaseApp();
  const userStatus = firebaseAppContext.userObject?.status as number|null|undefined;
  const { campaigns, error: campaignsGetError } = useGetCampaigns({
    embeddedPage: EmbeddedPageID.MY_PAGE,
  });
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  // ページパス設定
  setPath(PATIENT.USB001);

  // エラー処理
  useCheckErrorThrowError([campaignsGetError, patientInfoError]);

  const isActive = isActiveUser(firebaseAppContext.userObject);

  return (
    <div className="admin-area">
      <HeaderUser backgroundColor="rough-yellow" />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.top}
          color="rough-yellow"
          isActiveUser={isActive}
        />
        <Sheet minWidth="1076px" padding="0" color="rough-yellow">
          <LayoutBox
            width="1064px"
            direction="column"
            gap="5x"
            className="util-py-32 util-sp-py-24 util-sp-flex--gap24"
          >
            {userStatus === UserStatusID.SUSPENDED &&
              patientInfo?.invitation_status !==
                PATIENT_INVITATIONS_STATUS.paymentError && (
                // カード決済失敗時のカード
                <PaymentFailureCard
                  stripeSubscriptionId={patientInfo?.stripe_subscription_id}
                  backUrl={PATIENT.USB001}
                />
              )}
            {/* 退会済カード */}
            {userStatus === UserStatusID.CANCELLATION && <WithdrawnCard />}

            {isActive && campaigns.top && (
              <Banner
                padding="0"
                background="none"
                url={campaigns.top.url}
                imagePc={campaigns.top.pc_image}
                imageSp={campaigns.top.sp_image}
              />
            )}
            <LayoutBox direction="column" gap="2x" fullWidth>
              <LayoutBox direction="column" gap="1x" fullWidth>
                <LayoutBox gap="1x" className="util-py-8">
                  <img src={Notebook} alt="notebook" />
                  <div className="util-mt-20">
                    <Text
                      size="2xl"
                      bold
                      yellowUnderline
                      lineHeight="50%"
                      titleFont
                    >
                      検査
                    </Text>
                  </div>
                </LayoutBox>
                {isActive ? (
                  <>
                    <Text size="xl">次のステップをご案内いたします</Text>
                    <Text size="large">
                      ※受検可能になると該当の検査がクリックできるようになります
                    </Text>
                    <LayoutBox gap="1x" align="stretch" fullWidth toColumn>
                      {/* のうKNOW */}
                      <TopNouknowCard />
                      {/* あたまの健康チェック */}
                      <TopBrainCheckCard />
                    </LayoutBox>
                  </>
                ) : (
                  <>
                    <Text size="xl">検査結果を確認できます</Text>
                    <LayoutBox gap="1x" align="stretch" fullWidth toColumn>
                      {/* のうKNOW 退会 */}
                      <TopNouknowCardWithdrawal />
                      {/* あたまの健康チェック 退会 */}
                      <TopBrainCheckCardWithdrawal />
                    </LayoutBox>
                  </>
                )}
                {isActive ? (
                  <LayoutBox gap="1x" align="stretch" fullWidth toColumn>
                    {/* MRI */}
                    <MriCard />
                    {/* PET */}
                    <PetCard />
                  </LayoutBox>
                ) : (
                  <LayoutBox gap="1x" align="stretch" fullWidth toColumn>
                    {/* MRI 退会 */}
                    <MriCardWithdrawal />
                    {/* PET 退会 */}
                    <PetCardWithdrawal />
                  </LayoutBox>
                )}
              </LayoutBox>
              {isActive && (
                <LayoutBox direction="column" gap="1x" fullWidth>
                  <LayoutBox gap="1x" className="util-py-8">
                    <img src={ChatBubble2} alt="megaphone" />
                    <div className="util-mt-20">
                      <Text
                        size="2xl"
                        bold
                        yellowUnderline
                        lineHeight="50%"
                        titleFont
                      >
                        健康相談
                      </Text>
                    </div>
                  </LayoutBox>
                  <Text size="xl">
                    認知症に関する各種相談メニューをご用意しています
                  </Text>
                  <LayoutBox gap="1x" align="stretch" fullWidth toColumn>
                    {/* オンライン面談 無料 */}
                    <FreeInterviewCard />
                    {/* オンライン面談 有料 */}
                    <PaidInterviewCard />
                  </LayoutBox>
                  <LayoutBox gap="1x" align="stretch" fullWidth toColumn>
                    {/* チャット */}
                    <ChatCard />
                    {/* 認知症相談ダイヤル24 */}
                    <ConsultationDialCard />
                  </LayoutBox>
                </LayoutBox>
              )}
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        {/* 余白 */}
        {isActive ? (
          <>
            <Sheet
              relative
              color="rough-yellow"
              padding="25px"
              minWidth="1076px"
            >
              <div />
            </Sheet>
            <Sheet
              relative
              color="rough-yellow-wavy"
              padding="25px"
              minWidth="1076px"
            >
              <div />
            </Sheet>
          </>
        ) : (
          <>
            <Sheet
              relative
              color="rough-yellow-wavy"
              padding="0px"
              minWidth="1076px"
            >
              <div />
            </Sheet>
          </>
        )}

        {/* オンライン寄席 */}
        {isActive && <SectionOnlineVaudeville />}
        {/* POC EC非表示 */}
        {/* ショップ */}
        {/* {isActive && <ShopSheet />} */}

        <div className="util-mb-32" />

        {/* サービスプレゼント */}
        {isActive &&
        patientInfo?.patient_type !== PatientTypeID.ORG &&
        patientInfo?.patient_type !== PatientTypeID.PRESENT ? (
          <ServiceGiftSheet />
        ) : null}

        <Sheet padding="56px 108px" minWidth="1076px">
          <LayoutBox
            width="1064px"
            direction="column"
            gap="7x"
            className="util-sp-flex--gap40"
          >
            {/* お知らせ */}
            <NotificationCard />
            {isActive && campaigns.bottom && (
              <Banner
                padding="0"
                background="none"
                url={campaigns.bottom.url}
                imagePc={campaigns.bottom.pc_image}
                imageSp={campaigns.bottom.sp_image}
              />
            )}
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USB001;
