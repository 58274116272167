import React, { useCallback, useEffect } from "react";
import HeaderUser from "../components/Header/HeaderUser";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import { isActiveUser } from "../utils/utils";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Card from "../components/Card/Card";
import { USC_021_TAB_ITEMS } from "../constants/common";
import { setPath } from "../hooks/common/usePath";

const USC021 = () => {
  const firebaseAppContext = useFirebaseApp();
  const navigate = useNavigateWithUrl();
  const isActive = isActiveUser(firebaseAppContext.userObject);
  const [selectedButton, setSelectedId] = React.useState<string | number>("1");

  useEffect(() => {
    // ページパス設定
    setPath(PATIENT.USC021);
  }, [navigate]);

  // Top画面に遷移
  const handleClickBack = useCallback(
    () => navigate(PATIENT.USB001),
    [navigate],
  );

  // のうKnow詳細に遷移
  const handleClickNowKnow = useCallback(
    () => navigate(PATIENT.USC022),
    [navigate],
  );

  // 電話認知機能評価詳細に遷移
  const handleClickTelTest = useCallback(
    () => navigate(PATIENT.USC023),
    [navigate],
  );

  // MRI詳細に遷移
  const handleClickMri = useCallback(
    () => navigate(PATIENT.USC024),
    [navigate],
  );

  // PET詳細に遷移
  const handleClickPet = useCallback(
    () => navigate(PATIENT.USC025),
    [navigate],
  );

  // タブの切り替え
  const handleTabChange = useCallback(
    (selected: string | number) => {
      setSelectedId(selected);
      if (selected === "2") navigate(PATIENT.USC026);
    },
    [navigate],
  );

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
          isActiveUser={isActive}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="検査"
          content="各種検査の受検と結果の確認ができます"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <ToggleButton
                size="large"
                width="100%"
                items={USC_021_TAB_ITEMS.slice(0, 2)}
                onClick={handleTabChange}
                selectedButton={selectedButton}
              />
            </LayoutBox>
            <LayoutBox direction="column" gap="1x" fullWidth>
              <Card
                onClick={handleClickNowKnow}
                header={
                  <Text bold size="xl">
                    のうKNOW®（オンライン脳の健康度チェック）
                  </Text>
                }
              />
              <Card
                onClick={handleClickTelTest}
                header={
                  <Text bold size="xl">
                    あたまの健康チェック®（電話認知機能評価）
                  </Text>
                }
              />
              <Card
                onClick={handleClickMri}
                header={
                  <Text bold size="xl">
                    BrainSuite®（MRI検査＋AI解析）
                  </Text>
                }
              />
              <Card
                onClick={handleClickPet}
                header={
                  <Text bold size="xl">
                    FDG-PET検査
                  </Text>
                }
              />
            </LayoutBox>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC021;
