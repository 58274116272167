import { useState, useEffect } from "react";
import { Collection } from "../../../constants/common";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateUsj001 } from "../../../utils/query";
import useStateCustomObj from "../../base/useStateCustomObj";
import useUsj002Dialog from "../USJ002/useUsj002Dialog";

const useUsj001Fetch = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [patientMessages, setPatientMessages] = useStateCustomObj<
    PatientMessage[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  const [isLoaded, setIsLoaded] = useState(false);

  // メッセージ詳細を監視
  const [isMessageDetailDialog] = useUsj002Dialog();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const aggregate = getAggregateUsj001(currentUser?.uid ?? "");
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_MESSAGE_RELATIONSHIPS, aggregate },
        ])) as PatientMessage[];

        setPatientMessages(fetchResult);
        setIsLoaded(true);
      } catch (err) {
        setError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, setPatientMessages, isMessageDetailDialog, functions]);

  return { error, patientMessages, isLoaded };
};

export default useUsj001Fetch;
