import { useState, useEffect } from "react";
import noneThumbnail from "../../assets/noneThumbnail.png";

const useGetThumbnail = (props: { vimeoId: string }) => {
  const { vimeoId } = props;
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://vimeo.com/api/v2/video/${vimeoId}.json`;
        const response = await fetch(url);
        const jsonData = ((await response.json()) as VimeoInfoType[])[0];
        setImgUrl(jsonData.thumbnail_large);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error fetching Vimeo video information:", err);
        setImgUrl(noneThumbnail);
      }
    };

    if (vimeoId) void fetchData();
  }, [vimeoId]);

  return { imgUrl };
};

export default useGetThumbnail;
