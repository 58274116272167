import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  nameSei: ValidationResultType<string>;
  nameMei: ValidationResultType<string>;
  nameSeiKana: ValidationResultType<string>;
  nameMeiKana: ValidationResultType<string>;
  tel: ValidationResultType<string>;
  zipcode: ValidationResultType<string>;
  city: ValidationResultType<string>;
  town1: ValidationResultType<string>;
  gender: ValidationResultType<string>;
  birthYear: ValidationResultType<string>;
  birthMonth: ValidationResultType<string>;
  birthDay: ValidationResultType<string>;
  pref: ValidationResultType<string>;
  email: ValidationResultType<string>;
  // selectedNotice: ValidationResultType<string>;
  selectedWithCode: ValidationResultType<string>;
  campaignCode: ValidationResultType<string>;
};
// バリデーションの不要な型定義
type DefaultType = {
  town2: string;
  enabledModality: {
    label: string;
    checked: boolean;
    value: string;
  }[];
  pastDiagnosis: {
    label: string;
    checked: boolean;
    value: string;
  }[];
};

export type UserRegisterInputType = DefaultValidationType & DefaultType;

export const INIT_STATE: UserRegisterInputType = {
  nameSei: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  nameMei: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  nameSeiKana: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  nameMeiKana: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  tel: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  zipcode: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  city: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  town1: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  gender: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  birthYear: {
    value: "1960",
    ...INIT_VALIDATION_RESULT,
  },
  birthMonth: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  birthDay: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  pref: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  email: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  // selectedNotice: {
  //   value: "",
  //   ...INIT_VALIDATION_RESULT,
  // },
  selectedWithCode: {
    value: "2",
    ...INIT_VALIDATION_RESULT,
  },
  campaignCode: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  town2: "",
  enabledModality: [
    {
      label: "当てはまらない",
      checked: false,
      value: "1",
    },
  ],
  pastDiagnosis: [
    {
      label: "認知症 もしくはMCI(軽度認知障害) と診断されたことがない",
      checked: false,
      value: "1",
    },
  ],
};

// 初期値
const localStateStr = sessionStorage.getItem("userRegisterInput");
const initialState = localStateStr
  ? (JSON.parse(localStateStr) as UserRegisterInputType)
  : INIT_STATE;

// 入力状態を管理
const stateReactiveVar = createReactiveVar<UserRegisterInputType>(initialState);
const useUserRegisterInput = (): ReactiveVarHooks<UserRegisterInputType> =>
  useReactiveVarHooks(stateReactiveVar);

// 値をセット セッションストレージにも保存することでリロードに対応
const setState = (value: UserRegisterInputType) => {
  sessionStorage.setItem("userRegisterInput", JSON.stringify(value));
  stateReactiveVar(value);
};

// 値を削除して初期化
export const resetUserRegisterInput = () => {
  sessionStorage.removeItem("userRegisterInput");
  stateReactiveVar(INIT_STATE);
};

// バリデーションチェックを行いながら値をセット
const _setUserEditValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setNameSei = (
  value: DefaultValidationType["nameSei"]["value"],
) => {
  _setUserEditValidation("nameSei", value, VALIDATION_TYPE.USER_NAME_REQUIRED);
};
export const setNameMei = (
  value: DefaultValidationType["nameMei"]["value"],
) => {
  _setUserEditValidation("nameMei", value, VALIDATION_TYPE.USER_NAME_REQUIRED);
};
export const setNameSeiKana = (
  value: DefaultValidationType["nameSeiKana"]["value"],
) => {
  _setUserEditValidation(
    "nameSeiKana",
    value,
    VALIDATION_TYPE.USER_NAME_KANA_REQUIRED,
  );
};
export const setNameMeiKana = (
  value: DefaultValidationType["nameMeiKana"]["value"],
) => {
  _setUserEditValidation(
    "nameMeiKana",
    value,
    VALIDATION_TYPE.USER_NAME_KANA_REQUIRED,
  );
};
export const setTel = (value: DefaultValidationType["tel"]["value"]) => {
  _setUserEditValidation("tel", value, VALIDATION_TYPE.TEL_REQUIRED);
};
export const setZipcode = (
  value: DefaultValidationType["zipcode"]["value"],
) => {
  _setUserEditValidation("zipcode", value, VALIDATION_TYPE.ZIPCODE_REQUIRED);
};
export const setCity = (value: DefaultValidationType["city"]["value"]) => {
  _setUserEditValidation("city", value, VALIDATION_TYPE.ADDRESS_REQUIRED);
};
export const setTown1 = (value: DefaultValidationType["town1"]["value"]) => {
  _setUserEditValidation("town1", value, VALIDATION_TYPE.ADDRESS_REQUIRED);
};
export const setGender = (value: DefaultValidationType["gender"]["value"]) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    gender: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setBirthYear = (
  value: DefaultValidationType["birthYear"]["value"],
) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    birthYear: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setBirthMonth = (
  value: DefaultValidationType["birthMonth"]["value"],
) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    birthMonth: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setBirthDay = (
  value: DefaultValidationType["birthDay"]["value"],
) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    birthDay: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setPref = (value: DefaultValidationType["pref"]["value"]) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    pref: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setTown2 = (value: DefaultType["town2"]) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    town2: value,
  });
};

export const setEnabledModality = (value: DefaultType["enabledModality"]) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    enabledModality: value,
  });
};
export const setPastDiagnosis = (value: DefaultType["pastDiagnosis"]) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    pastDiagnosis: value,
  });
};

// 一旦非表示
// export const setSelectedNotice = (
//   value: DefaultValidationType["selectedNotice"]["value"],
// ) => {
//   const currentInput = stateReactiveVar();
//   setState({
//     ...currentInput,
//     selectedNotice: {
//       value,
//       isError: false,
//       validationMessage: "",
//     },
//   });
// };

export const setSelectedWithCode = (
  value: DefaultValidationType["selectedWithCode"]["value"],
) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    selectedWithCode: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};

export const setEmail = (value: DefaultValidationType["email"]["value"]) => {
  _setUserEditValidation("email", value, VALIDATION_TYPE.EMAIL_REQUIRED);
};

export const setCampaignCode = (
  value: DefaultValidationType["campaignCode"]["value"],
) => {
  _setUserEditValidation("campaignCode", value, VALIDATION_TYPE.CAMPAIGN_CODE);
};

// 値をすべて更新
export const setUserRegisterInput = ({
  nameSei,
  nameMei,
  nameSeiKana,
  nameMeiKana,
  tel,
  zipcode,
  pref,
  city,
  town1,
  town2,
  birthYear,
  birthMonth,
  birthDay,
  gender,
  enabledModality,
  pastDiagnosis,
  // selectedNotice,
  selectedWithCode,
  email,
  campaignCode,
}: {
  nameSei: DefaultValidationType["nameSei"]["value"];
  nameMei: DefaultValidationType["nameMei"]["value"];
  nameSeiKana: DefaultValidationType["nameSeiKana"]["value"];
  nameMeiKana: DefaultValidationType["nameMeiKana"]["value"];
  gender: DefaultValidationType["gender"]["value"];
  birthYear: DefaultValidationType["birthYear"]["value"];
  birthMonth: DefaultValidationType["birthMonth"]["value"];
  birthDay: DefaultValidationType["birthDay"]["value"];
  tel: DefaultValidationType["tel"]["value"];
  zipcode: DefaultValidationType["zipcode"]["value"];
  pref: DefaultValidationType["pref"]["value"];
  city: DefaultValidationType["city"]["value"];
  town1: DefaultValidationType["town1"]["value"];
  // selectedNotice: DefaultValidationType["selectedNotice"]["value"];
  selectedWithCode: DefaultValidationType["selectedWithCode"]["value"];
  town2: DefaultType["town2"];
  enabledModality: DefaultType["enabledModality"];
  pastDiagnosis: DefaultType["pastDiagnosis"];
  email: DefaultValidationType["email"]["value"];
  campaignCode: DefaultValidationType["campaignCode"]["value"];
}) => {
  setNameSei(nameSei);
  setNameMei(nameMei);
  setNameSeiKana(nameSeiKana);
  setNameMeiKana(nameMeiKana);
  setTel(tel);
  setZipcode(zipcode);
  setCity(city);
  setTown1(town1);
  setPref(pref);
  setTown2(town2);
  setBirthYear(birthYear);
  setBirthMonth(birthMonth);
  setBirthDay(birthDay);
  setGender(gender);
  setEnabledModality(enabledModality);
  setPastDiagnosis(pastDiagnosis);
  // setSelectedNotice(selectedNotice);
  setSelectedWithCode(selectedWithCode);
  setEmail(email);
  setCampaignCode(campaignCode);
};

// 初期化
export const setInitUserEditInput = () => {
  setState(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkUserRegisterInputError = (patientType = false): boolean => {
  const currentInfos = stateReactiveVar();
  const {
    town2: _town2,
    enabledModality,
    pastDiagnosis,
    ...validationInfos
  } = currentInfos;
  const { campaignCode: _campaignCode, ...restValidationInfos } =
    validationInfos;
  const validationValues = Object.values(restValidationInfos);
  const {
    city: _city,
    town1: _town1,
    zipcode: _zipcode,
    pref: _pref,
    ...presentValidationInfos
  } = restValidationInfos;
  const validationPresentValues = Object.values(presentValidationInfos);

  // 会員登録が可能なユーザーかチェック
  if (!enabledModality[0].checked) return true;
  if (!pastDiagnosis[0].checked) return true;

  if (patientType) {
    const isEmpty = validationPresentValues.some(({ value }) => value === "");
    if (isEmpty) return true;

    // バリデーションチェック
    const isValidationError = validationPresentValues.some(
      ({ isError }) => isError,
    );
    if (isValidationError) return true;
  } else {
    // 必須チェック
    const isEmpty = validationValues.some(({ value }) => value === "");
    if (isEmpty) return true;

    // バリデーションチェック
    const isValidationError = validationValues.some(({ isError }) => isError);
    if (isValidationError) return true;

    // キャンペーンコードチェック
    if (currentInfos.selectedWithCode.value === "1") {
      const isCampaign = currentInfos.campaignCode.value === "";
      if (isCampaign) return true;

      const campaignError = currentInfos.campaignCode.isError;
      if (campaignError) return true;
    }
  }

  return false;
};

export const setZipCodeError = (
  value: DefaultValidationType["zipcode"]["value"],
  validationMessage: string,
) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    zipcode: {
      value,
      isError: true,
      validationMessage,
    },
  });
};

// 同一Emailチェック
export const setExistingEmailError = () => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    email: {
      ...currentInput.email,
      validationMessage:
        "すでに登録されているメールアドレスです。別のメールアドレスを入力してください。",
    },
  });
};

// キャンペーンチェック
export const setCampaignError = () => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    campaignCode: {
      ...currentInput.campaignCode,
      isError: true,
      validationMessage: "キャンペーンコードが存在しません。",
    },
  });
};

export const setCampaignLimitError = () => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    campaignCode: {
      ...currentInput.campaignCode,
      isError: true,
      validationMessage: "申込人数が上限に達したため使用できません。",
    },
  });
};

export const setCampaignCancelError = () => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    campaignCode: {
      ...currentInput.campaignCode,
      isError: true,
      validationMessage: "キャンペーンコードの利用期限を過ぎています。",
    },
  });
};

export default useUserRegisterInput;
