import { useEffect, useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  Collection,
  PATIENT_INVITATIONS_STATUS,
} from "../../../constants/common";
import {
  getAggregateUsa004,
  getAggregateUsaInvitationsUser,
  getCampaignCoupons,
  getCampaignTerm,
} from "../../../utils/query";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import useUserRegisterInput, {
  setCity,
  setPref,
  setTown1,
  setZipCodeError,
} from "../../input/useUserRegisterInput";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";

const genderObject = {
  male: "1",
  female: "2",
} as const;

const useUsa004DBActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [result, setResult] = useState<USA004Type>();
  const [error, setError] = useState<Error | null>(null);
  const [invitation, setInvitation] = useState<PatientInvitation>();
  const [campaignError, setCampaignError] = useState<Error | null>(null);

  const [
    {
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      tel,
      zipcode,
      pref,
      city,
      town1,
      town2,
      enabledModality,
      campaignCode,
      // selectedWithCode, //phase2以降
    },
  ] = useUserRegisterInput();

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsa004(currentUser?.uid ?? "");
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.USERS, aggregate },
        ])) as USA004Type[];

        setResult(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions]);

  // Invitation取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsaInvitationsUser(
          currentUser?.uid ?? "",
          [PATIENT_INVITATIONS_STATUS.notRegistered],
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_INVITATIONS, aggregate },
        ])) as PatientInvitation[];
        setInvitation(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions]);

  // 住所検索
  const fetchAddress = () => {
    void (async () => {
      addLoadCount();
      try {
        const sanitizedZipCode = zipcode.value.replace(/-/g, "");
        if (sanitizedZipCode.length === 7) {
          const response = await fetch(
            `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${sanitizedZipCode}`,
          );
          const data = (await response.json()) as ZipCloudResponse;
          if (data.status === 200 && data.results) {
            setPref(data.results[0].address1);
            setCity(data.results[0].address2);
            setTown1(data.results[0].address3);
          } else {
            setZipCodeError(zipcode.value, "郵便番号が見つかりません。");
          }
        } else {
          setZipCodeError(
            zipcode.value,
            "郵便番号は7桁の数字を入力してください。",
          );
        }
      } catch (err) {
        setZipCodeError(
          zipcode.value,
          "郵便番号検査時にエラーが発生しました。",
        );
      } finally {
        decrementLoadCount();
      }
    })();
  };

  // 会員情報更新 失敗時にはfalseを返します。
  const updateUser = async () => {
    openUpdateActionLoading();
    try {
      // FIXME: ユーザ情報更新API
      const params = {
        _id: currentUser?.uid,
        // payment_type: 1, // 会員ユーザー phase2以降 動的に変更
        gender: gender.value === genderObject.male ? "male" : "female",
        birth_year: birthYear.value,
        birth_month: birthMonth.value,
        birth_day: birthDay.value,
        is_enabled_modality: !enabledModality[0].checked, // trueで当てはまる
        is_notification: true,
        tel: tel.value,
        zipcode: zipcode.value,
        pref: pref.value,
        city: city.value,
        town1: town1.value,
        town2,
        name_sei: nameSei.value,
        name_mei: nameMei.value,
        name_sei_kana: nameSeiKana.value,
        name_mei_kana: nameMeiKana.value,
        campaign_code: campaignCode.value,
      };
      await functions(["updatePatientApi", params]);

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  // キャンペーンコード取得
  const fetchCampaign = async () => {
    addLoadCount();
    try {
      const aggregate = getCampaignCoupons(campaignCode.value);
      const fetchResult = (await functions([
        "mongo/client",
        { collection: Collection.CAMPAIGN_COUPONS, aggregate },
      ])) as USA024Type[];

      return fetchResult[0] || null;
    } catch (err) {
      setCampaignError(checkFetchErr(err));

      return null;
    } finally {
      decrementLoadCount();
    }
  };

  // キャンペーン利用人数取得
  const fetchCampaignTerm = async (id: string | number) => {
    addLoadCount();
    try {
      const aggregate = getCampaignTerm(id);
      const fetchResult = (await functions([
        "mongo/client",
        { collection: Collection.PATIENTS, aggregate },
      ])) as USA024TermType[];

      return fetchResult || null;
    } catch (err) {
      setCampaignError(checkFetchErr(err));

      return null;
    } finally {
      decrementLoadCount();
    }
  };

  return {
    result,
    invitation,
    error,
    fetchAddress,
    updateUser,
    fetchCampaign,
    campaignError,
    fetchCampaignTerm,
  };
};

export default useUsa004DBActions;
