import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsa028 } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

const useUsa028Actions = (emailInputs: Array<USA027Type>) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [result, setResult] = useState<USA028Type[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsa028(currentUser?.uid ?? "");
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_INVITATIONS, aggregate },
        ])) as USA028Type[];
        const matchingResults = fetchResult.filter((result) =>
          emailInputs.some((input) => input.email === result.email),
        );
        setResult(matchingResults);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, emailInputs, forceUpdate.forceUpdateCount, functions]);

  return {
    result,
    error,
  };
};

export default useUsa028Actions;
