import { useEffect, useState } from "react";
import Card from "../components/Card/Card";
import Divider from "../components/Divider/Divider";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import Nouknow from "../assets/nouknow.png";
import useNouknowEligibility from "../hooks/common/useNouknowEligibility";
import usePatientNouknowsFetch from "../hooks/common/usePatientNouknowsFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { isDateWithinRange } from "../utils/utils";
import { PatientNouKnowStatusID } from "../constants/common";

const TopNouknowCard = () => {
  const navigate = useNavigateWithUrl();
  const [isNouknowsStatus, setIsNouknowsStatus] = useState(0);
  const { isNouknowsEligible } = useNouknowEligibility();
  const { patientNouknows, error: nouknowError } = usePatientNouknowsFetch();

  useCheckErrorThrowError([nouknowError]);

  useEffect(() => {
    // 期間内でテスト未実施のデータが対象
    const enabledPatientNouknows = patientNouknows.filter((item) =>
      isDateWithinRange(item.exam_start, item.exam_end, new Date()),
    );
    setIsNouknowsStatus(enabledPatientNouknows[0]?.status);
  }, [patientNouknows]);

  return (
    <Card
      header={
        <>
          <LayoutBox
            gap="1/2x"
            justify="start"
            align="start"
            flexShrink={0}
            direction="row"
            minHeight="65px"
          >
            <img
              src={Nouknow}
              alt="nouknow"
              className={`image--icon image--nouknow ${
                !isNouknowsEligible ? "image--icon--disabled" : ""
              }`}
            />
            <LayoutBox
              gap="1/2x"
              justify="center"
              align="start"
              flexShrink={0}
              direction="column"
            >
              <Text bold size="xl">
                のうKNOW®
              </Text>
              <Text size="large">オンライン脳の健康度チェック</Text>
            </LayoutBox>
          </LayoutBox>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <Text size="large" color="neutralUserLight">
          3か月ごとに受検してください。
        </Text>
      }
      onClick={() => navigate(PATIENT.USC022)}
      clickable={
        isNouknowsEligible &&
        isNouknowsStatus !== PatientNouKnowStatusID.COMMENTED
      }
      disabled={
        !isNouknowsEligible ||
        isNouknowsStatus === PatientNouKnowStatusID.COMMENTED
      }
    />
  );
};
export default TopNouknowCard;
