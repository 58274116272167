import { useEffect, useMemo } from "react";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import List from "../components/List/List";
import Icon from "../components/Icon/Icon";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import usePagination, {
  setTotal,
} from "../hooks/component/pagination/usePagination";
import useUsg012Fetch from "../hooks/pages/USG012/useUsg012Fetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  convertToCommaSeparatedString,
  getSalesCategoryDisplay,
} from "../utils/utils";
import SpPaginationContainer from "../components/Pagination/SpPaginationContainer";
import { setReceiptId } from "../hooks/common/useReceiptId";

const USG012 = () => {
  const [pagination] = usePagination();

  const { skip } = pagination;
  const {
    receipts,
    count,
    error: receiptsGetError,
  } = useUsg012Fetch({
    skip,
  });

  // エラー処理
  useCheckErrorThrowError([receiptsGetError]);

  useEffect(() => {
    setTotal(count);
  }, [count]);

  const handleGoBack = useNavigateWithBack(PATIENT.USB001);

  const handleGotoReceipt = (id: string) => {
    setReceiptId(id);
    window.open(
      `${window.location.protocol}//${window.location.host}/receipt`,
      "_blank",
    );
  };

  const receiptList = useMemo(
    () =>
      receipts.map((receipt) => ({
        id: receipt._id,
        left: <></>,
        center: (
          <>
            <LayoutBox direction="column">
              <LayoutBox gap="1/2x">
                <Text size="base" color="neutralUserLight">
                  {receipt.payment_date}
                </Text>
                <Text size="base" color="neutralUserLight">
                  |
                </Text>
                <Text size="base" color="neutralUserBlack">
                  {receipt.amount
                    ? `${convertToCommaSeparatedString(receipt.amount)}円`
                    : ""}
                </Text>
              </LayoutBox>
            </LayoutBox>
            <Text size="large" bold color="neutralUserBlack">
              {getSalesCategoryDisplay(receipt.category)}
            </Text>
          </>
        ),
        right: (
          <>
            <Icon icon="keyboard_arrow_right" color="#CF4900" />
          </>
        ),
      })),
    [receipts],
  );

  return (
    <div className="admin-area  admin-area_no-header">
      <div className="admin-inner area-with-header-footer">
        <HeaderUser />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="領収書"
          borderBottom="primary"
          onClickBack={handleGoBack}
        />
        <Sheet padding="24px 16px 0px" minWidth="1076px">
          <LayoutBox direction="column" gap="3x" width="1064px">
            <Sheet type="card" padding="16px 16px 32px">
              <List
                items={receiptList ?? []}
                selectedIds={[]}
                onClick={handleGotoReceipt}
              />
            </Sheet>
            <SpPaginationContainer />
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USG012;
