import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import {
  getAggregateUsa031,
  getAggregateUsc024,
  getAggregateUsc025,
  getAggregateUsc021AllOrderYear,
  getAggregateUsc021AllOrderYearModality,
  getAggregateUsc021AllOrderYearBrain,
  getAggregateUsa022,
} from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { extractUniqueYearsFromData } from "../../../utils/utils";
import useForceUpdate from "../../common/useForceUpdate";

type YearType = {
  value: string;
  label: string;
};

type SettlementTimeType = {
  finished_at: Date;
};

const useUsc021DBActions = (orderYear: { orderYear: string | null }) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [listResult, setListResult] = useState<USC001Type[]>([]);
  const [brainListResult, setBrainListResult] = useState<USC021Type[]>([]);
  const [mriListResult, setMriListResult] = useState<USC002Type[]>([]);
  const [petListResult, setPetListResult] = useState<USC003Type[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [selectOrderYear, setSelectOrderYear] = useState<YearType[]>([
    { value: "すべて", label: "すべて" },
  ]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  // のうKnow取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsa031(
          currentUser?.uid ?? "",
          orderYear.orderYear,
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_NOUKNOWS, aggregate },
        ])) as USC001Type[];
        setListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [
    currentUser,
    forceUpdate.forceUpdateCount,
    functions,
    orderYear.orderYear,
  ]);

  // 電話認知機能取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsa022(
          currentUser?.uid ?? "",
          orderYear.orderYear,
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_BRAIN_CHECKS, aggregate },
        ])) as USC021Type[];
        setBrainListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [
    currentUser,
    forceUpdate.forceUpdateCount,
    functions,
    orderYear.orderYear,
  ]);

  // MRI取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsc024(
          currentUser?.uid ?? "",
          orderYear.orderYear,
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate },
        ])) as USC002Type[];
        setMriListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [
    currentUser,
    forceUpdate.forceUpdateCount,
    functions,
    orderYear.orderYear,
  ]);

  // PET取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsc025(
          currentUser?.uid ?? "",
          orderYear.orderYear,
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate },
        ])) as USC003Type[];
        setPetListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [
    currentUser,
    forceUpdate.forceUpdateCount,
    functions,
    orderYear.orderYear,
  ]);

  // 受検した年のセレクトボックス
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // のうKnow
        const aggregate = getAggregateUsc021AllOrderYear(
          currentUser?.uid ?? "",
        );
        const aggregateResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_NOUKNOWS, aggregate },
        ])) as SettlementTimeType[];

        // 電話認知機能評価
        const aggregateBrain = getAggregateUsc021AllOrderYearBrain(
          currentUser?.uid ?? "",
        );
        const aggregateResultBrain = (await functions([
          "mongo/client",
          {
            collection: Collection.PATIENT_BRAIN_CHECKS,
            aggregate: aggregateBrain,
          },
        ])) as SettlementTimeType[];

        // PET・MRI
        const aggregateModality = getAggregateUsc021AllOrderYearModality(
          currentUser?.uid ?? "",
        );
        const aggregateResultModality = (await functions([
          "mongo/client",
          {
            collection: Collection.PATIENT_MODALITY_BOOKS,
            aggregate: aggregateModality,
          },
        ])) as SettlementTimeType[];
        const lists = [
          ...aggregateResult,
          ...aggregateResultModality,
          ...aggregateResultBrain,
        ];
        setSelectOrderYear(() => {
          const uniqueYears = extractUniqueYearsFromData({
            key: "finished_at",
            data: lists,
          });

          return [{ value: "すべて", label: "すべて" }, ...uniqueYears];
        });
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, functions]);

  return {
    error,
    listResult,
    brainListResult,
    selectOrderYear,
    fetchError,
    mriListResult,
    petListResult,
  };
};

export default useUsc021DBActions;
