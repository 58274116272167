import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { checkActionErr } from "../contexts/CustomErrorBoundary";

type BookingDates = {
  first?: Date;
  second?: Date;
  third?: Date;
  fourth?: Date;
  fifth?: Date;
};

interface RestoredParams {
  [key: string]: string | BookingDates;
}

// 予約ロード処理
const PreBookLoading = () => {
  const navigate = useNavigateCustom();
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const location = useLocation();
  const isFirstRender = useRef(true);

  useEffect(() => {
    void (async () => {
      addLoadCount();
      try {
        if (isFirstRender.current) {
          // 初回のみ実行
          isFirstRender.current = false;

          const queryParams = new URLSearchParams(location.search);
          const restoredParams: RestoredParams = Array.from(queryParams).reduce(
            (acc: RestoredParams, [key, value]) => {
              if (key === "candidate_book_dates") {
                acc[key] = JSON.parse(value) as BookingDates;
              } else {
                acc[key] = value;
              }

              return acc;
            },
            {} as RestoredParams,
          );
          const precautions = queryParams.getAll("pet_precautions").map(Number);

          // 文字列を Date オブジェクトに変換
          if (typeof restoredParams.candidate_book_dates === "object") {
            const dates = restoredParams.candidate_book_dates;

            const convertedDates = Object.keys(dates).reduce((acc, key) => {
              const dateStr = dates[key as keyof BookingDates];
              if (dateStr) {
                acc[key as keyof BookingDates] = new Date(dateStr);
              }

              return acc;
            }, {} as BookingDates);

            restoredParams.candidate_book_dates = convertedDates;
          }

          const hospitalId =
            "hospital_id" in restoredParams
              ? (restoredParams.hospital_id as string)
              : "";

          // 仮予約登録API
          // TODO: 引数
          await functions([
            "booking/savePreBooking",
            [
              restoredParams.booking_type,
              hospitalId,
              currentUser?.uid,
              restoredParams.candidate_book_dates,
              "",
              true,
              precautions,
              restoredParams.session_id,
            ],
          ]);

          // 画面遷移、ストレージ削除
          if (restoredParams.booking_type === "interview") {
            sessionStorage.removeItem("interviewBookingInput");
            navigate(PATIENT.USD005);
          } else if (restoredParams.booking_type === "pet") {
            sessionStorage.removeItem("petBookingInput");
            navigate(PATIENT.USC016);
          }
        }
      } catch (e) {
        navigate(PATIENT.USB001);
        checkActionErr(e);
      } finally {
        decrementLoadCount();
      }
    })();
  }, [currentUser, navigate, location.search, functions]);

  return <></>;
};

export default PreBookLoading;
