import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { Collection, PatientNouKnowStatusID } from "../../../constants/common";

// のうKNOW完了
const useUsc014DBActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const location = useLocation();
  const isFirstRender = useRef(true);

  useEffect(() => {
    void (async () => {
      addLoadCount();
      try {
        if (isFirstRender.current) {
          // 初回のみ実行
          isFirstRender.current = false;

          const queryParams = new URLSearchParams(location.search);
          const brainsuiteNouknowId = queryParams.get("nouknowId") || "";
          await functions([
            "mongo/client",
            {
              collection: Collection.PATIENT_NOUKNOWS,
              updateOne: {
                filter: { brainsuite_nouknow_id: brainsuiteNouknowId },
                update: {
                  $set: {
                    status: PatientNouKnowStatusID.COMPLETED,
                    updated_at: new Date(),
                  },
                },
              },
            },
          ]);
        }
      } catch (e) {
        checkActionErr(e);
      } finally {
        decrementLoadCount();
      }
    })();
  }, [currentUser, functions, location.search]);

  return <></>;
};

export default useUsc014DBActions;
