import { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import useSWR from "swr";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";

import {
  checkActionErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";
import { Collection, OrderStatusID } from "../../../constants/common";
import { getAggregateOneOrder } from "../../../utils/query";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import usePatientInfoFetch from "../../common/usePatientInfoFetch";

const useUsf009DBActions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { functions } = firebaseAppContext;
  const [orderInfo, setOrderInfo] = useState<OrdersType | null>(null);
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();

  const [count, setCount] = useState(0);

  const [actionError, setActionError] = useState<Error | null>(null);
  const [id] = useId();

  const getOrder = useCallback(async () => {
    addLoadCount();
    try {
      if (!id) redirectToInvalidAccessPage();

      // ログインユーザのOrderユーザ情報を取得
      const conditions = { _id: id, status: OrderStatusID.PAYMENT_COMPLETED };
      const aggregate = getAggregateOneOrder(conditions);
      const searchOrderInfo = (await functions([
        "mongo/client",
        { collection: Collection.ORDERS, aggregate },
      ])) as OrdersType[];

      setOrderInfo(searchOrderInfo[0]);
    } catch (err) {
      setActionError(checkActionErr(err));
    } finally {
      setCount((prev) => prev + 1);
      decrementLoadCount();
    }
  }, [functions, id]);

  useSWR("getSuccessOrder", getOrder, {
    refreshInterval: count < 100 ? 500 : 0,
  });

  useEffect(() => {
    if (orderInfo) {
      setCount(100);
    }
  }, [orderInfo]);

  return {
    orderInfo,
    patientInfo,
    actionError,
    error: actionError,
    patientInfoError,
  };
};

export default useUsf009DBActions;
