import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  redirectToNoDataPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateOneSale } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useForceUpdate from "./useForceUpdate";
import useReceiptId from "./useReceiptId";

const useSalesFetchById = (receiptId?:string) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [id] = useReceiptId();
  const [sale, setSale] = useState<Receipt | null>(null);
  const [nldata, setNldata] = useState<SettingsType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();
  const actualId = receiptId ?? id;

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!actualId) redirectToInvalidAccessPage();

        const conditions = { _id: actualId };
        const aggregate = getAggregateOneSale(conditions);
        const result = (await functions([
          "mongo/client",
          { collection: Collection.SALES, aggregate },
        ])) as Receipt[];

        if (result.length < 1) redirectToNoDataPage();
        setSale(result[0]);
        const resultNL = (await functions([
          "mongo/client",
          { collection: Collection.SETTINGS, findOne: {} },
        ])) as SettingsType;
        setNldata(resultNL);
     } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, actualId, forceUpdate.forceUpdateCount, functions]);

  return { error, sale, nldata };
};

export default useSalesFetchById;
