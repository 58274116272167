import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsc004 } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useUsc004Conditions from "./useUsc004Conditions";

const useUsc004Actions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [{ selectedDate }] = useUsc004Conditions();
  const [result, setResult] = useState<USC001Type>();
  const [finishedDateArray, setFinishedDateArray] = useState<SelectOption[]>(
    [],
  );
  const [error, setError] = useState<Error | null>(null);

  // のうKNOW取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsc004(
          selectedDate,
          currentUser?.uid ?? "",
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_NOUKNOWS, aggregate },
        ])) as USC001Type[];

        // 受検日の選択肢
        const usc004FinishedAtAggregate = getAggregateUsc004(
          "All",
          currentUser?.uid ?? "",
        );
        const fetchResultFinishedAt = (await functions([
          "mongo/client",
          {
            collection: Collection.PATIENT_NOUKNOWS,
            aggregate: usc004FinishedAtAggregate,
          },
        ])) as USC001Type[];
        const uniqueFinishedAt = [
          ...new Set(fetchResultFinishedAt.map((item) => item.finished_at)),
        ];
        setFinishedDateArray((prevState) => {
          const existingFinishedAt = new Set(
            prevState.map((item) => item.label),
          );
          const newDates = uniqueFinishedAt.filter(
            (date) => !existingFinishedAt.has(date),
          );

          return [
            ...prevState,
            ...newDates.map((year) => ({
              value: String(year),
              label: year,
            })),
          ];
        });

        setResult(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions, selectedDate]);

  return {
    finishedDateArray,
    result,
    error,
  };
};

export default useUsc004Actions;
