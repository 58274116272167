import React from "react";
import Divider from "../components/Divider/Divider";
import HeaderUser from "../components/Header/HeaderUser";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import Link from "../components/Link/Link";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import { useCheckErrorThrowError } from "../utils/checkError";
import useGetFreeInterview from "../hooks/common/useGetFreeInterview";
import SpButton from "../components/Button/SpButton";
import InformationDialog from "../components/Dialog/InformationDialog";
import usePatientInterviewsActions from "../hooks/common/usePatientInterviewsActions";

const USD010 = () => {
  const navigateWithBack = useNavigateWithBack(PATIENT.USD004);
  const { interview, error } = useGetFreeInterview();
  const { updateInterviewsCancel, error: cancelError } =
    usePatientInterviewsActions();

  useCheckErrorThrowError([error, cancelError]);

  const onBackBtnClicked = () => {
    navigateWithBack();
  };

  const [isOpen, setIsOpen] = React.useState(false);
  // キャンセルダイアログclose
  const handleCloseCancelConfirm = () => {
    setIsOpen(false);
  };

  // キャンセルダイアログopen
  const handleCancelConfirm = () => {
    setIsOpen(true);
  };

  //  予約取消
  const handleCancelReservation = (id: string | undefined) => {
    void (async () => {
      const isSuccess = await updateInterviewsCancel(id);
      if (!isSuccess) return;
      navigateWithBack();
      window.location.reload(); // ヘッダーを無理やり更新
    })();
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          selectedTab={SELECTED_TAB_NUM.healthAdvice}
          minWidth="1076px"
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="オンライン面談"
          headerNote="（MRI検査付帯）"
          content="オンライン会議システム（Zoom）を利用して医師面談ができます"
          borderBottom="primary"
          onClickBack={onBackBtnClicked}
        />
        <LayoutBox justify="center">
          <Sheet padding="24px 16px 0px" maxWidth="1096px">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <LayoutBox direction="column" fullWidth>
                <Sheet type="card" padding="24px 16px">
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox
                      direction="column"
                      align="center"
                      gap="2x"
                      fullWidth
                    >
                      <Icon icon="warning" size="xl" color="#D6A000" />
                      <Text size="2xl" bold color="neutralUserBlack">
                        現在予約を調整中です。
                      </Text>
                    </LayoutBox>
                    <Divider darkColor margin="0" />
                    {interview && interview.candidate_interview_dates && (
                      <LayoutBox direction="column" gap="3x" fullWidth>
                        {Object.entries(
                          interview.candidate_interview_dates,
                        ).map(([key, value], index) =>
                          value ? (
                            <LayoutBox
                              direction="column"
                              gap="1x"
                              fullWidth
                              key={key}
                            >
                              <Text size="xl" bold color="neutralUserBlack">
                                第{index + 1}候補
                              </Text>
                              <Text size="xl" color="neutralUserBlack">
                                {value.split(" ")[0]}
                              </Text>
                              <LayoutBox direction="row" fullWidth>
                                <Text size="xl" color="neutralUserBlack">
                                  {value.split(" ")[1]}
                                </Text>
                                <Text size="xl" color="neutralUserBlack">
                                  の間に開始
                                </Text>
                              </LayoutBox>
                              <Divider darkColor margin="0" />
                            </LayoutBox>
                          ) : null,
                        )}
                      </LayoutBox>
                    )}
                    <LayoutBox direction="column" gap="2x" fullWidth>
                      <Text size="xl" bold color="neutralUserBlack">
                        予約の変更・キャンセルについて
                      </Text>
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <LayoutBox direction="row" gap="1x">
                          <Link
                            size="xl"
                            color="primary"
                            url="https://dementia365.com/cancelpolicy/"
                          >
                            キャンセルポリシー
                          </Link>
                          <Text size="xl" color="neutralUserBlack">
                            をご確認ください。
                          </Text>
                        </LayoutBox>
                        <Divider darkColor margin="0" />
                      </LayoutBox>
                    </LayoutBox>
                    <LayoutBox justify="center" fullWidth>
                      <SpButton
                        type="danger"
                        width="311px"
                        size="small"
                        mobileFullWidth
                        onClick={handleCancelConfirm}
                      >
                        予約取消
                      </SpButton>
                    </LayoutBox>
                  </LayoutBox>
                </Sheet>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
      {isOpen && (
        <InformationDialog
          open={isOpen}
          closeDialog={handleCloseCancelConfirm}
          title="予約取消"
          isShowCloseButton={false}
          alertLevel="error"
          width="350px"
          footer={
            <>
              <LayoutBox justify="center" wrapReverse gap="2x">
                <SpButton
                  type="danger"
                  width="275px"
                  onClick={() => handleCancelReservation(interview?._id)}
                >
                  予約取消
                </SpButton>
                <SpButton
                  type="secondary"
                  width="275px"
                  onClick={handleCloseCancelConfirm}
                >
                  キャンセル
                </SpButton>
              </LayoutBox>
            </>
          }
        >
          <LayoutBox align="center">
            <LayoutBox
              direction="column"
              gap="none"
              align="start"
              justify="start"
            >
              <Text size="xl">
                オンライン面談を取り消しますがよろしいですか？
              </Text>
            </LayoutBox>
          </LayoutBox>
        </InformationDialog>
      )}
    </div>
  );
};
export default USD010;
