import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  Collection,
  PATIENT_INVITATIONS_STATUS,
} from "../../../constants/common";
import { getAggregateUsaInvitationsUser } from "../../../utils/query";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useId from "../../common/useId";

const useUsa032Actions = () => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [result, setResult] = useState<PatientInvitation>();
  const [error, setError] = useState<Error | null>(null);
  const searchParams = new URLSearchParams(window.location.search);
  const invitedId = searchParams.get("invited_patient_id");
  const [id] = useId();

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        let idToUse: string | null = id;
        if (!idToUse) {
          idToUse = invitedId;
        }

        if (idToUse) {
          const aggregate = getAggregateUsaInvitationsUser(idToUse, [
            PATIENT_INVITATIONS_STATUS.currentlyApplying,
          ]);
          const fetchResult = (await functions([
            "mongo/client",
            { collection: Collection.PATIENT_INVITATIONS, aggregate },
          ])) as PatientInvitation[];
          setResult(fetchResult[0]);
        }
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, functions, id, invitedId]);

  // プレゼント承認API 失敗時にはfalseを返します。
  const updateApproval = async (
    parentPatientId: string | undefined,
    invitedPatientId: string | undefined,
    acceptance: boolean,
  ) => {
    openUpdateActionLoading();
    try {
      // プレゼント承認API
      const approvalData = (await functions([
        "account/present/approval",
        {
          parent_patient_id: parentPatientId,
          invited_patient_id: invitedPatientId,
          acceptance,
        },
      ])) as string;

      return { url: approvalData, isError: false };
    } catch (err) {
      setError(checkActionErr(err));

      return { url: null, isError: true };
    } finally {
      closeActionLoading();
    }
  };

  return {
    result,
    error,
    updateApproval,
  };
};

export default useUsa032Actions;
