import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import PatientFooter from "../features/PatientFooter";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import SpButton from "../components/Button/SpButton";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";

const USG020 = () => {
  const navigate = useNavigateWithUrl();
  const { userObject } = useFirebaseApp();

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner area-with-header-footer">
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="メールアドレスの変更"
            withButton={false}
            borderBottom="primary"
          />
          <Sheet padding="40px 0" className="util-sp-py-24">
            <LayoutBox direction="column" maxWidth="440px">
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" gap="2x">
                  <Text bold size="2xl" color="neutralBlack" lineHeight="150%">
                    {userObject?.line_id ? "メールアドレスの変更が完了しました" : userObject ? "メールの送信が完了しました" : ""}
                  </Text>
                  <Text size="xl" color="neutralBlack" lineHeight="150%">
                    {userObject && !userObject?.line_id ? "メールの内容をご確認いただき、次の手順にお進みください。" : ""}
                  </Text>
                  {userObject?.line_id && <SpButton
                    type="primary"
                    width="400px"
                    size="medium"
                    mobileFullWidth
                    withArrowRight
                    onClick={() => navigate(PATIENT.USB001)}
                  >
                    トップへ
                  </SpButton>}
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
          <div />
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  )
};

export default USG020;
