import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  redirectToNoDataPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateOnePatientUser } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useForceUpdate from "./useForceUpdate";

// ログインユーザまたはuid(nullならフェッチしない、undefinedならログインユーザー)で指定されるユーザーのPatientユーザ情報を取得
const usePatientInfoFetch = (uid?:string) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [patientInfo, setPatientInfo] = useState<PatientsType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const id = uid !== undefined ? uid : currentUser?.uid;

  useEffect(() => {
    const fetchData = async () => {
      if (uid === null) return;
      addLoadCount();
      setLoading(true);
      try {
        if (!id) redirectToInvalidAccessPage();

        // ログインユーザのPatientユーザ情報を取得
        const conditions = { _id: id };
        const aggregate = getAggregateOnePatientUser(conditions);
        const searchPatientInfo = (await functions([
          "mongo/client",
          { collection: Collection.USERS, aggregate },
        ])) as PatientsType[];

        if (searchPatientInfo.length < 1) redirectToNoDataPage();
        setPatientInfo(searchPatientInfo[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
        setLoading(false);
      }
    };

    void fetchData();
  }, [currentUser, uid, id, forceUpdate.forceUpdateCount, functions]);

  return { loading, error, patientInfo };
};

export default usePatientInfoFetch;
