import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateStripeSubscription } from "../../utils/query";
import { Collection } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
  redirectToNoDataPage,
} from "../../contexts/CustomErrorBoundary";
import useForceUpdate from "./useForceUpdate";
import { formatDate } from "../../utils/utils";

type SubscriptionInfo = {
  nextBillingDate: Date;
  price: number;
  interval: string;
};

// Stripeのサブスクリプション情報を取得
const useStripeSubscriptionFetch = (id: string) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;

  const [nextUpdateDateStr, setNextUpdateDateStr] = useState("");
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        // 特定IDのサブスクリプション情報を取得
        const conditions = { _id: id };
        const aggregate = getAggregateStripeSubscription(conditions);
        const result = (await functions([
          "mongo/client",
          { collection: Collection.USERS, aggregate },
        ])) as {
          stripeSubscriptionId: string;
        }[];

        if (result.length < 1) redirectToNoDataPage();

        const { stripeSubscriptionId } = result[0];
        if (!stripeSubscriptionId) {
          setNextUpdateDateStr("");

          return;
        }

        // ストライプ情報取得API
        const stripeInfo = (await functions([
          "stripe/subscription/info",
          stripeSubscriptionId,
        ])) as SubscriptionInfo;

        if (!stripeInfo) {
          setNextUpdateDateStr("");

          return;
        }
        const nextBillingDateStr = stripeInfo.nextBillingDate
          ? formatDate(stripeInfo.nextBillingDate)
          : "";
        setNextUpdateDateStr(nextBillingDateStr);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, forceUpdate.forceUpdateCount, functions]);

  return { error, nextUpdateDateStr };
};

export default useStripeSubscriptionFetch;
