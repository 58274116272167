import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import {
  getAggregateSubscriptionOrderYear,
  getAggregateUsa033,
} from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { extractUniqueYearsFromData } from "../../../utils/utils";

type YearType = {
  value: string;
  label: string;
};

type SettlementTimeType = {
  payment_date: Date;
};

const useUsa033Actions = (orderYear: { orderYear: string }) => {
  const firebaseAppContext = useFirebaseApp();
  const { currentUser, functions } = firebaseAppContext;
  const [result, setResult] = useState<USA033Type[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [selectOrderYear, setSelectOrderYear] = useState<YearType[]>([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateUsa033(
          currentUser?.uid ?? "",
          orderYear.orderYear,
        );
        const fetchResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_SUBSCRIPTIONS, aggregate },
        ])) as USA033Type[];
        setResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [
    currentUser,
    forceUpdate.forceUpdateCount,
    functions,
    orderYear.orderYear,
  ]);

  // セレクトボックス
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateSubscriptionOrderYear(
          currentUser?.uid ?? "",
        );
        const aggregateResult = (await functions([
          "mongo/client",
          { collection: Collection.PATIENT_SUBSCRIPTIONS, aggregate },
        ])) as SettlementTimeType[];
        setSelectOrderYear(
          extractUniqueYearsFromData({
            key: "payment_date",
            data: aggregateResult,
          }),
        );
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, functions]);

  return {
    result,
    error,
    selectOrderYear,
    fetchError,
  };
};

export default useUsa033Actions;
